import {
	ADD_ORDER,
	RESET_ORDERS,
	SET_CONFIRMATION
} from './actions';

import utils from '../../utils/utils';
import config from '../../config';

const initialState = {
	items: [],
	customerEmail: '',
	cardName: '',
	cardNumber: '',
	cardExpirationMonth: '',
	cardExpirationYear: '',
	billingZipCode: '',
	lastUpdated: new Date().getTime(),
	confirmation: ''
};

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case 'LOAD_STORED_STATE':
			const storedState = action.storedState && action.storedState.orders;
			return utils.testSessionData(storedState, initialState, config.orderSessionStorageTTL);
		case RESET_ORDERS:
			return Object.assign({}, state, initialState, {
				lastUpdated: -1
			});
		case ADD_ORDER:
			return Object.assign({}, state, action.value, {
				lastUpdated: new Date().getTime()
			});
		case SET_CONFIRMATION:
			return Object.assign({}, state, action.value, {
				confirmation: action.value
			});
		default:
			return state;
	}
}
