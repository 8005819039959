import utils from './utils';

const MONTH_REGEX = /^(0[1-9]|1[0-2])$/;
const YEAR_REGEX = /^[0-9]{4}$/;
const TELEPHONE_REGEX = /^\D?(\d{3,5})\D?\D?(\d{3,5})\D?(\d{4,5})$/

const validators = {
	isNotNullOrEmpty: (val) => {
		return val && val !== null && String(val).trim().length;
	},
	includes: (substr) => {
		return (val) => {
			return val && String(val).trim().includes(substr);
		};
	},
	isLength: (length) => {
		return (val) => {
			return val && String(val).trim().length === length;
		}
	},
	isLengthLessThan: (length) => {
		return (val) => {
			return val && String(val).trim().length < length;
		}
	},
	isLengthGreaterThan: (length) => {
		return (val) => {
			return val && String(val).trim().length > length;
		}
	},
	isStateNullOrEmpty: (val) => {
		return val && val.fullName && val.fullName.trim().length;
	},
	regex: (regex) => {
		return (val) => {
			return val && regex.test(String(val).trim());
		}
	},
	isEmail: (val) => {
		return utils.isEmail(val);
	},
	isEmailEmpty: (val) => {
		return utils.isEmailEmpty(val);
	},
	isDateString: (val) => {
		return utils.isDateString(val);
	},
	isDateStringV2: (val) => {
		return utils.isDateStringV2(val);
	},
	isNumeric: (val) => {
		return utils.isNumeric(val);
	},
	isNumericEmpty: (val) => {
		return utils.isNumericEmpty(val);
	},
	isPositive: (val) => {
		return utils.isPositive(val);
	},
	isValidMonth: (val) => {
		return (MONTH_REGEX.test(utils.padLeft(val, 2, '0')) &&
			val > 0 &&
			val < 13)
	},
	isValidYearInFuture: (val) => {
		return YEAR_REGEX.test(val) &&
			val >= new Date().getFullYear();
	},
	isValidYearInFutureLastTwo: (val) => {
		return YEAR_REGEX.test(val) &&
			'20' + val.slice(-2) >= new Date().getFullYear();
	},
	isValidYearInPast: (val) => {
		return YEAR_REGEX.test(val) &&
			val <= new Date().getFullYear();
	},
	isDateBetween: (startDaysAhead, endDaysAhead) => {
		return (val) => {
			if (utils.isDateString(val) || utils.formatDate(new Date()) === val) {
				const minDate = utils.getStartOfDay(utils.daysFrom(startDaysAhead));
				const maxDate = utils.daysFrom(endDaysAhead, minDate);
				const date = utils.reclaimDate(val);
				return (date >= minDate && date <= maxDate);
			}
			return false;
		}
	},
	isEqual: (obj, prop) => {
		return (val) => {
			return obj[prop].toLowerCase() === val.toLowerCase();
		};
	},
	matchesPattern: (pattern) => {
		return (val) => {
			return pattern.test(val);
		};
	},
	validTelephone: (val) => {
		return TELEPHONE_REGEX.test(val)
	},
	validTelephoneOptional: (val) => {
		if (!TELEPHONE_REGEX.test(val)) {
			if (!(val && String(val).trim().length)) {
				return true
			}
		} else {
			return TELEPHONE_REGEX.test(val)
		}
	}
};

export default validators;


export function runValidators(val, validators) {
	if (Array.isArray(validators)) {
		let valid = true;
		for (let i = 0, ii = validators.length; i < ii; i++) {
			if (validators[i] && typeof validators[i].validator === 'function') {
				valid = validators[i].validator(val);
				if (!valid) {
					return validators[i].message;
				}
			}
		}
		return null;
	}
}