import React from 'react';
import './card.css';

import utils from '../../utils/utils';

class Card extends React.PureComponent {

	render() {
		const { children, className, padding } = this.props;
		const cssClass = utils.extractClassName(className);
		return (
			<div className={`card ${cssClass} ${utils.getPaddingClass(padding, 'card')}`}>
				{children}
			</div>
		);
	}
}
export default Card;

