import { CLEAR_ERRORS, CLEAR_ERROR, ADD_ERROR } from './actions';

const initialState = {
	errors: [],
};

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case CLEAR_ERRORS:
			return Object.assign({}, state, {
				errors: []
			});
		case CLEAR_ERROR:
			return Object.assign({}, state, {
				errors: state.errors.slice(action.count)
			});
		case ADD_ERROR:
			return Object.assign({}, state, {
				errors: state.errors.concat(action.error)
			});
		default:
			return state;
	}
}
