import React from 'react';
import './loading.css';

const Loading = (props) => {
	return (
		<div className="loading">
			<p>Loading</p>
		</div>
	);
};

export default Loading;

