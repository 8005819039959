import {
	SET_CLAIM_TOKEN,
	SET_PIN,
	SET_EMAIL,
	SET_FIRST_NAME,
	SET_MIDDLE_INITIAL,
	SET_LAST_NAME,
	SET_ADDRESS_1,
	SET_ADDRESS_2,
	SET_CITY,
	SET_STATE,
	SET_STATE_NAME,
	SET_ZIP_CODE,
	SET_BIRTH_YEAR,
	SET_PHONE_NUMBER,
	SET_PLAN_NAME,
	SET_MEMBERSHIP_NUMBER,
	RESET_CLAIM,
	SET_ADDRESS,
	SET_SHORT_DESCRIPTION,
	SET_GIVER_FIRST_NAME,
	SET_GIVER_LAST_NAME,
	SET_GIVER_EMAIL,
	SET_PLAN_RESPONSE,
	SET_CLAIM_ASSOCIATES,
	SET_ASSOCIATE_PROP
} from './actions';

import utils from '../../utils/utils';
import config from '../../config';

const initialState = {
	pin: null,
	email: null,
	claimToken: null,
	firstName: null,
	middleInitial: null,
	lastName: null,
	address1: null,
	address2: null,
	city: null,
	state: null,
	stateName: null,
	zipCode: null,
	birthYear: null,
	phoneNumber: null,
	planName: null,
	membershipNumber: null,
	address: null,
	lastUpdated: new Date().getTime(),
	shortDescription: null,
	giverFirstName: null,
	giverLastName: null,
	giverEmail: null,
	planResponse: null,
	associates: null
};

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case 'LOAD_STORED_STATE':
			const storedState = action.storedState && action.storedState.claim;
			return utils.testSessionData(storedState, initialState, config.sessionStorageTTL);
		case SET_PIN:
			return Object.assign({}, state, {
				pin: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_GIVER_FIRST_NAME:
			return Object.assign({}, state, {
				giverFirstName: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_GIVER_LAST_NAME:
			return Object.assign({}, state, {
				giverLastName: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_GIVER_EMAIL:
			return Object.assign({}, state, {
				giverEmail: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_SHORT_DESCRIPTION:
			return Object.assign({}, state, {
				shortDescription: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_PLAN_NAME:
			return Object.assign({}, state, {
				planName: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_EMAIL:
			return Object.assign({}, state, {
				email: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_ADDRESS:
			return Object.assign({}, state, {
				address: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_CLAIM_TOKEN:
			return Object.assign({}, state, {
				claimToken: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_FIRST_NAME:
			return Object.assign({}, state, {
				firstName: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_MIDDLE_INITIAL:
			return Object.assign({}, state, {
				middleInitial: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_LAST_NAME:
			return Object.assign({}, state, {
				lastName: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_ADDRESS_1:
			return Object.assign({}, state, {
				address1: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_ADDRESS_2:
			return Object.assign({}, state, {
				address2: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_CITY:
			return Object.assign({}, state, {
				city: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_STATE:
			return Object.assign({}, state, {
				state: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_STATE_NAME:
			return Object.assign({}, state, {
				stateName: action.value,
				lastUpdated: new Date().getTime()
			});

		case SET_ZIP_CODE:
			return Object.assign({}, state, {
				zipCode: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_BIRTH_YEAR:
			return Object.assign({}, state, {
				birthYear: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_PHONE_NUMBER:
			return Object.assign({}, state, {
				phoneNumber: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_MEMBERSHIP_NUMBER:
			return Object.assign({}, state, {
				membershipNumber: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_PLAN_RESPONSE:
			return Object.assign({}, state, {
				planResponse: action.value,
				lastUpdated: new Date().getTime()
			});
		case RESET_CLAIM:
			return Object.assign({}, state, initialState);

		case SET_CLAIM_ASSOCIATES:
			return Object.assign({}, state, {
				associates: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_ASSOCIATE_PROP:
			return {
				...state,
				associates: state.associates.map((x) => {
					if (x.recipientAssociateId === action.value.id) {
						return { ...x, ...action.value.field }
					} else {
						return { ...x }
					}
				})
			}
		default:
			return state;
	}
}
