export const SET_CLAIM_TOKEN = 'claim.SET_CLAIM_TOKEN';
export const SET_PIN = 'claim.SET_PIN';
export const SET_EMAIL = 'claim.SET_EMAIL';
export const SET_FIRST_NAME = 'claim.SET_FIRST_NAME';
export const SET_MIDDLE_INITIAL = 'claim.SET_MIDDLE_INITIAL';
export const SET_LAST_NAME = 'claim.SET_LAST_NAME';
export const SET_ADDRESS_1 = 'claim.SET_ADDRESS_1';
export const SET_ADDRESS_2 = 'claim.SET_ADDRESS_2';
export const SET_CITY = 'claim.SET_CITY';
export const SET_STATE = 'claim.SET_STATE';
export const SET_STATE_NAME = 'claim.SET_STATE_NAME';
export const SET_ZIP_CODE = 'claim.SET_ZIP_CODE';
export const SET_BIRTH_YEAR = 'claim.SET_BIRTH_YEAR';
export const SET_PHONE_NUMBER = 'claim.SET_PHONE_NUMBER';
export const SET_PLAN_NAME = 'claim.SET_PLAN_NAME';
export const SET_MEMBERSHIP_NUMBER = 'claim.SET_MEMBERSHIP_NUMBER';
export const RESET_CLAIM = 'claim.RESET_CLAIM';
export const SET_ADDRESS = 'claim.SET_ADDRESS';
export const SET_SHORT_DESCRIPTION = 'claim.SET_SHORT_DESCRIPTION';
export const SET_GIVER_FIRST_NAME = 'claim.SET_GIVER_FIRST_NAME';
export const SET_GIVER_LAST_NAME = 'claim.SET_GIVER_LAST_NAME';
export const SET_GIVER_EMAIL = 'claim.SET_GIVER_EMAIL';
export const SET_PLAN_RESPONSE = 'claim.SET_PLAN_RESPONSE';
export const SET_CLAIM_ASSOCIATES = 'claim.SET_CLAIM_ASSOCIATES';
export const SET_ASSOCIATE_PROP = 'claim.SET_ASSOCIATE_PROP';

export function setPlanName(val) {
	return {
		type: SET_PLAN_NAME,
		value: val
	};
}

export function setShortDescription(val) {
	return {
		type: SET_SHORT_DESCRIPTION,
		value: val
	}
}

export function setGiverFirstName(val) {
	return {
		type: SET_GIVER_FIRST_NAME,
		value: val
	}
}

export function setGiverLastName(val) {
	return {
		type: SET_GIVER_LAST_NAME,
		value: val
	}
}

export function setGiverEmail(val) {
	return {
		type: SET_GIVER_EMAIL,
		value: val
	}
}

export function setClaimToken(val) {
	return {
		type: SET_CLAIM_TOKEN,
		value: val
	};
}

export function setPin(val) {
	return {
		type: SET_PIN,
		value: val
	};
}

export function setEmail(email) {
	return {
		type: SET_EMAIL,
		value: email
	};
}

export function setAddress(address) {
	return {
		type: SET_ADDRESS,
		value: address
	};
}

export function setFirstName(firstName) {
	return {
		type: SET_FIRST_NAME,
		value: firstName
	};
}

export function setMiddleInitial(middleInitial) {
	return {
		type: SET_MIDDLE_INITIAL,
		value: middleInitial
	};
}

export function setLastName(lastName) {
	return {
		type: SET_LAST_NAME,
		value: lastName
	};
}

export function setAddress1(address1) {
	return {
		type: SET_ADDRESS_1,
		value: address1
	};
}

export function setAddress2(address2) {
	return {
		type: SET_ADDRESS_2,
		value: address2
	};
}

export function setCity(city) {
	return {
		type: SET_CITY,
		value: city
	};
}

export function setState(state) {
	return {
		type: SET_STATE,
		value: state
	};
}
export function setStateName(stateName) {
	return {
		type: SET_STATE_NAME,
		value: stateName
	};
}

export function setZipCode(zipCode) {
	return {
		type: SET_ZIP_CODE,
		value: zipCode
	};
}

export function setBirthYear(birthYear) {
	return {
		type: SET_BIRTH_YEAR,
		value: birthYear
	};
}

export function setPhoneNumber(phoneNumber) {
	return {
		type: SET_PHONE_NUMBER,
		value: phoneNumber
	};
}

export function setMembershipNumber(membershipNumber) {
	return {
		type: SET_MEMBERSHIP_NUMBER,
		value: membershipNumber
	};
}

export function resetClaim() {
	return {
		type: RESET_CLAIM
	};
}

export function setPlanResponse(product) {
	return {
		type: SET_PLAN_RESPONSE,
		value: product
	};
}

export function setAssociates(ass) {
	return {
		type: SET_CLAIM_ASSOCIATES,
		value: ass
	}
}

export function setAssociateProp(ass) {
	return {
		type: SET_ASSOCIATE_PROP,
		value: ass
	}
}

