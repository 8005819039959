import React from 'react';
import * as styles from './styleValues';
import Hero from '../components/hero/hero';
import Section from '../components/section/section';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK } from '../components/btn/btn';
import Card from '../components/card/card';
import { PADDING_ALL, PADDING_NONE } from '../utils/styleValues';

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ZIP_REGEX = /^[0-9]{5}(-[0-9]{4})?$/;

const Utils = {
    /**
     * Sets className from array/string/null/undefined
     * @param {any} className
     */
    extractClassName(className) {
        return Array.isArray(className) ? className.join(' ') : (className || '');
    },
    /**
     * Gets the CSS class for the PADDING_* constant with an optional prefix
     * @param {string} padding
     * @param {string} prefix
     */
    getPaddingClass(padding, prefix) {
        let paddingClass = '';
        const defVal = styles.PADDING_NONE;
        switch (padding) {
            case styles.PADDING_HORIZONTAL:
            case styles.PADDING_VERTICAL:
            case styles.PADDING_TOP:
            case styles.PADDING_BOTTOM:
            case styles.PADDING_LEFT:
            case styles.PADDING_RIGHT:
            case styles.PADDING_ALL:
            case styles.PADDING_NO_TOP:
            case styles.PADDING_NO_BOTTOM:
                paddingClass = this.isNonEmptyString(prefix) ? `${prefix}-${padding}` : padding;
                break;
            case styles.PADDING_NONE:
            default:
                paddingClass = this.isNonEmptyString(prefix) ? `${prefix}-${defVal}` : defVal;
                break;
        }
        return paddingClass;
    },
    /**
     * Pads a string to the left
     * @param {string} str
     * @param {number} len
     * @param {string} padStr
     */
    padLeft(str = '', len, padStr = ' ') {
        let strVal = this.isNonEmptyString(str) ? str : this.forceString(str);
        while (strVal.length < len) {
            strVal = ('' + padStr) + strVal;
        }
        return strVal;
    },
    /**
     * Formats a date for input[type="date"] (yyyy-mm-dd), if 'date' not instance of Date will use now
     * @param {Date} date
     */
    formatDate(date) {
        const d = (date instanceof Date) ? date : new Date();
        const day = this.padLeft(d.getDate(), 2, '0');
        const month = this.padLeft(d.getMonth() + 1, 2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    },
    /**
     * Generates a new Date object 'count' days from startDate (default now)
     * @param {number} count
     * @param {Date} startDate
     */
    daysFrom(count, startDate) {
        const start = (startDate instanceof Date) ? startDate : new Date();
        const timestamp = start.getTime();
        const day = 1000 * 60 * 60 * 24;
        return new Date(timestamp + (day * count));
    },
    /**
     * Reclaims date obj from formatted date
     * @param {string} formattedDate
     */
    reclaimDate(formattedDate) {
        const parts = formattedDate.split('-');
        if (parts.length === 3) {
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const day = parseInt(parts[2], 10);
            return new Date(year, month, day);
        }
        return new Date();
    },
    /**
     * Takes a date obj ad
     * @param {Date} date
     */
    getStartOfDay(date) {
        const theDay = (date instanceof Date) ? date : new Date();
        return new Date(theDay.getFullYear(), theDay.getMonth(), theDay.getDate(), 0, 0, 0, 1);
    },
    /**
     * Scrolls page to top
     */
    scrollTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    /**
     * forces a string return. if null/undefined -> empty string
     *     if has toString method it uses that, else concats empty string to val
     * @param {any} val
     */
    forceString(val) {
        if (this.isNullOrUndefined(val)) {
            return '';
        } else if (this.isFunction(val.toString)) {
            return val.toString();
        } else {
            return val + '';
        }
    },
    /**
     * if var is a string
     * @param {any} val
     */
    isString(val) {
        return (typeof val === 'string');
    },
    /**
     * if var is a object
     * @param {any} val
     */
    isObject(val) {
        return (typeof val === 'object');
    },
    /**
     * if var is a string and non empty
     * @param {any} val
     */
    isNonEmptyString(val, min, max) {
        if (this.isNumber(min) || this.isNumber(max)) {
            if (!this.isNumber(max)) {
                return this.isString(val) && val.length > min;
            } else if (!this.isNumber(min)) {
                return this.isString(val) && val.length > 0 && val.length <= max;
            } else {
                return this.isString(val) && val.length >= min && val.length <= max;
            }
        }
        return this.isString(val) && val.length > 0;
    },
    /**
     * if var is a function
     * @param {any} val
     */
    isFunction(val) {
        return (typeof val === 'function');
    },
    /**
     * if var is undefined
     * @param {any} val
     */
    isUndefined(val) {
        return typeof val === 'undefined' || val === undefined;
    },
    /**
     * if var is null
     * @param {any} val
     */
    isNull(val) {
        return val === null;
    },
    /**
     * if var is null or undefined
     * @param {any} val
     */
    isNullOrUndefined(val) {
        return val === null || this.isUndefined(val);
    },
    /**
     * if var is a number type
     * @param {any} val
     */
    isNumber(val) {
        return typeof val === 'number';
    },
    /**
     * if var is a number type or a string that is only comprised of digits 0-9
     * @param {any} val
     */
    isNumeric(val) {
        if (this.isString(val)) {
            return this.isNonEmptyString(val) && /^[0-9]+$/.test(val);
        }
        return this.isNumber(val);
    },
    /**
     * if var is a empty number type or a string that is only comprised of digits 0-9
     * @param {any} val
     */
    isNumericEmpty(val) {
        return val === '' || val === null || this.isNumber(val) || (this.isNonEmptyString(val) && /^[0-9]+$/.test(val));
    },
    isPositive(val) {
        return val > -1 && val !== null
    },
    /**
     * if var is email like (only check for @ symbol)
     * @param {any} val
     */
    isEmail(val) {
        return this.isNonEmptyString(val) && EMAIL_REGEX.test(val.trim());
    },
    /**
     * if var is email empty like (only check for @ symbol)
     * @param {any} val
     */
    isEmailEmpty(val) {
        return val === '' || val === null || EMAIL_REGEX.test(val);
    },
    /**
     * is a string in format yyyy-mm-dd
     * @param {any} val
     */
    isDateString(val) {
        return this.isString(val) && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val);
    },
    isDateStringV2(val) {
        return !isNaN(Date.parse(val));
    },
    /**
     * validates credit card numbers
     * @param {string} number
     */
    luhnCheck(ccNumber) {
        if (typeof ccNumber === 'string') {
            const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
            let len = ccNumber ? ccNumber.length : 0;
            let bit = 1;
            let sum = 0;

            while (len--) {
                sum += !(bit ^= 1) ? parseInt(ccNumber[len], 10) : arr[ccNumber[len]];
            }

            return sum % 10 === 0 && sum > 0;
        }
        return false;
    },
    /**
     * calculates total cost of a memebership (including fees and annual cost and tax)
     * @param {object} membership
     */
    calculateMembershipTotalCostTax(membership, saleTaxRate) {
        let cost = 0;
        if (membership) {
            cost += membership.netCost;
            cost += membership.enrollmentFee;
            cost += cost * saleTaxRate;
            if (Array.isArray(membership.costAdjustments)) {
                cost += membership.costAdjustments.reduce((acc, v) => {
                    acc += +v.adjustment;
                    return acc;
                }, 0);
            }
        }
        return cost;
    },
    /**
     * calculates total cost of a memebership (including fees and annual cost)
     * @param {object} membership
     */
    calculateMembershipTotalCost(membership) {
        let cost = 0;
        if (membership) {
            cost += membership.netCost;
            cost += membership.enrollmentFee;
            if (Array.isArray(membership.costAdjustments)) {
                cost += membership.costAdjustments.reduce((acc, v) => {
                    acc += +v.adjustment;
                    return acc;
                }, 0);
            }
        }
        return cost;
    },
    /**
 * calculates total cost of a memebership (including fees and annual cost)
 * @param {object} asociate
 */
    calculateAsociateTotalCost(costAsociate, saleTaxRate) {
        let cost = 0;
        cost = costAsociate;
        cost += cost * saleTaxRate;
        return cost;
    },
    /**
     * calculates total cost of a memebership (including fees and annual cost)
     * @param {object} membership
     * @param {number} typeFilter will filter to a specific adjustmentType
     */
    calculateFeesTotalCost(membership, typeFilter) {
        let cost = 0;
        if (membership) {
            if (Array.isArray(membership.costAdjustments)) {
                cost += membership.costAdjustments.reduce((acc, v) => {
                    if (typeFilter === undefined || v.adjustmentType === typeFilter) {
                        acc += parseInt('' + v.adjustment, 10);
                    }
                    return acc;
                }, 0);
            }
        }
        return cost;
    },
    calculateTotalGif(cart) {
        let totalGif = 0
        let totalGifWithoutTax = 0;

        cart.items.forEach(item => {
            const { membership, enrollmentFee, totalAssociates } = this.calculateItemsGif(item);
            totalGifWithoutTax = membership + enrollmentFee + totalAssociates;
            totalGif += totalGifWithoutTax + parseFloat(this.calculateTaxGif(item));
        });
        return totalGif;
    },
    calculateTaxGif(item) {
        let totalTax = 0
        let totalGifWithoutTax = 0;
        const { selectedClub } = item;
        const saleTaxRate = selectedClub.saleTaxRate == null ? 0 : selectedClub.saleTaxRate;
        const { membership, enrollmentFee, totalAssociates } = this.calculateItemsGif(item);
        totalGifWithoutTax = membership + enrollmentFee + totalAssociates;
        totalTax += (totalGifWithoutTax * saleTaxRate);
        return totalTax.toFixed(2);
    },
    calculateItemsGif(item) {
        let membership = 0;
        let enrollmentFee = 0;
        let totalAssociates = 0;
        const { selectedPlan } = item;

        if (selectedPlan.newPromoCode != null) {
            membership = selectedPlan.newPromoCode.netCost;
            enrollmentFee = selectedPlan.newPromoCode.enrollmentFee;
            totalAssociates = this.calculateTotalAssociate(item, true);
            //membership = membership>0 ? membership : selectedPlan.cost;
            //enrollmentFee = enrollmentFee>0 ? enrollmentFee : selectedPlan.enrollmentFee;
            //totalAssociates = totalAssociates>0 ? totalAssociates : this.calculateTotalAssociate(item, false);
        } else {
            membership = selectedPlan.cost;
            enrollmentFee = selectedPlan.enrollmentFee;
            totalAssociates = this.calculateTotalAssociate(item, false);
        }
        const itemsGif = {
            membership: membership,
            enrollmentFee: enrollmentFee,
            totalAssociates: totalAssociates,
        }
        return itemsGif;
    },
    calculateTotalAssociate(item, isPromoCode) {
        let totalAssociates = 0;
        const { selectedPlan, associates } = item;
        associates.map((ass, index) => {
            if (ass.firstName !== '') {
                if (selectedPlan.newPromoCode &&
                    selectedPlan.newPromoCode.promoCode.assocPromoTypeCode) {
                    if (selectedPlan.newPromoCode.promoCode.assocPromoTypeCode !== 'FS') {
                        if (index === 0) {
                            totalAssociates = totalAssociates + (isPromoCode ? parseFloat(selectedPlan.newPromoCode.costPerAssociate) : parseFloat(selectedPlan.costPerAssociate));
                        } else {
                            totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                        }
                    }
                    else {
                        if (selectedPlan.newPromoCode.promoCode.assocFreeCount > index) {
                            totalAssociates = totalAssociates + parseFloat(0);
                        } else {
                            totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                        }
                    }

                } else {
                    if (selectedPlan.freeAssociatesAllowedNumber &&
                        selectedPlan.freeAssociatesAllowedNumber > index) {
                        totalAssociates = totalAssociates + parseFloat(0);
                    } else {
                        totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                    }
                }

            }
        })
        return totalAssociates;
    },
    /**
     * calculates total cost of items in a cart
     * @param {object} cart
     */
    calculateCartTotalCost(cart) {
        return Array.isArray(cart.items) ? cart.items.reduce((acc, v) => {
            let billedAssociates = v.associates ? v.associates.length - v.selectedPlan.freeAssociatesAllowedNumber < 0 ? 0 : v.associates.length - v.selectedPlan.freeAssociatesAllowedNumber : 0;
            const saleTaxRate = v.selectedClub.saleTaxRate == null ? 0 : v.selectedClub.saleTaxRate;
            let costAsociate = (v.selectedPlan.costPerAssociate * billedAssociates)
            if (v.selectedPlan.promoCode && v.selectedPlan.promoCode.applyToAssoc) {
                if (v.selectedPlan.promoCode.assocPromoTypeCode !== 'FS') {
                    costAsociate = v.selectedPlan.newPromoCode.costPerAssociate + (v.selectedPlan.costPerAssociate * (billedAssociates - 1))
                }
                else {
                    billedAssociates = v.associates.length - v.selectedPlan.newPromoCode.freeAssociatesAllowedNumber
                    costAsociate = (v.selectedPlan.costPerAssociate * billedAssociates)
                }
            }
            return acc + this.calculateMembershipTotalCostTax(v.selectedPlan, saleTaxRate) + this.calculateAsociateTotalCost(costAsociate, saleTaxRate);
        }, 0) : 0;
    },
    /**
     * calculates total cost of  memberships for items in a cart
     * @param {object} cart
     */
    calculateCartMembershipTotalCost(cart) {
        return Array.isArray(cart.items) ? cart.items.reduce((acc, v) => {
            return acc + v.selectedPlan.netCost;
        }, 0) : 0;
    },

    /**
     * calculates total cost of fees for items in a cart
     * @param {object} cart
     */
    calculateCartFeesTotalCost(cart) {
        return Array.isArray(cart.items) ? cart.items.reduce((acc, v) => {
            return acc + this.calculateFeesTotalCost(v.selectedPlan);
        }, 0) : 0;
    },
    /**
     * counts items in cart
     * @param {object} cart
     */
    countCartItems(cart) {
        return cart && Array.isArray(cart.items) ? cart.items.length : 0;
    },
    /**
     * Formats region object into a city, state string
     * @param {object} region
     */
    formatRegion(region) {
        if (region) {
            return `${region.city}, ${region.state}`
        } else {
            return '';
        }
    },
    /**
     * Formats region object into a city, without State abreviation
     * @param {object} region
     */
    formatRegionState(region) {
        if (region) {
            return `${region.city}`
        } else {
            return '';
        }
    },
    /**
     * reclaims city/state from region formatted string (via: formatRegion())
     * @param {string} regionString
     */
    reclaimRegion(regionString) {
        let region = null;
        if (this.isString(regionString) && regionString !== 'null') {
            const parts = regionString.split(', ');
            if (parts.length === 2) {
                region = {
                    city: parts[0],
                    state: parts[1]
                };
            }
        }
        return region;
    },
    /**
     * given a region object (city/state) match it to an array of location suggestions
     * @param {object} region
     * @param {Array} suggestions
     */
    matchRegionToSuggestion(region, suggestions) {
        if (Array.isArray(suggestions)) {
            return suggestions.reduce((acc, v) => {
                try {
                    if (v && region &&
                        v.city.toLowerCase() === region.city.toLowerCase() &&
                        v.state.toLowerCase() === region.state.toLowerCase()) {
                        acc = v;
                    }
                } catch (err) {
                    console.warn(err);
                }
                return acc;
            }, null);
        }
        return null;
    },
    /**
     * given a planName match it to an array of plan suggestions
     * @param {object} planName
     * @param {Array} suggestions
     */
    matchPlanToSuggestion(planName, suggestions) {
        if (Array.isArray(suggestions)) {
            return suggestions.reduce((acc, v) => {
                try {
                    if (v && planName &&
                        v.name.toLowerCase() === planName.toLowerCase()) {
                        acc = v;
                    }
                } catch (err) {
                    console.warn(err);
                }
                return acc;
            }, null);
        }
        return null;
    },
    /**
     * Renders generic page with button and text for errors etc
     * @param {string} headerImage
     * @param {string} headerTitle
     * @param {string} title
     * @param {string} text
     * @param {string} btnText
     * @param {Function} btnHandler
     */
    renderGenericPage(headerImage, headerTitle, title, text, btnText, btnHandler) {
        return (
            <section className="page">
                <Hero image={headerImage}
                    title={headerTitle} />

                <Section padding={PADDING_NONE}>
                    <Card padding={PADDING_ALL} className="card-vertical">
                        {title ? (<h3>{title}</h3>) : null}
                        {text ? (<p>{text}</p>) : null}
                        {btnText && btnHandler ?
                            (<Btn label={btnText}
                                onClick={btnHandler}
                                className={['btn-try-another', SIZE_MEDIUM, DISPLAY_BLOCK]} />) : null}
                    </Card>
                </Section>
            </section>
        );
    },
    /**
     * returns value of 'prop' property on obj if eits
     * @param {*} obj
     * @param {*} prop
     */
    getProp(obj, prop, defVal) {
        if (obj && prop in obj) {
            return obj[prop];
        }
        return defVal;
    },
    /**
     * Builds an array of months
     * @param {Function} t
     */
    getMonths(t) {
        return [
            t('monthJanuary'),
            t('monthFebruary'),
            t('monthMarch'),
            t('monthApril'),
            t('monthMay'),
            t('monthJune'),
            t('monthJuly'),
            t('monthAugust'),
            t('monthSeptember'),
            t('monthOctober'),
            t('monthNovember'),
            t('monthDecember')
        ];
    },
    /**
     * turns cardnumber into XXXXXXXXXX-last 4 digits
     * @param {string} cardNumber
     */
    anonymizeCardNumber(cardNumber) {
        return `XXXX-XXXX-XXXX-${(cardNumber || 'XXXX').substr(-4)}`;
    },
    /**
     * Calculates px from rems
     * @param {number} rem
     */
    convertRemToPixels(rem) {
        const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize) || 16;
        return rem * fontSize;
    },
    /**
     * Checks stored session storage data against ttl
     * @param {object} storedState
     * @param {object} initialState
     * @param {number} timeToLive
     */
    testSessionData(storedState, initialState, timeToLive = -1) {
        if (storedState && storedState.lastUpdated) {
            const age = new Date().getTime() - storedState.lastUpdated;
            if (age < timeToLive) {
                console.log('LOAD_STORED_STATE', 'age:', age, 'ttl:', timeToLive);
                return storedState;
            }
            console.log('STALE_SESSION_DATA', 'age:', age, 'ttl:', timeToLive);
        }
        return initialState;
    },
    /**
     * See if two cart entries are the same, compares (location, club, plan, name, email, deliverDate)
     * @param {object} entry1
     * @param {object} entry2
     * @returns {boolean} true if same false if not
     */
    compareCartEntries(entry1, entry2) {
        if (entry1 && entry2) {
            const loc1 = entry1.recipientLocation;
            const loc2 = entry2.recipientLocation;
            if (loc1.id === loc2.id && loc1.clubCode === loc2.clubCode) {
                const club1 = entry1.selectedClub;
                const club2 = entry2.selectedClub;
                if (club1.id === club2.id && club1.clubCode === club2.clubCode) {
                    const plan1 = entry1.selectedPlan;
                    const plan2 = entry2.selectedPlan;
                    if (plan1.id === plan2.id) {
                        const n1 = entry1.recipientFirstName;
                        const n2 = entry2.recipientFirstName;
                        const e1 = entry1.recipientEmail;
                        const e2 = entry2.recipientEmail;
                        const d1 = entry1.deliveryDate;
                        const d2 = entry2.deliveryDate;
                        if (n1 === n2 && e1 === e2 && d1 === d2) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    },
    /**
     * Makes sure is valid cart entry
     * @param {object} entry
     */
    isValidCartEntry(entry) {
        return (entry.recipientLocation && entry.selectedClub && entry.selectedPlan);
    },
    /**
     * Sorts plans array by cost ascending
     * @param {Array} plans
     * @returns {Array}
     */
    sortPlansCostAsc(plans) {
        const plansCopy = Array.isArray(plans) ? [].concat(plans) : [];
        plansCopy.sort((a, b) => {
            if (a.cost < b.cost) {
                return -1;
            } else if (a.cost > b.cost) {
                return 1;
            }
            return 0;
        });
        return plansCopy;
    },
    /**
     * Sorts plans array by cost ascending, with RV plans last
     * @param {Array} plans
     * @returns {Array}
     */
    sortPlansRVsLast(plans) {
        const plansCopy = this.sortPlansCostAsc(plans);
        const reduced = plansCopy.reduce((acc, v) => {
            if (/\sRV$/.test(v.name)) {
                acc.rvs.push(v);
            } else {
                acc.normal.push(v);
            }
            return acc;
        }, { normal: [], rvs: [] });
        return [].concat(...Object.values(reduced));
    },
    /**
     * Form encodes request
     * @param {any} element Any value
     * @param {string} key key
     * @param {Array} list previous vals
     */
    formEncode(element, key, list) {
        list = list || [];
        if (typeof (element) === 'object') {
            for (const idx in element) {
                this.formEncode(element[idx], key ? key + '[' + idx + ']' : idx, list);
            }
        } else {
            list.push(key + '=' + encodeURIComponent(element));
        }
        return list.join('&');
    },
    /**
     * Sets page meta description tag
     * @param {string} content
     */
    setPageMeta(content) {
        const desc = document.querySelector('meta[name="description"]');
        if (desc) {
            desc.setAttribute('content', content);
        }
    },
    /**
     * Sets page rel=canonical url tag
     * @param {string} url
     */
    setCanonicalURL(url) {
        const link = document.querySelector('link[rel="canonical"]');
        if (link) {
            link.setAttribute('href', url);
        }
    },
    /**
     * Sets page title tag value
     * @param {string} title
     */
    setPageTitle(title) {
        document.title = title;
    },
    /**
     * Slugifies string
     * @param {string} str
     */
    slugify(str) {
        return ('' + str).toLowerCase()
            .replace(/[^a-z]?$/, '')
            .replace(/[']+/g, '')
            .replace(/[^a-z]+/g, '_');
    },
    /**
     * gets page name
     * @param {string} path
     */
    getPageTrackingNameFromPath(path) {
        if (path === '/') {
            return 'home';
        } else {
            return path.replace('/', '');
        }
    },
    /**
     * trims string of spaces on end
     * @param {string} str
     */
    trimString(str) {
        return str.replace(/^\s+/, '').replace(/\s+$/, '');
    },
    /**
     * truncates text at certain charcount
     * @param {string} text
     * @param {number} maxLength
     * @param {string} suffix
     */
    truncateText(text, maxLength, suffix = '') {
        if (text.length <= maxLength) {
            return text;
        }
        return (text || '').split(' ').reduce((acc, v) => {
            const nextWord = acc + ' ' + v;
            if (nextWord.length <= maxLength) {
                acc = nextWord;
            }
            return acc;
        }, '').replace(/[^a-zA-Z0-9]+$/, '') + suffix;
    }
};

export default Utils;