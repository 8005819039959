export const CLEAR_ERRORS = 'errors.CLEAR_ERRORS';
export const CLEAR_ERROR = 'errors.CLEAR_ERROR';
export const ADD_ERROR = 'errors.ADD_ERROR';

export function clearErrors() {
	return {
		type: CLEAR_ERRORS
	};
}

export function clearError(count) {
	return {
		type: CLEAR_ERROR,
		count: count
	};
}


export function addError(error) {
	// // Example:
	// {
	// 	title: 'generic error',
	// 	message: 'lorem ipsum dolor sit amet.',
	// 	buttons: [{
	// 		label: 'button 1',
	// 		onClick: 'dismiss'
	// 	}]
	// }, {
	// 	title: 'generic error2',
	// 	message: 'lorem ipsum dolor sit amet.',
	// 	buttons: [{
	// 		label: 'button 1',
	// 		onClick: 'dismiss'
	// 	}, {
	// 		label: 'button 2',
	// 		onClick: (e) => {
	// 			console.log('onClicked');
	// 		},
	// 		color: COLOR_ACTION
	// 	}]
	// }
	return {
		type: ADD_ERROR,
		error: error
	};
}
