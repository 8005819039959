import React from 'react'
import './toolbar.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Ak from '../../components/ak/ak'
import utils from '../../utils/utils'
import config from '../../config'
const { routes, backstack } = config

class Toolbar extends React.Component {
    _getUrlParts() {
        const { location } = this.props
        const { pathname } = location
        return pathname.replace(/^\//, '').split('/')
    }

    shouldComponentUpdate() {
        this._setMeta()
        return true
    }

    _getUrlPathVars(params) {
        const { cart, membership } = this.props
        const path = []
        if (Array.isArray(params)) {
            params.forEach((param) => {
                switch (param) {
                    case 'location':
                        let location = membership.recipientLocation
                        if (!location) {
                            try {
                                location = cart.items[cart.items.length - 1].recipientLocation
                            } catch (err) {
                                console.warn("couldn't find location")
                            }
                        }
                        if (location) {
                            path.push(encodeURIComponent(utils.formatRegion(location)))
                        }
                        break
                    case 'plan':
                        let plan = membership.selectedPlan
                        if (!plan) {
                            try {
                                plan = cart.items[cart.items.length - 1].selectedPlan
                            } catch (err) {
                                console.warn("couldn't find plan")
                            }
                        }
                        if (plan) {
                            path.push(encodeURIComponent(plan.name))
                        }
                        break
                    default:
                        break
                }
            })
        }
        return path.join('/')
    }

    _onBackClick(e) {
        const { history, cart } = this.props
        if (this.isProductPreviewModeEnabled) {
            history.goBack()
            return
        }
        const parts = this._getUrlParts()
        const backstackPath = backstack[parts.shift()]
        utils.scrollTop()
        if (backstackPath) {
            let path = null
            if (utils.isString(backstackPath)) {
                path = routes[backstackPath]
            } else if (utils.isObject(backstackPath) && backstackPath.route) {
                const pathVars = this._getUrlPathVars(backstackPath.params)
                path = `${routes[backstackPath.route]}/${pathVars}`
            }
            if (path) {
                if (path === routes.contactInfoPage) {
                    if (cart.items.length > 1) {
                        history.replace(path, 'lastData')
                    } else {
                        history.goBack()
                    }
                } else {
                    history.replace(path)
                }
            } else {
                history.goBack()
            }
        } else {
            history.goBack()
        }
    }

    _onLogoClick(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.homePage)
    }

    _onCartClick(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.cartPage)
    }

    _shouldShowBackButton() {
        const { location, history } = this.props
        switch (location.pathname) {
            case routes.homePage:
            case routes.maintenancePage:
            case routes.claimPage:
            case routes.recipientThankyouPage:
                return false
            default:
                return !!history.length
        }
    }

    _shouldShowCartButton() {
        const { location } = this.props
        switch (location.pathname) {
            case routes.claimPage:
            case routes.enrollmentPage:
            case routes.reviewEnrollmentPage:
            case routes.recipientThankyouPage:
            case routes.maintenancePage:
                return false
            default:
                return true
        }
    }

    _setMeta() {
        const { t, location } = this.props
        switch (location.pathname) {
            case routes.faqPage:
                utils.setCanonicalURL(t('faqCononicalUrl'))
                utils.setPageTitle(t('faqTitle'))
                utils.setPageMeta(t('faqMeta'))
                break
            default:
            case routes.homePage:
                utils.setCanonicalURL(t('homeCononicalUrl'))
                utils.setPageTitle(this.isProductPreviewModeEnabled
                    ? t('homePreviewTitle')
                    : t('homeTitle'))
                utils.setPageMeta(t('homeMeta'))
                break
        }
    }

    _getEnvironment() {
        const { environment } = this.props;

        if (environment === 'dev') {
            return 'Dev';
        }
        if (environment === 'qa') {
            return 'QA';
        }
        if (environment === 'staging') {
            return 'Staging';
        }

        return '';
    }

    render() {
        const { cart } = this.props
        const showBackbutton = this._shouldShowBackButton()
        const showCartbutton = this._shouldShowCartButton()
        const cartCount = Array.isArray(cart.items) ? cart.items.length : 0
        this._setMeta()
        return (
            <header className="toolbar">
                <div className="toolbar--inner app--centered">
                    {showBackbutton ? (
                        <Ak
                            className="toolbar--back"
                            text="&nbsp;"
                            onClick={this._onBackClick.bind(this)}
                        />
                    ) : null}
                    <Ak className="toolbar--logo" onClick={this._onLogoClick.bind(this)}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <img
                                    className="toolbar--logo-img"
                                    src="/images/logo.svg"
                                    alt="AAA"
                                    id="logoAAA"
                                />
                            </div>
                            <div>
                                {this._getEnvironment()}
                            </div>
                        </div>
                    </Ak>
                    {showCartbutton ? (
                        <Ak
                            className="toolbar--cart"
                            onClick={this._onCartClick.bind(this)}
                        >
                            <img
                                className="toolbar--cart-img"
                                src="/images/icons/cart-icon.svg"
                                alt="Cart"
                                id="cartAAA"
                            />
                            {cartCount ? (
                                <span className="toolbar--count">{cartCount}</span>
                            ) : null}
                        </Ak>
                    ) : null}
                </div>
            </header>
        )
    }

    get isProductPreviewModeEnabled() {
        return this.props.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart,
        productPreviewModeEnabled: state.config.productPreviewModeEnabled,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(Toolbar)))
