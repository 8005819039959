import React from 'react';
import './enrollmentSummary.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Btn, { SIZE_SMALL, COLOR_ALT_FULL } from '../btn/btn';
import utils from '../../utils/utils';
import Card from '../card/card';
import { PADDING_ALL } from '../../utils/styleValues';
import Ak from '../ak/ak';

class EnrollmentSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailsOpen: true,
            responsive: null,
        };
    }

    _toggleDetails(e) {
        const { detailsOpen } = this.state;
        this.setState({
            detailsOpen: !detailsOpen
        });

    }

    _formatName(firstName, middleInitial, lastName) {
        let name = `${firstName} `;
        if (middleInitial) {
            name += `${middleInitial}. `;
        }
        name += lastName;
        return name;
    }

    _formatLocation(city, state) {
        return utils.formatRegion({
            city,
            state
        });
    }

    _responsiveCheck() {
        if (window.matchMedia('screen and (min-width: 48rem)').matches) {
            this.setState({
                responsive: false
            });
        } else {
            this.setState({
                responsive: true
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this._responsiveCheck()
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    renderAssociatesByName(associates) {
        const { t } = this.props
        return associates.map((ass, index) => {
            console.log(ass)
            if (ass.firstName !== '') {
                return (
                    <div id={ass.id}>
                        {index === 0 && <p className="summary-label">{t('associates')}</p>}
                        <p className="summary-value-grey">
                            {ass.firstName} {ass.middleName} {ass.lastName}
                        </p>
                    </div>
                )
            }
        })
    }

    renderAssociatesByCost(associates, selectedPlan) {
        const { t } = this.props
        return associates.map((ass, index) => {
            if (ass.firstName !== '') {
                return (
                    <div id={ass.id}>
                        {index === 0 && (
                            <p
                                className="membership-item--enrollment"
                                style={{ color: 'white' }}
                            >
                                {t('associates')}
                            </p>
                        )}
                        <p
                            className="summary-value-grey"
                            style={{ textAlign: 'right', fontWeight: 'bold', color: 'black' }}
                        >
                            $
                            {selectedPlan.freeAssociatesAllowedNumber &&
                                selectedPlan.freeAssociatesAllowedNumber > index
                                ? parseFloat(0).toFixed(2)
                                : selectedPlan.costPerAssociate.toFixed(2)}
                        </p>
                    </div>
                )
            }
        })
    }

    renderAssociatesByNone(associates) {
        const { t } = this.props
        return associates.map((ass, index) => {
            if (ass.firstName !== '') {
                return (
                    <div id={ass.id}>
                        {index === 0 && (
                            <p className="summary-label" style={{ color: 'white' }}>
                                {t('associates')}
                            </p>
                        )}
                        <p className="summary-value-grey" style={{ color: 'white' }}>
                            {ass.firstName}
                        </p>
                    </div>
                )
            }
        })
    }

    render() {
        const { t, enrollment, onEdit } = this.props;
        const { detailsOpen } = this.state;
        const detailsLabel = detailsOpen ? t('hideDetails') : t('viewDetails');
        const {
            firstName,
            middleInitial,
            lastName,
            address1,
            city,
            state,
            zipCode,
            birthYear,
            phoneNumber,
            address,
            membershipNumber,
            associates
        } = enrollment;
        return (
            <Card padding={PADDING_ALL} className="display-block margin-top">

                {!this.state.responsive ? (
                    <div className={this.state.detailsOpen ? "enrollment-summary" : 'none'}>
                        <div className="enrollment-summary--group">
                            <p className="summary-label">Name</p>
                            <p className="summary-value grey">{this._formatName(firstName, middleInitial, lastName)}</p>
                            <p className="summary-label">Address</p>
                            <p className="summary-value grey">{address}</p>
                            <p className="summary-label">{t('enrollmentZipCodeLabel')}</p>
                            <p className="summary-value grey">{zipCode}</p>

                        </div>
                        <div className="enrollment-summary--group">
                            <p className="summary-label">Email address</p>
                            <p className="summary-value grey">{address1}</p>
                            <p className="summary-label">City</p>
                            <p className="summary-value grey">{city}</p>
                            <p className="summary-label">{t('enrollmentBirthYearLabel')}</p>
                            <p className="summary-value grey">{birthYear}</p>
                        </div>
                        <div className="enrollment-summary--group">
                            <p className="summary-label">Telephone number</p>
                            <p className="summary-value grey">{phoneNumber}</p>
                            <p className="summary-label">State</p>
                            <p className="summary-value grey">{state.fullName}</p>
                            <p className="summary-label">Membership number</p>
                            <p className="summary-value grey">{membershipNumber}</p>
                        </div>
                    </div>
                ) : (<div className={this.state.detailsOpen ? "enrollment-summary" : 'none'}>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Name</p>
                        <p className="summary-value grey">{this._formatName(firstName, middleInitial, lastName)}</p>
                        <p className="summary-label">Email address</p>
                        <p className="summary-value grey">{address1}</p>
                        <p className="summary-label">Telephone number</p>
                        <p className="summary-value grey">{phoneNumber}</p>
                    </div>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Address</p>
                        <p className="summary-value grey">{address}</p>
                        <p className="summary-label">City</p>
                        <p className="summary-value grey">{city}</p>
                        <p className="summary-label">State</p>
                        <p className="summary-value grey">{state.fullName}</p>
                    </div>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">{t('enrollmentZipCodeLabel')}</p>
                        <p className="summary-value grey">{zipCode}</p>
                        <p className="summary-label">{t('enrollmentBirthYearLabel')}</p>
                        <p className="summary-value grey">{birthYear}</p>
                        <p className="summary-label">Membership number</p>
                        <p className="summary-value grey">{membershipNumber}</p>
                    </div>
                </div>)}
                <div className={!this.state.detailsOpen ? "enrollment-summary" : 'none'}>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Name</p>
                        <p className="summary-value grey">{this._formatName(firstName, middleInitial, lastName)}</p>
                    </div>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Email address</p>
                        <p className="summary-value grey">{address1}</p>
                    </div>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Telephone number</p>
                        <p className="summary-value grey">{phoneNumber}</p>
                    </div>
                </div>
                <hr className={'hr'} />
                {(associates && associates.length > 0) && <div className={this.state.detailsOpen ? "enrollment-summary" : 'none'}>
                    <div className="enrollment-summary--group">
                        <p className="summary-label">Associates</p>
                        {associates.map((ass, index) => {
                            return <p className="summary-value grey">{this._formatName(ass.firstName, ass.middleInitial, ass.lastName)}</p>
                        })}
                    </div>
                </div>}

                <div className="enrollment-summary--details-row">

                    <Ak
                        className="enrollment-summary--details-toggle"
                        onClick={this._toggleDetails.bind(this)}
                        text={detailsLabel} />

                    <Btn label={t('edit')}
                        onClick={onEdit}
                        className={[
                            COLOR_ALT_FULL,
                            SIZE_SMALL
                        ]} />

                </div>
            </Card>
        );
    }
}

export default withTranslation('global')(withRouter(EnrollmentSummary));

