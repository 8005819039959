import React from 'react';
import './checkoutPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import InjectedCheckoutForm from '../../components/checkoutForm/checkoutForm';
import { PADDING_ALL } from '../../utils/styleValues';
import {
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerTelephone,
    setCustomerMiddleInitial,
    setCustomerEmail,
    setCustomerEmailConfirm,
    setCardName,
    setCardNumber,
    setCardType,
    setCardExpirationMonth,
    setCardExpirationYear,
    setSecurityCode,
    setBillingZipCode,
    setCustomerDonorBilling,
    setCustomerDonorCity,
    setCustomerDonorPostalCode,
    setCustomerDonorStateProvince,
    setCustomerDonorStreetAddress
} from '../../redux/cart/actions';
import utils from '../../utils/utils';
import getCardType from '../../utils/cardType';
import config from '../../config';
import { Elements } from 'react-stripe-elements';
const { routes } = config;

class CheckoutPage extends React.Component {

    _onValueChange(prop, value) {
        const { dispatch } = this.props;
        switch (prop) {
            case 'customerFirstName':
                dispatch(setCustomerFirstName(value));
                break;
            case 'customerLastName':
                dispatch(setCustomerLastName(value));
                break;
            case 'customerTelephone':
                dispatch(setCustomerTelephone(value));
                break;
            case 'customerMiddleInitial':
                dispatch(setCustomerMiddleInitial(value));
                break;
            case 'customerEmail':
                dispatch(setCustomerEmail(value));
                break;
            case 'customerEmailConfirm':
                dispatch(setCustomerEmailConfirm(value));
                break;
            case 'customerDonorBilling':
                dispatch(setCustomerDonorBilling(value))
                break
            case 'customerDonorCity':
                dispatch(setCustomerDonorCity(value))
                break
            case 'customerDonorPostalCode':
                dispatch(setCustomerDonorPostalCode(value))
                break
            case 'customerDonorStateProvince':
                dispatch(setCustomerDonorStateProvince(value))
                break
            case 'customerDonorStreetAddress':
                dispatch(setCustomerDonorStreetAddress(value))
                break
            case 'cardName':
                dispatch(setCardName(value));
                break;
            case 'cardNumber':
                const cardNumber = value.substr(0, 16);
                dispatch(setCardNumber(cardNumber));
                dispatch(setCardType(getCardType(cardNumber)))
                break;
            case 'cardExpirationMonth':
                dispatch(setCardExpirationMonth(value.substr(0, 2)));
                break;
            case 'cardExpirationYear':
                dispatch(setCardExpirationYear(value.substr(0, 4)));
                break;
            case 'securityCode':
                dispatch(setSecurityCode(value.substr(0, 4)));
                break;
            case 'billingZipCode':
                dispatch(setBillingZipCode(value.substr(0, 10)));
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        this.updateScroll();
    }

    updateScroll() {
        document.getElementById('cartAAA').scrollIntoView();
        utils.scrollTop();
    }

    _onSubmit(data) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.reviewOrderPage);
    }

    render() {
        const { t, cart } = this.props;

        if ((Array.isArray(cart.items) && cart.items.length > 0) || window.location.hostname === 'localhost') {

            return (
                <section className="page checkout-page">
                    <Hero title={t('checkoutPageTitle')} />

                    <Section padding={PADDING_ALL} className="checkout-page--blurb">
                        <p>{t('checkoutPageBlurb')}</p>
                    </Section>
                    <Section padding={PADDING_ALL} className="checkout-page-form">
                        <Elements>
                            <InjectedCheckoutForm data={cart}
                                onValueChange={this._onValueChange.bind(this)}
                                onSubmit={this._onSubmit.bind(this)} />
                        </Elements>
                    </Section>
                </section >
            );
        } else {
            return (
                <section className="page checkout-page">
                    <Hero title={t('checkoutPageTitle')} />

                    <Section padding={PADDING_ALL} className="checkout-page--blurb">
                        <h3 className="cart-page--empty-title">{t('emptyCartTitle')}</h3>
                        <p className="cart-page--empty-text">{t('emptyCartText')}</p>
                    </Section>
                </section>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(CheckoutPage)));

