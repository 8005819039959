import config from '../config'
import utils from './utils'

const { endpoints } = config

function http(url,
	method = 'GET',
	data = null,
	headers = {},
	expectsData = true) {
	const options = {
		method: method,
		mode: 'cors',
		headers: Object.assign({
			'Content-Type': 'application/json; charset=utf-8',
		},
			headers),
	}
	if (data) {
		if (
			headers &&
			headers['Content-Type'] === 'application/x-www-form-urlencoded'
		) {
			options.body = utils.formEncode(data)
		} else if (utils.isString(data)) {
			options.body = data
		} else if (utils.isObject(data)) {
			options.body = JSON.stringify(data)
		}
	}
	return fetch(url, options).then((response) => {
		return response && expectsData ? response.json() : response
	})
}

class API {
	constructor(config = {}) {
		this.config = config
	}
	payment(data) {
		const url = endpoints.payment(this.config.paymentApihost)
		this.config.debug && console.log('API -- payment', url)
		return http(url, 'POST', data /*, headers */)
	}
	faq() {
		const url = endpoints.faq(/*this.config.siteUrl*/)
		this.config.debug && console.log('API -- faq', url)
		return http(url)
	}
	location(loc) {
		const url = endpoints.location(this.config.apiUrl, loc)
		this.config.debug && console.log('API -- location', url)
		return http(url)
	}
	state(state) {
		const url = endpoints.state(this.config.apiUrl, state)
		this.config.debug && console.log('API -- state', url)
		return http(url)
	}
	states() {
		const url = endpoints.states(this.config.apiUrl)
		this.config.debug && console.log('API -- states', url)
		return http(url)
	}
	branch(branchId) {
		const url = endpoints.branch(this.config.apiUrl, branchId)
		this.config.debug && console.log('API -- branch', url)
		return http(url)
	}
	postGift(data) {
		const url = endpoints.postGift(this.config.apiUrl)
		this.config.debug && console.log('API -- postGift', url)
		return http(url, 'POST', data, {
			'Content-Type': 'application/json',
		})
	}
	postSearchPromoCodesForProducts(data) {
		const url = endpoints.postSearchPromoCodesForProducts(this.config.apiUrl)
		this.config.debug &&
			console.log('API -- postSearchPromoCodesForProducts', url)
		return http(url, 'POST', data, {
			'Content-Type': 'application/json',
		})
	}
	getClaim(email, token) {
		const url = endpoints.getClaim(this.config.apiUrl, email, token)
		this.config.debug && console.log('API -- getClaim', url)
		return http(url)
	}
	postClaim(data) {
		const url = endpoints.postClaim(this.config.apiUrl)
		this.config.debug && console.log('API -- postClaim', url)
		return http(url,
			'POST',
			data,
			{
				'Content-Type': 'application/json',
			},
			false)
	}
	contactUsCreate(data) {
		const url = endpoints.postContactUs(this.config.apiUrl)
		return http(url, 'POST', data, {
			'Content-Type': 'application/json',
		})
	}
	searchRedemptionCode(redemptionCode) {
		const url = endpoints.searchRedemptionCode(this.config.apiUrl,
			redemptionCode)
		this.config.debug && console.log('API -- searchRedemptionCode', url)
		return http(url)
	}
	enrollRedemptionCode(redemptionCode) {
		const url = endpoints.enrollRedemptionCode(this.config.apiUrl)
		this.config.debug && console.log('API -- searchRedemptionCode', url)
		return http(url, 'POST', { ...redemptionCode })
	}
	contactUsReasons() {
		const url = endpoints.contactUsReasons(this.config.apiUrl)
		this.config.debug && console.log('API -- contactUsReason', url)
		return http(url)
	}
}

export default API
