import React from 'react';
import './membershipSummary.css';
import MembershipItem from '../../components/membershipItem/membershipItem';

class MembershipSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			detailsOpen: true,
			collapseAll: false
		};
		this._onChildToggleView = this._onChildToggleView.bind(this);
	}

	_onCollapseAllSummary() {
		this.setState({
			detailsOpen: true,
			collapseAll: true
		});
	}

	_onChildToggleView() {
		this.setState({
			collapseAll: false
		})
	}

	render() {
		const { items, hideButtons, onEdit, isPurchasePage } = this.props;
		if (Array.isArray(items) && items.length > 0) {
			return (
				<div className="membership-summary">
					<div className="membership-summary--collapse-bar">
						<label className="membership-summary--gift-information">Gift information</label>
						<a
							className="membership-summary--collapse-all"
							onClick={this._onCollapseAllSummary.bind(this)}>
							Collapse All
						</a>
					</div>
					{items.map((v, k) => {
						return (
							<MembershipItem cartMembership={v}
								onEdit={onEdit}
								hideButtons={hideButtons}
								index={k}
								detailsOpen={this.state.detailsOpen}
								collapseAll={this.state.collapseAll}
								onChildToggleView={this._onChildToggleView}
								key={k}
								isPurchasePage={isPurchasePage}
							/>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default MembershipSummary;

