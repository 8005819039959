import React from 'react';
import './termsPage.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
// import Ak from '../../components/ak/ak';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import utils from '../../utils/utils';
import config from '../../config';

const { routes } = config;

class TermsPage extends React.Component {

    _onPrivacyClick(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.privacyPage);
    }

    render() {
        const { t } = this.props;
        return (
            <section className="page terms-page">
                <Hero title={t('termsPageTitle')} />

                <Section padding={PADDING_ALL} className="terms-page--blurb">
                    <p>{t('tnc:lastModified')}</p>
                    <div dangerouslySetInnerHTML={{ __html: t('tnc:termsContent') }}></div>
                </Section>
            </section >
        );
    }
}
export default withTranslation('global')(withRouter(TermsPage));
