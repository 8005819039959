import React from 'react';
import './cardMembershipType.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';

import Card from '../card/card';
import Ak from '../ak/ak';
import Btn, { SIZE_MEDIUM, DISPLAY_INLINE, COLOR_ACTION } from '../btn/btn';
import { PADDING_ALL } from '../../utils/styleValues';
import utils from '../../utils/utils';
import { setSelectedPlan } from '../../redux/membership/actions';
import config from '../../config';

const { routes } = config;

class CardMembershipType extends React.Component {

    constructor(props) {
        super(props);
        this._onMembershipTypeSelectBound = this._onMembershipTypeSelect.bind(this);
        this._learnMoreClickBound = this._learnMoreClick.bind(this);
    }

    _onMembershipTypeSelect(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { membershipType, membership, dispatch, history } = this.props;
        if (membershipType && membership) {
            dispatch(setSelectedPlan(membershipType));
            utils.scrollTop();
            history.push(routes.contactInfoPage);
        }
    }

    _learnMoreClick(e) {
        const { membershipType, membership, history } = this.props;
        if (membershipType && membership) {
            const { recipientLocation } = membership;
            const region = recipientLocation ? encodeURIComponent(utils.formatRegion(recipientLocation)) : 'unknown';
            const membershipName = encodeURIComponent(membershipType.name);
            utils.scrollTop();
            history.push(`${routes.membershipDetailPage}/${region}/${membershipName}`);
        }
    }

    _buildURL() {
        const { membershipType } = this.props;
        const url = window.location.href.split('/');
        const loc = url.pop();
        url.pop();
        return `${url.join('/')}${routes.membershipDetailPage}/${loc}/${encodeURIComponent(membershipType.name)}`;
    }

    render() {
        const { t, membershipType } = this.props;
        // const feesClass = utils.calculateFeesTotalCost(membershipType) ? 'has-fees' : '';
        // const description = utils.truncateText(membershipType.description || '', config.descriptionTruncationLength, '…');
        return (
            <Card className={`card-membership-type`}
                padding={PADDING_ALL} itemProp="itemListElement" itemScope itemType="http://schema.org/Product">

                <link itemProp="url" href={this._buildURL()} />

                <div className={`card-membership-type--details`}>
                    <h4 className="card-membership-type--name"
                        itemProp="name">{membershipType.displayName} </h4>
                    <div itemProp="description" style={{ borderStyle: "none" }} className="ql-container ql-snow">
                        <span className="card-membership-type--description ql-editor" dangerouslySetInnerHTML={{ __html: membershipType.shortDescription }}>
                        </span>
                        <Ak onClick={this._learnMoreClickBound}
                            className="card-membership-type--learn-more"
                            text={t('learnMore')} />
                    </div>
                </div>

                <div className="card-membership-type--prices">
                    <div style={{ fontSize: "1.25rem", lineHeight: "1.25rem", color: '#4c4c4c' }}>
                        <strong style={{ fontWeight: "400" }}>${membershipType.cost.toFixed(2)}&nbsp;/&nbsp;{t('year')}</strong>
                    </div>
                    <div className={membershipType.enrollmentFee > 0 ? "" : "none"} style={{ fontSize: "1rem", lineHeight: "1.25rem", color: '#4c4c4c', marginTop: "0.5rem" }}>
                        {t('Enrollment fee')}&nbsp;/&nbsp;${membershipType.enrollmentFee.toFixed(2)}
                    </div>
                    {(membershipType.costPerAssociate != null && membershipType.costPerAssociate > 0) &&
                        <div className={membershipType.costPerAssociate > 0 ? "" : "none"} style={{ fontSize: "1rem", lineHeight: "1.25rem", color: '#4c4c4c' }}>
                            {t('Associate Members')}&nbsp;/&nbsp;${membershipType.costPerAssociate.toFixed(2)}
                        </div>
                    }
                    <div style={{ marginTop: "0.5rem" }}>
                        <Btn label={t('select')}
                            onClick={this._onMembershipTypeSelectBound}
                            className={[
                                'card-membership-type--select-btn',
                                SIZE_MEDIUM,
                                DISPLAY_INLINE,
                                COLOR_ACTION
                            ]} />
                    </div>
                </div>
                <div className="fill"></div>
            </Card>
        );
    }

    get isProductPreviewModeEnabled() {
        return this.props.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        productPreviewModeEnabled: state.config.productPreviewModeEnabled,
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(CardMembershipType)));

