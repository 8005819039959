export const SET_REDEMPTION_CODE = 'redemptionCode.SET_REDEMPTION_CODE'
export const SET_ENROLLMENT_REDEMPTION_CODE = 'redemptionCode.SET_ENROLLMENT_REDEMPTION_CODE'
export const CLEAN_REDEMPTION_CODE = 'redemptionCode.CLEAN_REDEMPTION_CODE'

export function setRedemptionCode(redemptionCode) {
  return {
    type: SET_REDEMPTION_CODE,
    value: redemptionCode
  }
}

export function setEnrollmentRedemptionCode(key, value) {
  return {
    type: SET_ENROLLMENT_REDEMPTION_CODE,
    value: { key, value }
  }
}

export function cleanRedemptionCode() {
  return {
    type: CLEAN_REDEMPTION_CODE,
  }
}