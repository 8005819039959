import React from 'react';
import './ak.css';

import keys from '../../utils/keys';
import utils from '../../utils/utils';

class Ak extends React.PureComponent {

	constructor(props) {
		super(props);
		this._onKeyPressBound = this._onKeyPress.bind(this);
	}

	_onKeyPress(e) {
		const { onClick } = this.props;
		if (e && e.keyCode === keys.KEY_ENTER && utils.isFunction(onClick)) {
			onClick(e);
			e.preventDefault();
		}
	}

	_onFocus(e) {
		window.addEventListener('keypress', this._onKeyPressBound);
	}

	_onBlur(e) {
		window.removeEventListener('keypress', this._onKeyPressBound);
	}

	render() {
		const { children, text } = this.props;
		return (
			<a tabIndex="0"
				onFocus={this._onFocus.bind(this)}
				onBlur={this._onBlur.bind(this)}
				{...this.props}>{text || children}</a>
		);
	}
}

export default Ak;

