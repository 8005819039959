export const ADD_TO_CART = 'cart.ADD_TO_CART';
export const REMOVE_FROM_CART = 'cart.REMOVE_FROM_CART';
export const REPLACE_CART_ITEM = 'cart.REPLACE_CART_ITEM';
export const REPLACE_ALL_CART_ITEMS = 'cart.REPLACE_ALL_CART_ITEMS';
export const CUSTOMER_EMAIL = 'cart.CUSTOMER_EMAIL';
export const CUSTOMER_EMAIL_CONFIRM = 'cart.CUSTOMER_EMAIL_CONFIRM';
export const CARD_NAME = 'cart.CARD_NAME';
export const CARD_NUMBER = 'cart.CARD_NUMBER';
export const CARD_TYPE = 'cart.CARD_TYPE';
export const CARD_EXPIRATION_MONTH = 'cart.CARD_EXPIRATION_MONTH';
export const CARD_EXPIRATION_YEAR = 'cart.CARD_EXPIRATION_YEAR';
export const SECURITY_CODE = 'cart.SECURITY_CODE';
export const BILLING_ZIP_CODE = 'cart.BILLING_ZIP_CODE';
export const RESET_CART = 'cart.RESET_CART';
export const STRIPE_CREATE_SOURCE_RESPONSE = 'cart.STRIPE_CREATE_SOURCE_RESPONSE';
export const STRIPE_TAX_CREATE_SOURCE_RESPONSE = 'cart.STRIPE_TAX_CREATE_SOURCE_RESPONSE';
export const CUSTOMER_FIRSTNAME = 'cart.CUSTOMER_FIRSTNAME';
export const CUSTOMER_LASTNAME = 'cart.CUSTOMER_LASTNAME';
export const CUSTOMER_MIDDLEINITIAL = 'cart.CUSTOMER_MIDDLEINITIAL';
export const CUSTOMER_PHONENUMBER = 'cart.CUSTOMER_PHONENUMBER';
export const CUSTOMER_DONOR_BILLING = 'CUSTOMER_DONOR_BILLING';
export const CUSTOMER_DONOR_CITY = 'CUSTOMER_DONOR_CITY';
export const CUSTOMER_DONOR_POSTALCODE = 'CUSTOMER_DONOR_POSTALCODE';
export const CUSTOMER_DONOR_STATEPROVINCE = 'CUSTOMER_DONOR_STATEPROVINCE';
export const CUSTOMER_DONOR_STREETADDRESS = 'CUSTOMER_DONOR_STREETADDRESS';
export const PROMO_CODE = 'cart.PROMO_CODE'

export function addToCart(membership) {
    return {
        type: ADD_TO_CART,
        value: membership
    };
}

export function removeFromCart(index) {
    return {
        type: REMOVE_FROM_CART,
        value: index
    };
}

export function replaceCartItem(index, data) {
    return {
        type: REPLACE_CART_ITEM,
        index: index,
        value: data
    };
}

export function replaceAllCartItems(data) {
    return {
        type: REPLACE_ALL_CART_ITEMS,
        value: data
    };
}

export function resetCart() {
    return {
        type: RESET_CART
    };
}

export function setCustomerEmail(email) {
    return {
        type: CUSTOMER_EMAIL,
        value: email
    };
}

export function setCustomerEmailConfirm(email) {
    return {
        type: CUSTOMER_EMAIL_CONFIRM,
        value: email
    };
}

export function setCardName(name) {
    return {
        type: CARD_NAME,
        value: name
    };
}

export function setCardNumber(number) {
    return {
        type: CARD_NUMBER,
        value: number
    };
}


export function setCardType(cardType) {
    return {
        type: CARD_TYPE,
        value: cardType
    };
}

export function setCardExpirationMonth(expirationMonth) {
    return {
        type: CARD_EXPIRATION_MONTH,
        value: expirationMonth
    };
}

export function setCardExpirationYear(expirationYear) {
    return {
        type: CARD_EXPIRATION_YEAR,
        value: expirationYear
    };
}

export function setSecurityCode(securityCode) {
    return {
        type: SECURITY_CODE,
        value: securityCode
    };
}

export function setBillingZipCode(zipCode) {
    return {
        type: BILLING_ZIP_CODE,
        value: zipCode
    };
}

export function setStripeCreateSourceResponse(tokenResponse) {
    return {
        type: STRIPE_CREATE_SOURCE_RESPONSE,
        value: tokenResponse
    };
}

export function setStripeTaxCreateSourceResponse(tokenResponse) {
    return {
        type: STRIPE_TAX_CREATE_SOURCE_RESPONSE,
        value: tokenResponse
    };
}

export function setCustomerFirstName(name) {
    return {
        type: CUSTOMER_FIRSTNAME,
        value: name
    };
}

export function setCustomerLastName(lastName) {
    return {
        type: CUSTOMER_LASTNAME,
        value: lastName
    };
}

export function setCustomerMiddleInitial(middleInitial) {
    return {
        type: CUSTOMER_MIDDLEINITIAL,
        value: middleInitial
    };
}

export function setCustomerTelephone(phone) {
    return {
        type: CUSTOMER_PHONENUMBER,
        value: phone
    };
}

export function setCustomerDonorBilling(value) {
    return {
        type: CUSTOMER_DONOR_BILLING,
        value,
    }
}

export function setCustomerDonorCity(value) {
    return {
        type: CUSTOMER_DONOR_CITY,
        value,
    }
}

export function setCustomerDonorPostalCode(value) {
    return {
        type: CUSTOMER_DONOR_POSTALCODE,
        value,
    }
}

export function setCustomerDonorStateProvince(value) {
    return {
        type: CUSTOMER_DONOR_STATEPROVINCE,
        value,
    }
}

export function setCustomerDonorStreetAddress(value) {
    return {
        type: CUSTOMER_DONOR_STREETADDRESS,
        value,
    }
}

export function setPromoCode(value) {
    return {
        type: PROMO_CODE,
        value: value
    };
}