import { SET_CONFIG_ACTION, SET_CONFIG_STRIPE_ACTION ,SET_ENVIROMENT } from './actions';
import utils from '../../utils/utils';
import config from '../../config';

const initialState = {
	apiUrl: "",
	instrumentationKey: "",
	paymentApiJsSecurityKey: "",
	paymentApiToken: "",
	paymentApihost: "",
	paymentApikey: "",
	paymentTAToken: "",
	siteUrl: "",
	secretKey: "",
	publishableKey: "",
	clientId: "",
	environment: "",
	productPreviewModeEnabled: false,
	maintenanceMode: false,
};

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case 'LOAD_STORED_STATE':
			const storedState = action.storedState && action.storedState.config;
			return utils.testSessionData(storedState, initialState, config.sessionStorageTTL);
		case SET_CONFIG_ACTION:
			return Object.assign({}, state, action.value);
		case SET_CONFIG_STRIPE_ACTION:
			return Object.assign({}, state, action.value);
		case SET_ENVIROMENT:
			return Object.assign({}, state, {
				environment: action.value,
				lastUpdated:new Date().getTime()
			});
		default:
			return state;
	}
}
