function encode(val) {
  return encodeURIComponent(val)
}

const PAYMENT = {
  auth: true,
  currency: 'USD',
  type: 'home',
  country: 'us',
}
const PRODUCT_LEVEL_NAME = {
  basic    : 'Basic/Classic',
  plus     : 'Plus',
  premier  : 'Premier',
  plusRV   : 'Plus RV',
  premierRV: 'Premier RV',
}

const config = {
  productLevel: PRODUCT_LEVEL_NAME,
  payment: PAYMENT,
  endpoints: {
    payment: (host) => {
      return `https://${host}/v1/transactions`
    },
    faq: (url = '') => {
      return `${url}faq.json`
    },
    location: (url, location) => {
      return `${url}/api/Locations/${encode(location)}`
    },
    state: (url, state) => {
      return `${url}/api/StateProvinces/${encode(state)}`
    },
    states: (url) => {
      return `${url}/api/StateProvinces/Get`
    },
    branch: (url, branchId) => {
      return `${url}/api/Branches/${encode(branchId)}`
    },
    getClaim: (url, email, claimToken) => {
      return `${url}/api/Gifts/claim?Email=${encode(email)}&ClaimToken=${encode(claimToken)}`
    },
    postClaim: (url) => {
      return `${url}/api/Gifts/claim`
    },
    postGift: (url) => {
      return `${url}/api/Gifts`
    },
    postSearchPromoCodesForProducts: (url) => {
      return `${url}/api/PromoCode`
    },
    postContactUs: (url) => {
      return `${url}/api/Contacus/PostAsync`
    },
    searchRedemptionCode: (url, redemptionCode) => {
      return `${url}/api/Gifts/claimRedemptionCode?code=${encode(redemptionCode)}`
    },
    enrollRedemptionCode: (url) => {
      return `${url}/api/Gifts/claimRedemptionCode`
    },
    aaastop: (url) => {
      return `https://www.aaa.com/stop`
    },
    contactUsReasons: (url) => {
      return `${url}/api/Contacus/GetSupportActions`
    },
  },
  routes: {
    homePage: '/',
    maintenancePage: '/',
    locationSelectPage: '/location-select',
    chooseMembershipPage: '/choose-membership',
    previewMembershipPage: '/preview-membership',
    membershipDetailPage: '/membership',
    contactInfoPage: '/contact-info',
    cartPage: '/cart',
    checkoutPage: '/checkout',
    reviewOrderPage: '/review-order',
    giverThankyouPage: '/gift-thankyou',
    faqPage: '/help',
    termsPage: '/terms',
    privacyPage: '/privacy',
    claimPage: '/claim',
    enrollmentPage: '/enrollment',
    reviewEnrollmentPage: '/review-enrollment',
    recipientThankyouPage: '/recieve-thankyou',
    contactusPage: '/contactus',
    membershipInformationPage: '/membership-Information',
    redemptionCode: '/redemption-code',
    enrollmentRedemptionCodePage: '/enrollment-redemption-code',
    reviewRedemptionCodeEnrollment: '/review-redemption-code-enrollment',
  },
  backstack: {
    'location-select': 'homePage',
    //'location-select': 'maintenancePage',
    'choose-membership': 'locationSelectPage',
    membership: {
      route: 'chooseMembershipPage',
      params: ['location'],
    },
    'contact-info': null,
    cart: 'contactInfoPage',
    checkout: 'cartPage',
    'review-order': 'checkoutPage',
    'gift-thankyou': 'reviewOrderPage',
    enrollment: 'claimPage',
    'review-enrollment': 'enrollmentPage',
    'recieve-thankyou': 'reviewEnrollmentPage',
    'membership-Information': 'recieve-thankyou',
  },
  defaultMembershipTypeDetails:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae turpis dolor, eget rutrum justo.',
  // adjustmentTypeFee: 3,
  // defaultOpenPlan: 'Plus',
  AAAURL: 'https://aaa.com',
  maxGiftMessageLength: 330,
  maxFutureDaysSendGift: 90,
  creditCardNumberLengthMax: 16,
  creditCardNumberLengthMin: 13,
  orderSessionStorageTTL: 600000,
  sessionStorageTTL: 3600000,
  sortPlans: true,
  // plansSortMethod: 'RV_LAST', // also COST_ASC
  plansSortMethod: 'COST_ASC',
  descriptionTruncationLength: 100,
  maxNumberOfAssociates: 5,
  // sessionStorageTTL: 30000
}

export default config
