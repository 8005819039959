import React from 'react';
import './faqPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL, PADDING_NONE } from '../../utils/styleValues';
import QuestionBox from "../../components/questionBox/questionBox";
import API from '../../utils/api';
import { addError } from '../../redux/errors/actions';
import { COLOR_ACTION } from '../../components/btn/btn';

class FaqPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faq: []
        };
        this.api = null;
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
        this._fetchData();
    }

    _fetchData() {
        const { t, dispatch } = this.props;
        this.api.faq().then((data) => {
            this.setState({ faq: data });
        }).catch((err) => {
            console.error(err);
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorRetry'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _renderQuestions() {
        const { faq } = this.state;
        if (Array.isArray(faq) && faq.length > 0) {
            return faq.map((v, k) => {
                return (<QuestionBox key={k} question={v} />);
            });
        }
        return null;
    }

    render() {
        const { t } = this.props;
        return (
            <section className="page faq-page">
                <Hero image="/images/heros/faq-hero.jpg"
                    title={t('faqPageTitle')} />

                <Section padding={PADDING_ALL} className="faq-page--blurb">
                    <p>{t('faqPageDescription')}</p>
                </Section>

                <Section padding={PADDING_NONE} className="faq-page--questions">
                    {this._renderQuestions()}
                </Section>

            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(FaqPage)));

