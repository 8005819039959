import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    REPLACE_CART_ITEM,
    CUSTOMER_EMAIL,
    CUSTOMER_EMAIL_CONFIRM,
    CUSTOMER_FIRSTNAME,
    CUSTOMER_LASTNAME,
    CUSTOMER_MIDDLEINITIAL,
    CUSTOMER_PHONENUMBER,
    CARD_NAME,
    CARD_NUMBER,
    CARD_TYPE,
    CARD_EXPIRATION_MONTH,
    CARD_EXPIRATION_YEAR,
    SECURITY_CODE,
    BILLING_ZIP_CODE,
    RESET_CART,
    STRIPE_CREATE_SOURCE_RESPONSE,
    STRIPE_TAX_CREATE_SOURCE_RESPONSE,
    CUSTOMER_DONOR_BILLING,
    CUSTOMER_DONOR_CITY,
    CUSTOMER_DONOR_POSTALCODE,
    CUSTOMER_DONOR_STATEPROVINCE,
    CUSTOMER_DONOR_STREETADDRESS,
    REPLACE_ALL_CART_ITEMS,
    PROMO_CODE
} from './actions';

import utils from '../../utils/utils';
import config from '../../config';

const initialState = {
    items: [],
    customerFirstName: '',
    customerLastName: '',
    customerTelephone: '',
    customerMiddleInitial: '',
    customerDonorBilling: true,
    customerDonorCity: '',
    customerDonorPostalCode: '',
    customerDonorStateProvince: '',
    customerDonorStreetAddress: '',
    customerEmail: '',
    customerEmailConfirm: '',
    cardName: '',
    cardNumber: '',
    cardType: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    securityCode: '',
    billingZipCode: '',
    lastUpdated: new Date().getTime(),
    stripeCreateSourceResponse: [],
    stripeTaxCreateSourceResponse: []
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case 'LOAD_STORED_STATE':
            const storedState = action.storedState && action.storedState.cart;
            return utils.testSessionData(storedState, initialState, config.sessionStorageTTL);
        case RESET_CART:
            return Object.assign({}, state, initialState, {
                lastUpdated: -1
            });
        case ADD_TO_CART:
            // logic to prevent adding same entry to cart twice
            const newItem = action.value;
            newItem.selectedPlan.netCost = newItem.selectedPlan.cost
            const hasItem = state.items.filter((v) => {
                return utils.isValidCartEntry(v);
            }).filter((v) => {
                console.log('add cart', v, newItem);
                return utils.compareCartEntries(v, newItem);
            });
            let items = state.items;
            if (!hasItem.length) {
                items = items.concat(newItem);
            }
            return Object.assign({}, state, {
                items: items,
                lastUpdated: new Date().getTime()
            });
        case REMOVE_FROM_CART:
            return Object.assign({}, state, {
                items: state.items.filter((v, k) => {
                    return k !== action.value;
                }),
                lastUpdated: new Date().getTime()
            });
        case REPLACE_CART_ITEM:
            return Object.assign({}, state, {
                items: state.items.map((v, k) => {
                    if (k === action.index) {
                        return Object.assign({}, v, action.value);
                    } else {
                        return v;
                    }
                }),
                lastUpdated: new Date().getTime()
            });
        case REPLACE_ALL_CART_ITEMS:
            return Object.assign({}, state, {
                items: state.items.map((v, k) => {
                    const i = action.value.find((x) => {
                        return x.index === k
                    })
                    if (i && i.item) {
                        return i.item;
                    } else {
                        return v;
                    }
                }),
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_EMAIL:
            return Object.assign({}, state, {
                customerEmail: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_EMAIL_CONFIRM:
            return Object.assign({}, state, {
                customerEmailConfirm: action.value,
                lastUpdated: new Date().getTime()
            });
        case CARD_NAME:
            return Object.assign({}, state, {
                cardName: action.value,
                lastUpdated: new Date().getTime()
            });
        case CARD_NUMBER:
            return Object.assign({}, state, {
                cardNumber: action.value,
                lastUpdated: new Date().getTime()
            });
        case CARD_TYPE:
            return Object.assign({}, state, {
                cardType: action.value,
                lastUpdated: new Date().getTime()
            });
        case CARD_EXPIRATION_MONTH:
            return Object.assign({}, state, {
                cardExpirationMonth: action.value,
                lastUpdated: new Date().getTime()
            });
        case CARD_EXPIRATION_YEAR:
            return Object.assign({}, state, {
                cardExpirationYear: action.value,
                lastUpdated: new Date().getTime()
            });
        case SECURITY_CODE:
            return Object.assign({}, state, {
                securityCode: action.value,
                lastUpdated: new Date().getTime()
            });
        case BILLING_ZIP_CODE:
            return Object.assign({}, state, {
                billingZipCode: action.value,
                lastUpdated: new Date().getTime()
            });
        case STRIPE_CREATE_SOURCE_RESPONSE:
            return Object.assign({}, state, {
                stripeCreateSourceResponse: action.value,
                lastUpdated: new Date().getTime()
            });
        case STRIPE_TAX_CREATE_SOURCE_RESPONSE:
            return Object.assign({}, state, {
                stripeTaxCreateSourceResponse: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_FIRSTNAME:
            return Object.assign({}, state, {
                customerFirstName: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_LASTNAME:
            return Object.assign({}, state, {
                customerLastName: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_MIDDLEINITIAL:
            return Object.assign({}, state, {
                customerMiddleInitial: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_PHONENUMBER:
            return Object.assign({}, state, {
                customerTelephone: action.value,
                lastUpdated: new Date().getTime()
            });
        case CUSTOMER_DONOR_BILLING:
            return Object.assign({}, state, {
                customerDonorBilling: action.value,
                lastUpdated: new Date().getTime(),
            })
        case CUSTOMER_DONOR_CITY:
            return Object.assign({}, state, {
                customerDonorCity: action.value,
                lastUpdated: new Date().getTime(),
            })
        case CUSTOMER_DONOR_POSTALCODE:
            return Object.assign({}, state, {
                customerDonorPostalCode: action.value,
                lastUpdated: new Date().getTime(),
            })
        case CUSTOMER_DONOR_STATEPROVINCE:
            return Object.assign({}, state, {
                customerDonorStateProvince: action.value,
                lastUpdated: new Date().getTime(),
            })
        case CUSTOMER_DONOR_STREETADDRESS:
            return Object.assign({}, state, {
                customerDonorStreetAddress: action.value,
                lastUpdated: new Date().getTime(),
            })
        case PROMO_CODE:
            return Object.assign({}, state, {
                promoCode: action.value,
                lastUpdated: new Date().getTime(),
            })
        default:
            return state;
    }
}
