import React from 'react';
import './claimPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION } from '../../components/btn/btn';
import TextInput from '../../components/textInput/textInput';
import ErrorList from '../../components/errorList/errorList';
import {
    setPin,
    setEmail,
    setClaimToken,
    setFirstName,
    setLastName,
    setPlanName,
    setShortDescription,
    setGiverFirstName,
    setGiverLastName,
    setGiverEmail,
    setPlanResponse,
    setMiddleInitial,
    setPhoneNumber,
    setAddress1,
    setAddress2,
    setAddress,
    setState,
    setStateName,
    setMembershipNumber,
    setBirthYear,
    setZipCode,
    setCity,
    setAssociates
} from '../../redux/claim/actions';
import validators, { runValidators } from '../../utils/validators';
import utils from '../../utils/utils';
import config from '../../config';
import API from '../../utils/api';
import { addError } from '../../redux/errors/actions';
import Card from '../../components/card/card';
import Ak from '../../components/ak/ak';

const { routes, productLevel } = config;

class PinPage extends React.Component {

    constructor(props) {
        super(props);
        const claim = props.claim;
        this.productLevelName = null;
        const t = props.t;
        this.isFetching = false;
        this.validators = {
            pin: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('pinLabel')} ${t('isRequired')}`
            }]
        };
        this.state = {
            error: null,
            pin: '',
            hasSubmittedForm: false,
            email: claim.email,
            token: claim.claimToken,
            pinError: runValidators(claim.pin, this.validators.pin),
        };
        this.api = null;
        this._learnMoreClickBound = this._learnMoreClick.bind(this);
    }

    componentDidMount() {
        const { t, dispatch, conf } = this.props;
        this.api = new API(conf);
        this.isFetching = true;
        Promise.all([
            this._setUrlEmail(),
            this._setUrlToken()
        ]).then((data) => {
            return this._fetchClaim(data[0], data[1]).then(this._setClaim.bind(this));
        }).then(() => {
            this.isFetching = false;
            if (this.state.hasSubmittedForm) {
                this._checkPin();
            }
        }).catch((err) => {
            this.setState({
                error: t('genericError')
            });
            this.isFetching = false;
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _learnMoreClick(e) {
        const { history } = this.props;
        // const claimName = encodeURIComponent(claim.planName);
        utils.scrollTop();
        // history.push(`${routes.claimPage}/${claimName}`);
        history.push(routes.membershipInformationPage);
    }

    _setClaim(response) {
        const { t, dispatch, history } = this.props;
        if (response && response.data) {
            this.setState({
                pin: response.data.pin
            });
            if (history.action === 'POP') {
                this.productLevelName = response.data.product ? response.data.product.productLevelName : ''
                dispatch(setGiverEmail(response.data.purchaseEmail));
                dispatch(setGiverFirstName(response.data.purchaseFirstName));
                dispatch(setGiverLastName(response.data.purchaseLastName));
                dispatch(setShortDescription(response.data.productShortDescription));
                dispatch(setPlanName(response.data.productName));
                dispatch(setAddress1(decodeURIComponent(response.data.email)));
                dispatch(setFirstName(response.data.firstName));
                dispatch(setLastName(response.data.lastName));
                dispatch(setMiddleInitial(response.data.middleInitial));
                dispatch(setPhoneNumber(response.data.telephone));
                dispatch(setPlanResponse(response.data.product));
                dispatch(setAssociates(response.data.associates));
                dispatch(setAddress2(''));
                dispatch(setAddress(''));
                dispatch(setState(''));
                dispatch(setStateName(''));
                dispatch(setMembershipNumber(null));
                dispatch(setBirthYear(null));
                dispatch(setZipCode(''));
                dispatch(setCity(''));
                dispatch(setPin(''));
            }
        } else {
            this.setState({
                error: t('genericError')
            });
        }
        return Promise.resolve();
    }

    _fetchClaim(email, claimToken) {
        const { t, dispatch } = this.props;
        return this.api.getClaim(email, claimToken).catch((err) => {
            console.log('response error', err);
            console.warn(err);
            this.setState({
                error: t('genericError')
            });
            dispatch(addError({
                message: t('claimError'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }


    _setUrlEmail() {
        return new Promise((resolve, reject) => {
            const { dispatch, location } = this.props;
            const email = location.search ? this._getQueryParam('email') : this.state.email;
            if (email && validators.isEmail(email)) {
                dispatch(setEmail(email));
                resolve(email);
                return;
            }
            reject(new Error());
        });
    }

    _setUrlToken() {
        return new Promise((resolve, reject) => {
            const { dispatch, location } = this.props;
            const token = location.search ? this._getQueryParam('token') : this.state.token;
            if (validators.isNotNullOrEmpty(token)) {
                dispatch(setClaimToken(token));
                resolve(token);
                return;
            }
            reject(new Error());
        });
    }

    _getQueryParam(key) {
        const query = window.location.search.replace(/^\?/, '').split('&');
        let value = null;
        if (query.length) {
            value = query.reduce((acc, v) => {
                const q = v.split('=');
                if (q[0] === key) {
                    acc = decodeURIComponent(q[1]);
                }
                return acc;
            }, null);
        }
        return value;
    }

    _onPinChange(val) {
        const { dispatch } = this.props;
        dispatch(setPin(utils.trimString(val)));
    }

    _onPinValidationChange(error) {
        this.setState({
            pinError: error
        });
    }

    _onSubmitForm(e) {
        e.preventDefault();
        this.setState({
            hasSubmittedForm: true
        });
        if (!this._hasError()) {
            this.setState({
                error: null
            });
            if (!this.isFetching) {
                this._checkPin();
            }
        }
    }

    _checkPin() {
        const { t, claim, history } = this.props;
        const { pin } = this.state;
        if (pin && claim.pin && pin.toUpperCase() === claim.pin.toUpperCase()) {
            utils.scrollTop();
            history.push(routes.enrollmentPage);
        } else {
            this.setState({
                error: t('invalidPinError')
            });
        }
    }

    _hasError() {
        const { pinError } = this.state;
        return pinError;
    }

    _listErrors() {
        const { t } = this.props;
        const { hasSubmittedForm, pinError } = this.state;
        if (hasSubmittedForm && this._hasError()) {
            const list = [];
            if (pinError) {
                list.push({
                    key: 'pinError',
                    msg: pinError
                });
            }
            return (
                <ErrorList heading={t('fixMessage')} list={list} />
            );
        } else {
            return null;
        }
    }

    renderDisclosure(t, productLevelName) {
        if (productLevelName == productLevel.plus || productLevelName == productLevel.plusRV ||
            productLevelName == productLevel.premier || productLevelName == productLevel.premierRV) {
            return <p className='pin-page-disclosure' dangerouslySetInnerHTML={{ __html: t('pinDisclosureForPlusAndPremier') }}></p>
        }
    }

    render() {
        const { t, claim } = this.props;
        const { pin } = claim;
        const { hasSubmittedForm, error, pinError, productLevelName } = this.state;
        return (
            <section className="page pin-page">
                <Hero image="/images/heros/claim-hero.jpg"
                    title={t('pinPageTitle')} />

                <Section className="pin-page--message" padding={PADDING_ALL}>
                    <p dangerouslySetInnerHTML={{ __html: t('pinPageBlurb') }}></p>
                    <form action=""
                        name="pin_form"
                        encType="multipart/form-data"
                        method="post"
                        autoComplete="off">

                        {this._listErrors()}

                        {error ? (<p className="warning">{error}</p>) : null}
                        <Card className={`card-membership-type`}
                            padding={PADDING_ALL}>
                            <div className={`pin-page--details`}>
                                <h4 className="pin-page--name"
                                    itemProp="name">{claim.planName}</h4>
                                <div itemProp="description" style={{ borderStyle: "none" }} className="pin-page--description ql-container ql-snow">
                                    <span style={{ padding: 0 }} className="ql-editor" dangerouslySetInnerHTML={{ __html: claim.shortDescription }}>
                                    </span>
                                </div>
                                <Ak
                                    onClick={this._learnMoreClickBound}
                                    text={t('learnMore')}
                                    className="pin-page--learn-more" />
                            </div>
                            <hr className={'hr'} />
                            <div className={`pin-page--details`}>
                                <p><strong>FROM</strong></p>
                                <p className="pin-page--giver-info">{claim.giverFirstName} {claim.giverLastName}</p>
                                <p className="pin-page--giver-info">{claim.giverEmail}</p>
                            </div>
                        </Card>
                        {this.productLevelName && this.renderDisclosure(t, this.productLevelName)}
                        <p dangerouslySetInnerHTML={{ __html: t('pinGetStartedBlurb') }}></p>
                        <TextInput className="pin-form--pin"
                            value={pin}
                            error={hasSubmittedForm && pinError}
                            required={true}
                            onChange={this._onPinChange.bind(this)}
                            label={t('pinLabel')}
                            name="recipient_pin"
                            id="recipient_pin"
                            validators={this.validators.pin}
                            onValidationChange={this._onPinValidationChange.bind(this)} />

                        <Btn label={t('continue')}
                            type="submit"
                            onClick={this._onSubmitForm.bind(this)}
                            className={['pin-page--submit', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION]} />
                    </form>
                </Section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        claim: state.claim,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(PinPage)));

