import React from 'react';
import './footer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Section from '../../components/section/section';
import Ak from '../../components/ak/ak';
import { PADDING_NONE } from '../../utils/styleValues';
import config from '../../config';
import utils from '../../utils/utils';
import { resetCart } from '../../redux/cart/actions';
import { resetClaim } from '../../redux/claim/actions';
import { resetMembership } from '../../redux/membership/actions';
import { resetOrders } from '../../redux/orders/actions';

const { routes } = config;

class Footer extends React.PureComponent {

    _onPrivacyClick(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.privacyPage);
    }

    _onTermsClick(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.termsPage);
    }

    _onHelpClick(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.faqPage);
    }

    _onContactUsClick(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.contactusPage);
    }

    _debug() {
        const { dispatch } = this.props;
        if (window.location.href.includes('localhost')) {
            const styles = {
                position: 'absolute',
                right: '1rem',
                fontSize: '1rem',
            };
            return (
                <a style={styles}
                    onClick={() => {
                        dispatch(resetCart());
                        dispatch(resetClaim());
                        dispatch(resetMembership());
                        dispatch(resetOrders());
                        utils.scrollTop();
                        window.location.reload();
                    }}><span role="img" aria-label="reset redux">🗑️</span></a>
            );
        }
        return null;
    }

    render() {
        const { t } = this.props;
        return (
            <Section padding={PADDING_NONE} className="footer">
                <footer>
                    <ul className="footer--links">
                        <li>
                            <Ak className="app--footer-link app--help-link"
                                onClick={this._onHelpClick.bind(this)}
                                text={t('helpLinkText')} />
                        </li>
                        <li>
                            <Ak className="app--footer-link app--terms-link"
                                onClick={this._onContactUsClick.bind(this)}
                                text={t('contactUSText')} />
                        </li>
                        <li>
                            <Ak className="app--footer-link app--terms-link"
                                onClick={this._onTermsClick.bind(this)}
                                text={t('termsLinkText')} />
                        </li>
                    </ul>
                    <div className='footer--label'>
                        <span>Application Version {process.env.REACT_APP_VERSION}</span>
                    </div>
                    {this._debug()}
                </footer>
            </Section>
        );
    }

    get isProductPreviewModeEnabled() {
        return this.props.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        claim: state.claim,
        membership: state.membership,
        orders: state.orders,
        productPreviewModeEnabled: state.config.productPreviewModeEnabled,
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(Footer)));

