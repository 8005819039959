import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en';

// From example here: https://codesandbox.io/s/8n252n822

i18n.use(LanguageDetector).init({
	resources: {
		en: en
	},
	fallbackLng: 'en',
	debug: window.location.hostname === 'localhost',
	ns: ['global', 'tnc'],
	defaultNS: 'global',
	keySeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ','
	},
	react: {
		wait: true
	}
});

export default i18n;
