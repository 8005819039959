import React from 'react';
import './locationSelectPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION } from '../../components/btn/btn';
import {
    setStateName,
    setCityName,
    setRecipientLocation,
    setPendingRecipientLocation,
    setLocationSuggestions,
    resetMembership
} from '../../redux/membership/actions';
import TextInput from '../../components/textInput/textInput';
import utils from '../../utils/utils';
import API from '../../utils/api';
import config from '../../config';
import { addError } from '../../redux/errors/actions';

const { routes } = config;

// TODO: keyboard shortcuts (here or in TextInput...)

class LocationSelectPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            hasSearched: false,
            recipientState: "",
            pendingRecipientState: "",
            stateSuggestions: "",
            disabledLocation: true
        };
        this.inputRef = null;
        this.previousValue = '';
        this.isClearing = false;
        this.api = null;
    }

    componentDidMount() {
        utils.scrollTop();
        const { dispatch, conf } = this.props;
        this.api = new API(conf);
        this._setRecipientLocation(null);
        dispatch(resetMembership());
        dispatch(setPendingRecipientLocation(''));
        dispatch(setLocationSuggestions(null));
        // document.getElementById('state').focus();
        // document.getElementById('state_label').classList.add('bold');
        document.getElementById('cartAAA').focus();
        utils.scrollTop();
    }

    _deStrong() {
        document.getElementById('state_label').className = ('text-input--label');
    }

    _onClickGoToMemberships(e) {
        e.preventDefault();
        const { history, membership } = this.props;
        if (membership.recipientLocation) {
            utils.scrollTop();
            const region = utils.formatRegion(membership.recipientLocation);
            history.push(`${routes.chooseMembershipPage}/${encodeURIComponent(region)}`);
        }
    }

    _onLocationChange(val) {
        const { membership, dispatch } = this.props;
        const {
            recipientLocation
        } = membership;
        dispatch(setPendingRecipientLocation(val));
        if (!val.length) {
            this._setRecipientLocation(null);
            dispatch(setLocationSuggestions(null));
            this.isClearing = true;
        } else if ((val && val.length > 0) || (this.previousValue &&
            this.previousValue.length > val.length ** val.length)) {

            this.isClearing = false;
            if (recipientLocation && utils.formatRegion(recipientLocation) !== val) {
                this._setRecipientLocation(null);
            }
            this._requestLocation(val);
        }
        this.previousValue = val;
    }

    _requestLocation(val) {
        const { dispatch, t } = this.props;
        this.api.location(val + `, ${this.state.recipientState.state}`).then((suggestions) => {
            if (Array.isArray(suggestions.data) && !this.isClearing) {
                dispatch(setLocationSuggestions(suggestions.data));
            }
            this.setState({
                error: null,
                hasSearched: true
            });
        }).catch((err) => {
            console.warn(err);
            this.setState({
                error: t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _mapSuggestions(suggestion) {
        if (suggestion && suggestion.id) {
            return {
                name: utils.formatRegionState(suggestion),
                id: suggestion.id
            };
        } else {
            return null;
        }
    }

    _clearSuggestions() {
        const { dispatch } = this.props;
        dispatch(setLocationSuggestions(null));
    }

    _onLocationSelect(region) {
        const { dispatch } = this.props;
        if (region) {
            this._setRecipientLocation(region);
            dispatch(setCityName(region.city));
            dispatch(setLocationSuggestions(null));
            dispatch(setPendingRecipientLocation(utils.formatRegionState(region)));
        }
    }

    _setRecipientLocation(region) {
        const { dispatch } = this.props;
        dispatch(setRecipientLocation(region));
    }

    _onInputRef(ref) {
        this.inputRef = ref;
    }

    _onInputStateRef(ref) {
        this.inputRef = ref;
    }

    _showNoResultsMessage() {
        const { t, membership } = this.props;
        const { hasSearched } = this.state;
        if (hasSearched && membership.locationSuggestions && membership.locationSuggestions.length === 0) {
            Event("Event", "Location does not exist", 'City ' + membership.pendingRecipientLocation + ' does not exist ');
            return (
                <div className="location-select-page--help">
                    <p>{t('noResultsMessage')}<br /> <a href="//www.aaa.com/stop">{t('noResultsLink')}</a></p>
                </div>
            );
        }
        return null;
    }

    _goToEmptyResultsFaq() {
        const { history } = this.props;
        const hash = '#why_cant_i_find_the_city_of_my_intended_recipient_when_i_attempt_to_purchase_a_gift_membership';
        utils.scrollTop();
        history.push(routes.faqPage + hash);

    }

    _onStateChange(val) {
        const { dispatch } = this.props;
        // const {
        // 	recipientLocation
        // } = membership;
        this._setRecipientLocation(null);
        dispatch(setLocationSuggestions(null));
        this._mapSuggestions.bind(this)
        this.setState({ pendingRecipientState: val });

        if (!val.length) {
            this.setState({ stateSuggestions: null, disabledLocation: true });
            this.isClearing = true;
            this._requestState(val);
        } else if ((val && val.length >= 1) || (this.previousValue &&
            this.previousValue.length > val.length ** val.length)) {
            this.isClearing = false;
            if (this.state.recipientState !== this.state.pendingRecipientState) {
                this.setState({ disabledLocation: true });
            }
            if (this.state.recipientState) {
                this.setState({ recipientState: null });
            }
            this._requestState(val);
        }
        this.previousValue = val;
    }

    _requestState(val) {
        const { dispatch, t } = this.props;
        this.api.state(val).then((suggestions) => {
            if (Array.isArray(suggestions.data) && !this.isClearing) {
                //dispatch(setLocationSuggestions(suggestions.data));
                this.setState({ stateSuggestions: suggestions.data })
            }
            this.setState({
                error: null,
                hasSearched: true
            });
        }).catch((err) => {
            console.warn(err);
            this.setState({
                error: t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _onStateSelect(region) {
        const { dispatch } = this.props;
        if (region) {
            this._setRecipientLocation("");
            dispatch(setLocationSuggestions(null));
            dispatch(setPendingRecipientLocation(""));

            dispatch(setStateName(region.fullName));
            this.setState({
                stateSuggestions: null,
                recipientState: region,
                pendingRecipientState:
                    region.fullName,
                disabledLocation: false,
            });
        }
    }

    _mapStateSuggestions(suggestion) {
        if (suggestion && suggestion.stateProvinceId) {
            return {
                name: suggestion.fullName,
                stateInitials: suggestion.state,
                id: suggestion.stateProvinceId
            };
        } else {
            return null;
        }
    }

    render() {
        const { t, membership } = this.props;
        const { error } = this.state;
        return (
            <section className="page location-select-page">
                <Hero image="/images/heros/location-hero.jpg"
                    title={t('locationSelectPageTitle')} />

                <Section padding={PADDING_ALL} className="location-select-page--info app--inset">
                    <p>{t('locationBlurb')}</p>

                    <form action=""
                        className="location-select-page--form"
                        name="user_location_form"
                        encType="multipart/form-data"
                        method="post"
                        autoComplete="off">
                        {/* maybe autocomplete, make sure plays well with suggestions list */}

                        {error ? (<p className="warning">{error}</p>) : null}

                        <TextInput className="customer-location-input"
                            value={this.state.pendingRecipientState}
                            onChange={this._onStateChange.bind(this)}
                            onHideSuggestions={this._clearSuggestions.bind(this)}
                            onSuggestionSelect={this._onStateSelect.bind(this)}
                            label={t('stateInputLabel')}
                            // placeholder={t('stateInputPlaceholder')}
                            name="state"
                            onBlur={this._deStrong.bind(this)}
                            id="state"
                            suggestionsMap={this._mapStateSuggestions.bind(this)}
                            suggestions={this.state.stateSuggestions} />

                        <TextInput className="customer-location-input"
                            onInputRewf={this._onInputRef.bind(this)}
                            disabled={this.state.disabledLocation}
                            value={this.state.disabledLocation ? "" : membership.pendingRecipientLocation}
                            onChange={this._onLocationChange.bind(this)}
                            onHideSuggestions={this._clearSuggestions.bind(this)}
                            onSuggestionSelect={this._onLocationSelect.bind(this)}
                            label={t('locationInputLabel')}
                            // placeholder={t('locationInputPlaceholder')}
                            name="location"
                            id="location"
                            suggestionsMap={this._mapSuggestions.bind(this)}
                            suggestions={membership.locationSuggestions} />

                        {this._showNoResultsMessage()}

                        <Btn label={t('goToMemberships')}
                            type="submit"
                            onClick={this._onClickGoToMemberships.bind(this)}
                            disabled={!membership.recipientLocation}
                            className={[SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION, 'location-select-page--submit']} />

                    </form>
                </Section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(LocationSelectPage)));

