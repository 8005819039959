import React from 'react';
import './total.css';
import { withTranslation } from "react-i18next";
import utils from '../../utils/utils';
import { PADDING_ALL } from '../../utils/styleValues';
import Card from '../../components/card/card';

class Total extends React.Component {
    render() {
        const { t, items, hideFees } = this.props;
        const cartTotal = utils.calculateTotalGif(items);
        const membershipTotal = utils.calculateCartMembershipTotalCost(items);
        const feesTotal = utils.calculateCartFeesTotalCost(items);
        const cartCount = utils.countCartItems(items);
        const giftLabel = cartCount > 1 ? t('GIFTS') : t('GIFT');
        return (
            <Card padding={PADDING_ALL} className="total">
                {feesTotal > 0 && hideFees !== true ? (
                    <div>
                        <p>{t('subtotal')} <span className="total--value">${membershipTotal.toFixed(2)}</span></p>
                        <p>{t('fees')} <span className="total--value">${feesTotal.toFixed(2)}</span></p>
                    </div>
                ) : null}
                <div className="total--summary">
                    <p className="total--grand-qty"><span className="total--qty">{cartCount}</span>{giftLabel}</p>
                    <p className="total--grand-total">{t('total')} <span className="total--value">${cartTotal.toFixed(2)}</span></p>
                </div>
            </Card>
        );
    }
}

export default withTranslation('global')(Total);

