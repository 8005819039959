import React from 'react'
import './../../overlayLoader.css'
import './../contactInfoPage/contactInfoPage.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import API from '../../utils/api'
import utils from '../../utils/utils'
import validators, { runValidators } from '../../utils/validators'
import { customLoadingOverlay } from '../../styles/customLoadingOverlay';
import LoadingOverlay from 'react-loading-overlay-ts'
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ACTION
} from '../../components/btn/btn'
import { PADDING_ALL } from '../../utils/styleValues'
import TextInput from '../../components/textInput/textInput'
import Hero from '../../components/hero/hero'
import Section from '../../components/section/section'
import ErrorList from '../../components/errorList/errorList'
import SuccessList from '../../components/successList/successList'
import DropDown from '../../components/dropdown/dropdown'
import { addError } from '../../redux/errors/actions'
import { get } from 'lodash'

const initialContactUs = {
    fullName: null,
    email: null,
    comments: null,
    supportActionId: null,
}

const initialState = {
    contactUs: initialContactUs,
    loading: false,
    emailError: null,
    commentsError: null,
    nameError: null,
    stateError: null,
    cityError: null,
    reasonError: null,
    errorList: [],
    successList: [],
    error: null,
    hasSearched: false,
    recipientState: null,
    pendingRecipientState: null,
    stateSuggestions: null,
    disabledLocation: true,
    recipientLocation: null,
    pendingRecipientLocation: null,
    locationSuggestions: null,
    contactOptions: [],
    locationError: null
}

const MAX_CHARACTERS_COMMENT_INPUT = 1201
class ContactusPage extends React.Component {
    constructor(props) {
        super(props)
        const { t } = props
        this.validators = {
            email: [
                {
                    validator: validators.isEmailEmpty,
                    message: `${t('isRequired')}`,
                },
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                }
            ],
            comments: [
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                },
                {
                    validator: validators.isLengthLessThan(MAX_CHARACTERS_COMMENT_INPUT),
                    message: `${t('lessThan')} ${MAX_CHARACTERS_COMMENT_INPUT - 1}`,
                }
            ],
            fullName: [
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                }
            ],
            reason: [
                {
                    validator: validators.isPositive,
                    message: `${t('isRequired')}`,
                }
            ],
            state: [
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                }
            ],
            cityName: [
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                }
            ],
            location: [
                {
                    validator: validators.isNotNullOrEmpty,
                    message: `${t('isRequired')}`,
                }
            ],
        }

        this.state = {
            ...initialState,
        }

        this.api = null
        this.previousValue = ''
        this.isClearing = false
    }

    componentDidMount() {
        const { dispatch, t } = this.props

        utils.scrollTop()
        const { conf } = this.props
        this.api = new API(conf)
        this._setRecipientLocation(null)

        this.setState({
            loading: false,
            errorList: [],
            contactUs: {
                fullName: null,
                email: null,
                comments: null,
                supportActionId: null,
            },
            recipientState: null,
            recipientLocation: null,
            pendingRecipientLocation: null,
            pendingRecipientState: null,
        })
        utils.scrollTop()

        this.api
            .contactUsReasons()
            .then((contactOptions) => {
                this.setState({
                    contactOptions,
                    error: null,
                    hasSearched: true,
                })
            })
            .catch((err) => {
                console.warn(err)
                this.setState({
                    error: t('genericError'),
                })
                dispatch(addError({
                    message: t('errorMessageDefault'),
                    buttons: [
                        {
                            label: t('errorOK'),
                            onClick: 'dismiss',
                            color: COLOR_ACTION,
                        }
                    ],
                }))
            })
    }

    _resetContactUs = () => {
        this.setState = {
            ...initialState,
        }
    }

    _setPendingRecipientLocation = (val) => {
        this.setState({ pendingRecipientLocation: val })
    }

    _setLocationSuggestions = (val) => {
        this.setState({ locationSuggestions: val })
    }

    _setRecipientLocation = (region) => {
        this.setState({ recipientLocation: region })
    }

    _locationValidator = (location) => {
        return !location
    }

    _onLocationChange = (val) => {
        const { recipientLocation } = this.state
        this._setPendingRecipientLocation(val)
        const cityError = this._locationValidator(this.state.recipientLocation)
        this.setState({
            cityError,
        })

        if (!val.length) {
            this._setRecipientLocation(null)
            this._setLocationSuggestions(null)
            this.isClearing = true
            this.setState({
                cityError: true,
            })
        } else if (
            (val && val.length > 0) ||
            (this.previousValue &&
                (this.previousValue.length > val.length || this.previousValue.length < val.length))
        ) {
            this.isClearing = false
            if (recipientLocation && utils.formatRegion(recipientLocation) !== val) {
                this._setRecipientLocation(null)
                this.setState({
                    cityError: true,
                })
            }
            this._requestLocation(val)
        }
        this.previousValue = val


    }

    _requestLocation = (val) => {
        const { dispatch, t } = this.props
        this.api
            .location(val + `, ${this.state.recipientState.state}`)
            .then((suggestions) => {
                if (Array.isArray(suggestions.data) && !this.isClearing) {
                    this._setLocationSuggestions(suggestions.data)
                }
                this.setState({
                    error: null,
                    hasSearched: true,
                })
            })
            .catch((err) => {
                console.warn(err)
                this.setState({
                    error: t('genericError'),
                })
                dispatch(addError({
                    message: t('errorMessageDefault'),
                    buttons: [
                        {
                            label: t('errorOK'),
                            onClick: 'dismiss',
                            color: COLOR_ACTION,
                        }
                    ],
                }))
            })
    }

    _mapSuggestions = (suggestion) => {
        if (suggestion && suggestion.id) {
            return {
                name: utils.formatRegionState(suggestion),
                id: suggestion.id,
            }
        } else {
            return null
        }
    }

    _clearSuggestions = () => {
        this._setLocationSuggestions(null)
    }

    _setCityName = (val) => {
        const { contactUs } = this.state
        this.setState({ contactUs: { ...contactUs, cityName: val } })
    }

    _onLocationSelect = (region) => {
        const cityError = this._locationValidator(region)
        this.setState({
            cityError,
        })

        if (region) {
            this._setRecipientLocation(region)
            this._setCityName(region.city)
            this._setLocationSuggestions(null)
            this._setPendingRecipientLocation(utils.formatRegionState(region))
        }
    }

    _showNoResultsMessage = () => {
        const { t } = this.props
        const { locationSuggestions, pendingRecipientLocation } = this.state

        const { hasSearched } = this.state
        if (
            hasSearched &&
            locationSuggestions &&
            locationSuggestions.length === 0
        ) {
            Event('Event',
                'Location does not exist',
                'City ' + pendingRecipientLocation + ' does not exist ')
            return (
                <div className="location-select-page--help">
                    <p>
                        {t('noResultsMessage')}
                        <br /> <a href="//www.aaa.com/stop">{t('noResultsLink')}</a>
                    </p>
                </div>
            )
        }
        return null
    }

    _onStateChange = (val) => {
        this._setRecipientLocation(null)
        this._setLocationSuggestions(null)
        this._mapSuggestions.bind(this)
        this.setState({ pendingRecipientState: val })
        const stateError = this._locationValidator(this.state.recipientState)
        this.setState({
            stateError,
        })

        if (!val.length) {
            this.setState({
                recipientState: null,
                stateSuggestions: null,
                disabledLocation: true,
                stateError: true,
            })
            this.isClearing = true
            this._requestState(val)
        } else if (
            (val && val.length >= 1) ||
            (this.previousValue &&
                (this.previousValue.length > val.length || this.previousValue.length < val.length))
        ) {
            this.isClearing = false
            if (this.state.recipientState !== this.state.pendingRecipientState) {
                this.setState({ disabledLocation: true, stateError: true, recipientState: null })
            }
            if (this.state.recipientState) {
                this.setState({ recipientState: null })
            }
            this._requestState(val)
        }
        this.previousValue = val
    }

    _requestState = (val) => {
        const { dispatch, t } = this.props
        this.api
            .state(val)
            .then((suggestions) => {
                if (Array.isArray(suggestions.data) && !this.isClearing) {
                    this.setState({ stateSuggestions: suggestions.data })
                }
                this.setState({
                    error: null,
                    hasSearched: true,
                })
            })
            .catch((err) => {
                console.warn(err)
                this.setState({
                    error: t('genericError'),
                })
                dispatch(addError({
                    message: t('errorMessageDefault'),
                    buttons: [
                        {
                            label: t('errorOK'),
                            onClick: 'dismiss',
                            color: COLOR_ACTION,
                        }
                    ],
                }))
            })
    }

    _setStateName = (val) => {
        const { contactUs } = this.state
        this.setState({
            contactUs: { ...contactUs, stateName: val },
        })
    }

    _onStateSelect = (region) => {
        const stateError = this._locationValidator(region)
        this.setState({
            stateError,
        })

        if (region) {
            this._setRecipientLocation('')
            this._setLocationSuggestions(null)
            this._setPendingRecipientLocation('')

            this._setStateName(region.fullName)
            this.setState({
                stateSuggestions: null,
                recipientState: region,
                pendingRecipientState: region.fullName,
                disabledLocation: false,
            })
        }
    }

    _mapStateSuggestions = (suggestion) => {
        if (suggestion && suggestion.stateProvinceId) {
            return {
                name: suggestion.fullName,
                stateInitials: suggestion.state,
                id: suggestion.stateProvinceId,
            }
        } else {
            return null
        }
    }

    _hasError = () => {
        const { contactUs, recipientLocation, recipientState } = this.state

        const emailError = runValidators(contactUs.email, this.validators.email)
        const commentsError = runValidators(contactUs.comments,
            this.validators.comments)
        const nameError = runValidators(contactUs.fullName,
            this.validators.fullName)
        const stateError = runValidators(recipientState, this.validators.state)
        const cityError = runValidators(recipientLocation,
            this.validators.cityName)
        const reasonError = runValidators(contactUs.supportActionId,
            this.validators.reason)

        this.setState({
            emailError,
            commentsError,
            nameError,
            stateError,
            cityError,
            reasonError,
        })

        return (
            !!emailError ||
            !!commentsError ||
            !!nameError ||
            !!stateError ||
            !!cityError ||
            !!reasonError
        )
    }

    _onValueChange = (prop, value) => {
        const { contactUs } = this.state
        if (!/(Form|Error)$/.test(prop)) {
            switch (prop) {
                case 'fullName':
                    const nameError = runValidators(contactUs.fullName,
                        this.validators.fullName)
                    contactUs.fullName = value
                    this.setState({
                        contactUs: contactUs,
                        nameError,
                        successList: [],
                        errorList: [],
                    })
                    break
                case 'email':
                    contactUs.email = value
                    const emailError = runValidators(contactUs.email,
                        this.validators.email)
                    this.setState({
                        contactUs: contactUs,
                        emailError: emailError,
                        successList: [],
                        errorList: [],
                    })
                    break
                case 'comments':
                    contactUs.comments = value
                    const commentsError = runValidators(contactUs.comments,
                        this.validators.comments)
                    this.setState({
                        contactUs: contactUs,
                        commentsError: commentsError,
                        successList: [],
                        errorList: [],
                    })
                    break
            }
        }
    }

    _onSubmitClick = (e) => {
        e.preventDefault()
        try {
            const hasError = !this._hasError()
            if (hasError) {
                this.setState({
                    loading: true,
                })
                this._createApiRequest()
            }
        } catch (err) {
            console.log(err)
            this.setState({
                loading: false,
            })
        }
    }

    _onCancelForm = () => {
        const { onCancel } = this.props
        if (utils.isFunction(onCancel)) {
            onCancel()
        }
    }

    _handleReasonChange = (reason) => {
        console.log(reason, 'reason')
        if (reason) {
            const { value } = reason
            const reasonError = runValidators(value, this.validators.reason)
            const { contactUs } = this.state
            contactUs.supportActionId = value

            this.setState({
                contactUs,
                reasonError,
            })
        }
    }

    _change = (prop) => {
        return (val) => {
            this.setState({
                [prop]: val,
            })
            this._onValueChange(prop, val)
        }
    }

    _createApiRequest = () => {
        const { contactUs, recipientLocation } = this.state
        const { t } = this.props
        this.api
            .contactUsCreate({
                ...contactUs,
                cityId: get(recipientLocation, 'id') || '',
                branchId: get(recipientLocation, 'branchId') || '',
            })
            .then((response) => {
                if (response.success) {
                    this._handleApiSuccess(response)
                } else {
                    this._handleApiValidationErrors(response.errors)
                }
            })
            .catch((err) => {
                console.log(err)
                this._handleApiValidationErrors([{ message: t('errorMessageDefault') }])
            })
    }

    _handleApiSuccess = (response) => {
        this.setState({
            loading: false,
            successList: [
                {
                    key: 'Success',
                    msg: 'Thank you for contacting us.  If you have provided an email address then we will contact you as soon as possible.',
                }
            ],
            errorList: [],
            contactUs: {
                fullName: null,
                email: null,
                comments: null,
                supportActionId: -1,
            },
            recipientState: null,
            recipientLocation: null,
            pendingRecipientLocation: null,
            pendingRecipientState: null,
        })
        utils.scrollTop()
    }

    _handleApiValidationErrors = (errors) => {
        const errorList = []
        let i = 0
        errors.map((err, index) => {
            errorList.push({
                key: i,
                msg: err.message,
            })
            i++
        })

        this.setState({
            loading: false,
            errorList: errorList,
        })
    }

    _renderOptions = (options = []) => {
        const newKeys = { supportActionId: 'value', actionName: 'label' }
        const newOptions = options.map((keys) => {
            const newKey = Object.entries(keys).map((keyValue) => {
                const newKey = newKeys[keyValue[0]] || keyValue[0]
                return { [newKey]: keyValue[1] }
            })
            return Object.assign({}, ...newKey)
        })
        return newOptions
    }

    render() {
        const {
            loading,
            contactUs,
            emailError,
            commentsError,
            errorList,
            successList,
            pendingRecipientLocation,
            locationSuggestions,
            nameError,
            stateError,
            cityError,
            reasonError,
            contactOptions,
        } = this.state

        console.log(this.state)

        const { t } = this.props
        const btnsCssClass = 'contact-info-form--btns-single'
        const dropdownOptions = this._renderOptions(contactOptions)

        return (
            <section className="page contact-info-page">
                <LoadingOverlay active={loading} spinner styles={customLoadingOverlay} >
                    <Hero
                        image="/images/heros/faq-hero.jpg"
                        title={t('contactUsPageTitle')}
                    />
                    <Section padding={PADDING_ALL} className="contact-info-page--form">
                        <p className="text-justify">
                            For questions and comments about a gift purchase, or to provide
                            feedback about this site, please use the form below. We will try
                            to respond within a business day.
                        </p>
                        <ErrorList heading={t('fixMessage')} list={errorList} />
                        <SuccessList heading={t('successMessage')} list={successList} />
                        <p className="required-label">* {t('requiredField')}</p>
                        <form
                            action=""
                            name="contactus_form"
                            encType="multipart/form-data"
                            method="post"
                            autoComplete="off"
                        >
                            <TextInput
                                value={contactUs.fullName}
                                error={nameError}
                                onChange={this._change('fullName').bind(this)}
                                onValidationChange={this._change('fullNameError').bind(this)}
                                label={t('fullNameInputLabel')}
                                maxLength={200}
                                name="fullName"
                                id="fullName"
                                required
                            />
                            <TextInput
                                value={contactUs.email}
                                error={emailError}
                                onChange={this._change('email').bind(this)}
                                onValidationChange={this._change('emailError').bind(this)}
                                label={t('emailInputLabel')}
                                maxLength={255}
                                name="email"
                                id="email"
                                required
                            />
                            <DropDown
                                value={contactUs.supportActionId}
                                error={reasonError}
                                options={dropdownOptions || []}
                                placeholder={t('reason')}
                                onChange={this._handleReasonChange}
                                required={true}
                            />
                            <TextInput
                                className="customer-location-input"
                                value={this.state.pendingRecipientState}
                                error={stateError}
                                onChange={this._onStateChange.bind(this)}
                                onHideSuggestions={this._clearSuggestions.bind(this)}
                                onSuggestionSelect={this._onStateSelect.bind(this)}
                                label={t('contactStateInputLabel')}
                                name="state"
                                id="state"
                                suggestionsMap={this._mapStateSuggestions.bind(this)}
                                suggestions={this.state.stateSuggestions}
                                required
                            />
                            <TextInput
                                className="customer-location-input"
                                error={cityError}
                                disabled={this.state.disabledLocation}
                                value={
                                    this.state.disabledLocation ? '' : pendingRecipientLocation
                                }
                                onChange={this._onLocationChange.bind(this)}
                                onHideSuggestions={this._clearSuggestions.bind(this)}
                                onSuggestionSelect={this._onLocationSelect.bind(this)}
                                label={t('contactLocationInputLabel')}
                                name="location"
                                id="location"
                                suggestionsMap={this._mapSuggestions.bind(this)}
                                suggestions={locationSuggestions}
                                required
                            />
                            <TextInput
                                className="contact-info-form--gift-message"
                                value={contactUs.comments}
                                error={commentsError}
                                type="textarea"
                                required={true}
                                onChange={this._change('comments').bind(this)}
                                onValidationChange={this._change('commentsError').bind(this)}
                                label={t('commentInputLabel')}
                                name="comments"
                                maxLength={1000}
                                id="comments"
                            />
                            <p className="text-justify">
                                If you are contacting us about an existing or potential gift purchase,
                                please enter that gift recipient's State and City in the fields above.
                                In the Questions/Comments field, provide the following: buyer's name and
                                email, recipient's name and email, and the purchase and/or gift delivery
                                date (if applicable).
                            </p>
                            <div className={`contact-info-form--btns ${btnsCssClass}`}>
                                <Btn
                                    label={t('btnSendLabel')}
                                    type="submit"
                                    onClick={this._onSubmitClick.bind(this)}
                                    className={[
                                        'contact-info-form--submit',
                                        SIZE_MEDIUM,
                                        DISPLAY_BLOCK,
                                        COLOR_ACTION
                                    ]}
                                />
                            </div>
                        </form>
                    </Section>
                </LoadingOverlay>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ContactusPage)))
