import React from 'react';
import './iconTextBox.css';

const IconTextBox = (props) => {
	const { icon, title, text, className, iconAlt, noOutline } = props;
	const cssClass = className || '';
	const outlineClass = noOutline ? 'no-outline' : '';
	return (
		<div className={`icon-text-box ${cssClass} ${outlineClass}`}>
			<div className="icon-text-box--icon-wrapper">
				<div className="icon-text-box--icon-inner">
					<img className="icon-text-box--icon" src={icon} alt={iconAlt} />
				</div>
			</div>
			<div className="icon-text-box--text-wrapper">
				{title ? (<h4 className="icon-text-box--title">{title}</h4>) : null}
				<p className="icon-text-box--text">{text}</p>
			</div>
		</div>
	);
};

export default IconTextBox;

