import React from 'react';
import './section.css';

import utils from '../../utils/utils';

class Section extends React.Component {

	_inner(noInner) {
		const { children, tabletCentered } = this.props;
		const centeredClass = tabletCentered === false ? '' : 'app--centered';
		return (
			<div className={`${noInner ? '' : 'section--inner'} ${centeredClass}`}>
				{children}
			</div>
		);
	}

	render() {
		const { itemtype, padding, className, backgroundColor, name, noInner } = this.props;
		const bgStyle = backgroundColor ? {
			backgroundColor: backgroundColor
		} : {};
		const cssClass = utils.extractClassName(className);
		if (itemtype) {
			return (
				<div className={`section ${cssClass} ${utils.getPaddingClass(padding, 'section')}`}
					style={bgStyle} itemScope={'true'} itemType={itemtype}
					name={name}>
					{this._inner(noInner)}
				</div >
			);
		}
		return (
			<div className={`section ${cssClass} ${utils.getPaddingClass(padding, 'section')}`}
				style={bgStyle}
				name={name} >
				{this._inner(noInner)}
			</ div >
		);
	}
};

export default Section;

