import React from 'react';
import './membershipDetailPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { setRecipientLocation, setSelectedPlan } from '../../redux/membership/actions';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import MembershipDetails from '../../components/membershipDetails/membershipDetails';
import Card from '../../components/card/card';
import Loading from '../../components/loading/loading';
import IconTextBox from '../../components/iconTextBox/iconTextBox';
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ACTION,
    COLOR_ALT_SIMPLE
} from '../../components/btn/btn';
import {
    PADDING_ALL,
    PADDING_NO_BOTTOM,
    // PADDING_NONE,
    PADDING_HORIZONTAL
} from '../../utils/styleValues';
import utils from '../../utils/utils';
import API from '../../utils/api';
import config from '../../config';
import { addError } from '../../redux/errors/actions';

const { routes } = config;

class MembershipDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            location: null,
            plan: null,
        };
        this.api = null;
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
        if (this.isProductPreviewModeEnabled) {
            this._fetchDataFromQueryString()
        } else {
            this._fetchData();
        }
    }

    _fetchDataFromQueryString() {
        const { membership } = this.props;
        const { recipientLocation, selectedClub } = membership;
        const location = {
            id: '',
            branchId: recipientLocation.branchId,
            city: '',
            state: '',
        }
        const plan = {
            ...selectedClub.products[0]
        }
        this.setState({ location, plan })
    }

    _fetchData() {
        const { location, plan } = this.state;
        const urlParts = window.location.pathname.split('/');
        const planName = decodeURIComponent(urlParts[3]);
        const locationName = decodeURIComponent(urlParts[2]);
        if (!location) {
            return this._fetchRegionFromURL(locationName).then((location) => {
                if (!plan && location && location.branchId) {
                    return this._fetchPlanFromURL(location.branchId, planName)
                        .catch(this._onFetchError.bind(this));
                }
            }).catch(this._onFetchError.bind(this));
        } else if (!plan) {
            return this._fetchPlanFromURL(location.branchId, planName)
                .catch(this._onFetchError.bind(this));
        }
        return Promise.resolve();
    }

    _onFetchError(err) {
        const { t, dispatch } = this.props;
        console.warn(err);
        this.setState({
            error: t('genericError')
        });
        dispatch(addError({
            message: t('errorMessageDefault'),
            buttons: [{
                label: t('errorRetry'),
                onClick: 'dismiss',
                color: COLOR_ACTION
            }]
        }));
    }

    _fetchRegionFromURL(locationName) {
        const location = utils.reclaimRegion(locationName);
        if (location && location.city && location.state) {
            return this.api.location(utils.formatRegion(location)).then((suggestions) => {
                return utils.matchRegionToSuggestion(location, suggestions.data);
            }).then((location) => {
                this.setState({
                    location: location
                });
                return location;
            });
        }
        return Promise.reject(new Error());
    }

    _fetchPlanFromURL(branchId, planName) {
        if (branchId && planName) {
            return this.api.branch(branchId).then((club) => {
                return utils.matchPlanToSuggestion(planName, club.data ? club.data.products : []);
            }).then((plan) => {
                this.setState({
                    plan: plan
                });
                return plan;
            });
        }
        return Promise.reject(new Error());
    }

    _onTryAnotherRegion() {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.locationSelectPage);
    }

    _onTrySelectMembership() {
        const { history, membership } = this.props;
        if (membership && membership.recipientLocation) {
            const location = utils.formatRegion(membership.recipientLocation);
            utils.scrollTop();
            history.push(`${routes.chooseMembershipPage}/${encodeURIComponent(location)}`);
        } else {
            this._onTryAnotherRegion();
        }
    }

    _onTryAgain() {
        this.setState({
            error: null
        });
        this._fetchData();
    }

    _onSelect() {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { dispatch, history, membership } = this.props;
        const { recipientLocation } = membership;
        const { location, plan } = this.state;
        dispatch(setSelectedPlan(plan));
        if (!recipientLocation) {
            dispatch(setRecipientLocation(location));
        }
        utils.scrollTop();
        history.push(routes.contactInfoPage);
    }

    _onBack() {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { history } = this.props;
        utils.scrollTop();
        history.goBack();
    }

    _renderBody() {
        const { t, membership } = this.props;
        const { plan } = this.state;
        const { cityName, stateName } = membership;
        console.log('plan', plan);
        // const feesTotal = utils.calculateFeesTotalCost(plan);
        return (
            <section className="page membership-detail-page"
                itemScope itemType="http://schema.org/Product">
                <Hero image="/images/heros/membership-detail-hero.jpg"
                    isProductName={true}
                    title={plan.displayName}
                    subtitle={`${cityName}, ${stateName}`} />

                <Section padding={PADDING_ALL} className="membership-detail-page--membership-details">
                    <MembershipDetails membership={plan}
                        onSelect={this._onSelect.bind(this)} />
                </Section>
                <Section padding={PADDING_ALL} className="membership-detail-page--description">
                    <p>{t('membershipStartDate')}</p>
                </Section>
                <Section padding={PADDING_HORIZONTAL} className="membership-detail-page--benefits-label">
                    <h3 className="text-center"><strong>{t('additionalBenefits')}</strong></h3>
                </Section>
                <Section padding={PADDING_ALL}
                    className="membership-detail-page--benefits">

                    <Card padding={PADDING_ALL}>
                        <div className="app--centered">
                            <IconTextBox icon="/images/icons/battery-icon.svg"
                                title={t('membershipIconBox1Title')}
                                text={plan.battery} />

                            <IconTextBox icon="/images/icons/flat-tire-icon.svg"
                                title={t('membershipIconBox2Title')}
                                text={plan.flat} />

                            <IconTextBox icon="/images/icons/keys-icon.svg"
                                title={t('membershipIconBox3Title')}
                                text={plan.lockout} />

                            <IconTextBox icon="/images/icons/gas-icon.svg"
                                title={t('membershipIconBox4Title')}
                                text={plan.fuel} />
                        </div>
                    </Card>
                </Section>
                {plan.legalDisclosure && plan.legalDisclosure.replace(/(<([^>]+)>)/gi, "").trim()
                    && <Section padding={PADDING_ALL} className="membership-detail-page--legal">
                        <h3><strong>Terms</strong></h3>
                        <div itemProp="legalDisclosure" style={{ borderStyle: "none" }} className="ql-container ql-snow">
                            <span style={{ padding: 0 }} className="ql-editor" dangerouslySetInnerHTML={{ __html: plan.legalDisclosure }}>
                            </span>
                        </div>
                    </Section>}
                <Section padding={PADDING_NO_BOTTOM} className="membership-detail-page--action">
                    <Btn label={t('backToMembershipOptions')}
                        onClick={this._onBack.bind(this)}
                        className={[
                            'select--button',
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK,
                            COLOR_ALT_SIMPLE
                        ]} />
                    <Btn label={t('selectThisMembership')}
                        onClick={this._onSelect.bind(this)}
                        className={[
                            'select--button',
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK,
                            COLOR_ACTION
                        ]} />
                </Section>
            </section>
        );
    }

    _renderError() {
        const { t } = this.props;
        return utils.renderGenericPage('/images/heros/membership-detail-hero.jpg',
            t('genericMembershipDetailTitle'),
            t('somethingWentWrong'),
            t('genericError'),
            t('tryAgain'),
            this._onTryAgain.bind(this));
    }

    _renderLoading() {
        return (<section className="page"><Loading /></section>)
    }

    render() {
        const { location, plan, error } = this.state;
        if (error) {
            return this._renderError();
        } else if (location && plan) {
            return this._renderBody();
        } else {
            return this._renderLoading();
        }
    }

    get isProductPreviewModeEnabled() {
        return this.props.conf.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MembershipDetailPage)));

