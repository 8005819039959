import React from 'react';
import './errorList.css';

const ErrorList = (props) => {
	const { heading, list } = props;
	if (Array.isArray(list) && list.length > 0) {
		return (
			<div className="error-list">
				{heading ? (<p className="error-list--message">{heading}</p>) : null}
				<ul className="error-list--list">
					{list.map((v) => {
						return (<li key={v.key}>{v.msg}</li>);
					})}
				</ul>
			</div>
		)
	} else {
		return null;
	}
};

export default ErrorList;

