import React from 'react';
import './errorModal.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { clearError } from '../../redux/errors/actions';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ALT } from '../btn/btn';

class ErrorModal extends React.PureComponent {

    _closeModal() {
        const { dispatch } = this.props;
        dispatch(clearError(1));
    }

    _generateButtonOnclick(onClick) {
        let handler = null;
        if (typeof onClick === 'function') {
            handler = onClick;
        } else if (typeof onClick === 'string') {
            switch (onClick) {
                case 'dismiss':
                default:
                    handler = this._closeModal.bind(this);
            }
        }
        return handler;
    }

    _makeButtons(button, index) {
        return (
            <Btn label={button.label}
                key={index}
                onClick={this._generateButtonOnclick(button.onClick)}
                className={[
                    SIZE_MEDIUM,
                    DISPLAY_BLOCK,
                    button.color || COLOR_ALT
                ]} />
        );
    }

    render() {
        const { t, errors } = this.props;
        if (errors.errors.length > 0) {
            const error = errors.errors[0];
            return (
                <div className="error-modal">
                    <div className="error-modal--content">
                        <a className="error-modal--close"
                            onClick={this._closeModal.bind(this)}>{t('errorClose')}</a>
                        <img className="error-modal--icon" src="/images/icons/warning-icon.svg" alt="" />
                        <h3 className="error-modal--title">{error.title || t('errorTitle')}</h3>
                        <p className="error-modal--message">{error.message}</p>
                        {Array.isArray(error.buttons) && error.buttons.length > 0 && (
                            <div className="error-modal--buttons">
                                {error.buttons.map(this._makeButtons.bind(this))}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }
}

function mapStateToProps(state) {
    return {
        errors: state.errors
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ErrorModal)));

