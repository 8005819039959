import React from 'react';
import './reviewEnrollmentPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import EnrollmentForm from '../../components/enrollmentForm/enrollmentForm';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import EnrollmentSummary from '../../components/enrollmentSummary/enrollmentSummary';
import Ak from '../../components/ak/ak';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION } from '../../components/btn/btn';
import utils from '../../utils/utils';
import config from '../../config';
import {
    setFirstName,
    setMiddleInitial,
    setLastName,
    setAddress1,
    setCity,
    setState,
    setZipCode,
    setBirthYear,
    setPhoneNumber,
    setAddress,
    setMembershipNumber
} from '../../redux/claim/actions';
import API from '../../utils/api';
import { addError } from '../../redux/errors/actions';
import { customLoadingOverlay } from '../../styles/customLoadingOverlay';
import LoadingOverlay from 'react-loading-overlay-ts'

const { routes } = config;

class ReviewEnrollmentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isRequesting: false,
            errors: null,
            editInfo: false,
            loading: false
        };
        this.api = null;
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
        if (window.location.hash === '#edit') {
            this._setEditMode();
        }

        this.updateScroll();
    }

    _onEnrollmentClick() {
        const { t, dispatch, claim, history } = this.props;
        this.setState({
            errors: null,
            loading: true
        });
        this.api.postClaim({
            Token: claim.claimToken,
            StreetAddress: claim.address,
            FirstName: claim.firstName,
            LastName: claim.lastName,
            MiddleInitial: claim.middleInitial,
            Email: claim.address1,
            City: claim.city,
            State: claim.state.state,
            Zip: claim.zipCode,
            Phone: claim.phoneNumber,
            BirthYear: claim.birthYear,
            MembershipNumber: claim.membershipNumber,
            Associates: claim.associates
        }).then((result) => {
            if (result.status === 400) {
                console.log('enrollment api validation failure');
                throw Error(t('genericError'));
            }
            this.setState({
                loading: false
            });
            utils.scrollTop();
            history.push(routes.recipientThankyouPage);
        }).catch((err) => {
            this.setState({
                loading: false,
                errors: err ? err.message : t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    updateScroll() {
        document.getElementById('logoAAA').scrollIntoView();
        utils.scrollTop();
    }

    _onTermsClick(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.termsPage);
    }

    _setEditMode() {
        this.setState({
            editInfo: true
        });
        document.body.classList.add('edit-mode');
        window.location.hash = 'edit';
    }

    _clearEditMode() {
        this.setState({
            editInfo: false
        });
        document.body.classList.remove('edit-mode');
        window.location.hash = '';
    }

    _isEditMode() {
        const { editInfo } = this.state;
        return editInfo;
    }

    _onEditClick() {
        this._setEditMode();
    }

    _onEditCancel() {
        this._clearEditMode();
    }

    _onEditSubmit(data) {
        const { dispatch } = this.props;
        if (data) {
            dispatch(setFirstName(data.firstName));
            dispatch(setMiddleInitial(data.middleInitial));
            dispatch(setLastName(data.lastName));
            dispatch(setAddress1(data.address1))
            dispatch(setCity(data.city));
            dispatch(setState(data.state));
            dispatch(setZipCode(data.zipCode));
            dispatch(setBirthYear(data.birthYear));
            dispatch(setPhoneNumber(data.phoneNumber));
            dispatch(setAddress(data.address));
            dispatch(setMembershipNumber(data.membershipNumber));
        }
        setTimeout(() => {
            this._clearEditMode();
        }, 100)
    }

    _renderEditForm() {
        const { t, claim } = this.props;
        return (
            <section className="page review-enrollment-page">
                <Hero title={t('editEnrollmentTitle')} />
                <Section padding={PADDING_ALL}>
                    <EnrollmentForm data={claim}
                        saveButtonLabel={t('save')}
                        onCancel={this._onEditCancel.bind(this)}
                        onSubmit={this._onEditSubmit.bind(this)} />
                </Section>
            </section>
        );
    }

    _renderEnrollmentSummary() {
        const { t, claim } = this.props;
        const { isRequesting, errors, loading } = this.state;

        return (
            <section className="page review-enrollment-page">
                <LoadingOverlay active={loading} spinner styles={customLoadingOverlay} >
                    <Hero title={t('reviewEnrollmentTitle')} />

                    <Section padding={PADDING_ALL}>

                        {errors ? (
                            <p className="warning">{errors}</p>
                        ) : null}

                        <EnrollmentSummary enrollment={claim}
                            onEdit={this._onEditClick.bind(this)} />

                        <p className="review-enrollment-page--terms">{t('enrollmentTerms')}
                            <Ak className="review-enrollment-page--terms-link"
                                onClick={this._onTermsClick.bind(this)}
                                text={t('termsAndConditions')} />.</p>

                        <Btn label={t('enrollmentBtn')}
                            disabled={isRequesting}
                            onClick={this._onEnrollmentClick.bind(this)}
                            className={['review-enrollment-page--submit', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION]} />
                    </Section>
                </LoadingOverlay>
            </section>
        );
    }

    render() {
        const { editInfo } = this.state;
        if (editInfo) {
            return this._renderEditForm();
        } else {
            return this._renderEnrollmentSummary();
        }
    }
}

function mapStateToProps(state) {
    return {
        claim: state.claim,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ReviewEnrollmentPage)));

