import {
	CUSTOMER_REGION,
	PENDING_CUSTOMER_REGION,
	REGION_SUGGESTIONS,
	SELECTED_PLAN,
	RECIPIENT_FIRST_NAME,
	RECIPIENT_LAST_NAME,
	RECIPIENT_MIDDLE_INITIAL,
	RECIPIENT_EMAIL,
	RECIPIENT_EMAIL_CONFIRM,
	RECIPIENT_TELEPHONE,
	GIVER_NAME,
	GIFT_MESSAGE,
	DELIVERY_DATE,
	RESET_MEMBERSHIP,
	PREP_EDIT,
	SET_CLUB,
	STATE_NAME,
	CITY_NAME,
	LOCATION_KEY,
	RECIPIENT_SET_ASSOCIATES,
	RECIPIENT_ADD_ASSOCIATES,
	RECIPIENT_DELETE_ASSOCIATES,
	RECIPIENT_SET_ASSOCIATE_PROP,
	RECIPIENT_RESET_ASSOCIATE
} from './actions';
import utils from '../../utils/utils';
import config from '../../config';

const initialState = {
	recipientLocation: '',
	pendingRecipientLocation: '',
	locationSuggestions: [],
	selectedClub: null,
	selectedPlan: null,
	recipientFirstName: '',
	recipientLastName: '',
	recipientMiddleInitial: '',
	recipientEmail: '',
	recipientEmailConfirm: '',
	recipientTelephone: '',
	giverName: '',
	giftMessage: '',
	deliveryDate: '',
	stateName: '',
	cityName: '',
	key: '',
	associates: [],
	lastUpdated: new Date().getTime()
};

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case 'LOAD_STORED_STATE':
			const storedState = action.storedState && action.storedState.membership;
			return utils.testSessionData(storedState, initialState, config.sessionStorageTTL);
		case PREP_EDIT:
			return Object.assign({}, state, action.value, {
				lastUpdated: new Date().getTime()
			});
		case CUSTOMER_REGION:
			return Object.assign({}, state, {
				recipientLocation: action.value,
				lastUpdated: new Date().getTime()
			});
		case PENDING_CUSTOMER_REGION:
			return Object.assign({}, state, {
				pendingRecipientLocation: action.value,
				lastUpdated: new Date().getTime()
			});
		case REGION_SUGGESTIONS:
			return Object.assign({}, state, {
				locationSuggestions: action.value,
				lastUpdated: new Date().getTime()
			});
		case SELECTED_PLAN:
			return Object.assign({}, state, {
				selectedPlan: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_FIRST_NAME:
			return Object.assign({}, state, {
				recipientFirstName: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_LAST_NAME:
			return Object.assign({}, state, {
				recipientLastName: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_MIDDLE_INITIAL:
			return Object.assign({}, state, {
				recipientMiddleInitial: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_EMAIL:
			return Object.assign({}, state, {
				recipientEmail: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_EMAIL_CONFIRM:
			return Object.assign({}, state, {
				recipientEmailConfirm: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_TELEPHONE:
			return Object.assign({}, state, {
				recipientTelephone: action.value,
				lastUpdated: new Date().getTime()
			});
		case GIVER_NAME:
			return Object.assign({}, state, {
				giverName: action.value,
				lastUpdated: new Date().getTime()
			});
		case GIFT_MESSAGE:
			return Object.assign({}, state, {
				giftMessage: action.value,
				lastUpdated: new Date().getTime()
			});
		case DELIVERY_DATE:
			return Object.assign({}, state, {
				deliveryDate: action.value,
				lastUpdated: new Date().getTime()
			});
		case STATE_NAME:
			return Object.assign({}, state, {
				stateName: action.value,
				lastUpdated: new Date().getTime()
			});
		case CITY_NAME:
			return Object.assign({}, state, {
				cityName: action.value,
				lastUpdated: new Date().getTime()
			});
		case LOCATION_KEY:
			return Object.assign({}, state, {
				key: action.value,
				lastUpdated: new Date().getTime()
			});
		case SET_CLUB:
			const club = action.value;
			const { sortPlans, plansSortMethod } = config;
			if (sortPlans && club && Array.isArray(club.products)) {
				switch (plansSortMethod) {
					case 'RV_LAST':
						club.plans = utils.sortPlansRVsLast(club.products);
						break;
					default:
					case 'COST_ASC':
						club.plans = utils.sortPlansCostAsc(club.products);
						break;
				}
				console.log('plans', club.products);
			}
			return Object.assign({}, state, {
				selectedClub: club,
				lastUpdated: new Date().getTime()
			});
		case RESET_MEMBERSHIP:
			return Object.assign({}, state, initialState, {
				lastUpdated: -1
			});
		case RECIPIENT_SET_ASSOCIATES:
			return Object.assign({}, state, {
				associates: action.value,
				lastUpdated: new Date().getTime()
			});
		case RECIPIENT_ADD_ASSOCIATES:
			let arr = [...state.associates]
			arr = arr.map((d) => {
				return d.id
			});
			const lastId = arr.length > 0 ? Math.min(...arr) - 1 : -1;
			return {
				...state,
				associates: [...state.associates, { id: lastId, firstName: '', middleName: '', lastName: '' }]
				//associates: state.associates.concat({ id: lastId - 1, firstName: '', middleName: '', lastName: '' })
				//associates: [
				//	...state.associates,
				//	{ id: lastId - 1, firstName: '', middleName: '', lastName: '' }
				//]
			}
		case RECIPIENT_DELETE_ASSOCIATES:
			return {
				...state,
				associates: state.associates.filter((x) => {
					x.id !== action.value.id
				})
			}
		case RECIPIENT_SET_ASSOCIATE_PROP:
			return {
				...state,
				associates: state.associates.map((x) => {
					if (x.id === action.value.id) {
						return { ...x, ...action.value.field }
					} else {
						return { ...x }
					}
				})
			}
		case RECIPIENT_RESET_ASSOCIATE:
			return {
				...state,
				associates: []
			}
		default:
			return state;
	}
}