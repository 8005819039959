import { SET_REDEMPTION_CODE, SET_ENROLLMENT_REDEMPTION_CODE, CLEAN_REDEMPTION_CODE } from './actions'

const initialRedemptionCodeState = {
  redemptionCode: '',
  firstName: '',
  middleInitial: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  birthYear: '',
  phoneNumber: '',
  address: '',
  membershipNumber: '',
  lastUpdated: new Date().getTime(),
  product: {
    productHistoryId: '',
    productId: '',
    name: '',
    shortDescription: '',
    longDescription: '<p></p>',
    mileageException: '',
    entitlements: '',
    battery: '',
    flat: '',
    fuel: '',
    lockout: '',
    towing: '',
    clubName: '',
  },
}

export default function reduce(state = initialRedemptionCodeState, action = {}) {
  switch (action.type) {
    case SET_REDEMPTION_CODE:
      return Object.assign({}, state, {
        redemptionCode: action.value,
        lastUpdated: new Date().getTime(),
      })

    case SET_ENROLLMENT_REDEMPTION_CODE:
      return Object.assign({}, state, {
        [action.value.key]: action.value.value,
        lastUpdated: new Date().getTime(),
      })

    case CLEAN_REDEMPTION_CODE:
      return Object.assign({}, initialRedemptionCodeState)

    default:
      return state
  }
}
