import React from 'react';
import './paymentDetails.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Card from '../../components/card/card';
import { PADDING_ALL } from '../../utils/styleValues';
import utils from '../../utils/utils';

class PaymentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            last4: ''
        };
    }

    componentDidMount() {
        const { cart } = this.props;
        this.setState({
            last4: utils.anonymizeCardNumber(cart.stripeCreateSourceResponse[0].token.card.last4),
        });
    }

    render() {
        const { payment, hideButtons, disabled, onEdit } = this.props;
        const {
            customerEmail,
            cardName,
            billingZipCode
        } = payment;

        const { last4 } = this.state;

        return (
            <Card padding={PADDING_ALL} className="payment-details">
                <div className="payment-details--full">
                    <p className="summary-value fs-18 text-uppercase"><strong>{cardName}</strong></p>
                    <p className="summary-value fs-14">{customerEmail}</p>
                </div>
                <div className="payment-details--full">
                    <p className="summary-value fs-18">{last4.toLowerCase()}</p>
                    <p className="summary-value fs-14"><strong>Billing ZIP Code: </strong>{billingZipCode}</p>
                </div>
                {hideButtons !== true ? (
                    <div className="payment-details--btn text-align-right">
                        <button
                            onClick={onEdit}
                            disabled={disabled}
                            className="btn-primary-sm">Edit</button>
                    </div>
                ) : null}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(PaymentDetails)));

