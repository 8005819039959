import React from 'react'
import './recipientThankYouPage.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Hero from '../../components/hero/hero'
import Section from '../../components/section/section'
import { PADDING_ALL } from '../../utils/styleValues'
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ACTION
} from '../../components/btn/btn'
import utils from '../../utils/utils'
import config from '../../config'
import { get } from 'lodash'
// import { resetClaim } from '../../redux/claim/actions';

const { routes } = config

class RecipientThankYouPage extends React.Component {
    constructor(props) {
        super(props)
        const { claim, redemptionCode } = props
        this.state = {
            firstName: get(redemptionCode, 'firstName')
                ? get(redemptionCode, 'firstName')
                : get(claim, 'firstName') || '',
        }
    }

    componentDidMount() {
        // const { dispatch } = this.props;
        // setTimeout(() => {
        // 	dispatch(resetClaim());
        // }, 10);
    }

    _onVisitAAAClick(e) {
        const win = window.open(config.AAAURL, '_blank')
        win.focus()
    }

    _onClickGoToFaq(e) {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.faqPage)
    }

    _onClickViewMembershipBenefits(e) {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.membershipInformationPage)
    }

    render() {
        const { t, claim, redemptionCode } = this.props
        const { firstName } = this.state
        return (
            <section className="page recipient-thank-you-page">
                <Hero
                    image="/images/heros/claim-hero.jpg"
                    title={`${t('recipientThankYouTitle')} ${firstName}!`}
                />

                <Section
                    padding={PADDING_ALL}
                    className="recipient-thank-you-page--blurb"
                >
                    <p>
                        {t('recipientThankYouSubtitle')}{' '}
                        <strong>
                            {get(redemptionCode, 'product.clubName')
                                ? get(redemptionCode, 'product.clubName')
                                : get(claim, 'planResponse.clubName') || ''}
                        </strong>
                        !
                    </p>
                </Section>

                <Section
                    padding={PADDING_ALL}
                    className="recipient-thank-you-page--next"
                >
                    <h2 className="section-heading text-center">{t('nowWhat')}</h2>
                    <p>{t('nextSteps')}</p>
                    <Btn
                        label={t('visitAAABenefits')}
                        type="a"
                        onClick={this._onClickViewMembershipBenefits.bind(this)}
                        className={[
                            'recipient-thank-you-page--submit',
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK,
                            COLOR_ACTION
                        ]}
                    />
                </Section>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        claim: state.claim,
        redemptionCode: state.redemptionCode,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(RecipientThankYouPage)))
