import React from 'react';
import './reviewRedemptionCodeEnrollment.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import EnrollmentSummary from '../../components/enrollmentSummary/enrollmentSummary';
import Ak from '../../components/ak/ak';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION } from '../../components/btn/btn';
import utils from '../../utils/utils';
import config from '../../config';
import API from '../../utils/api';
import { addError } from '../../redux/errors/actions';
import { customLoadingOverlay } from '../../styles/customLoadingOverlay';
import LoadingOverlay from 'react-loading-overlay-ts'

const { routes } = config;

class ReviewRedemptionCodeEnrollment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isRequesting: false,
            errors: null,
            loading: false
        };
        this.api = null;
    }

    componentDidMount() {
        const { redemptionCode, history } = this.props;

        if (!redemptionCode.redemptionCode) {
            history.replace(routes.redemptionCode);
        }

        const { conf } = this.props;
        this.api = new API(conf);
        this.updateScroll();
    }

    _onEnrollmentClick() {

        const { t, dispatch, redemptionCode, history } = this.props;
        this.setState({
            errors: null,
            loading: true
        });
        this.api.enrollRedemptionCode({
            Code: redemptionCode.redemptionCode.redemptionCode,
            StreetAddress: redemptionCode.address,
            FirstName: redemptionCode.firstName,
            LastName: redemptionCode.lastName,
            MiddleInitial: redemptionCode.middleInitial,
            Email: redemptionCode.address1,
            City: redemptionCode.city,
            State: redemptionCode.state.state,
            Zip: redemptionCode.zipCode,
            Phone: redemptionCode.phoneNumber,
            BirthYear: redemptionCode.birthYear,
            MembershipNumber: redemptionCode.membershipNumber,
            Associates: redemptionCode.associates
        }).then((result) => {
            if (result.status === 400) {
                console.log('enrollment api validation failure');
                throw Error(t('genericError'));
            }
            this.setState({
                loading: false
            });
            utils.scrollTop();
            history.push(routes.recipientThankyouPage);
        }).catch((err) => {
            this.setState({
                loading: false,
                errors: err ? err.message : t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    updateScroll() {
        document.getElementById('logoAAA').scrollIntoView();
        utils.scrollTop();
    }

    _onTermsClick(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.termsPage);
    }

    _onEditClick() {
        const { history } = this.props;
        history.push(routes.enrollmentRedemptionCodePage);
    }

    _renderEnrollmentSummary() {
        const { t, redemptionCode } = this.props;
        const { isRequesting, errors, loading } = this.state;

        return (
            <section className="page review-enrollment-page">
                <LoadingOverlay active={loading} spinner styles={customLoadingOverlay} >
                    <Hero title={t('reviewEnrollmentTitle')} />

                    <Section padding={PADDING_ALL}>

                        {errors ? (
                            <p className="warning">{errors}</p>
                        ) : null}

                        <EnrollmentSummary enrollment={redemptionCode}
                            onEdit={this._onEditClick.bind(this)} />

                        <p className="review-enrollment-page--terms">{t('enrollmentTerms')}
                            <Ak className="review-enrollment-page--terms-link"
                                onClick={this._onTermsClick.bind(this)}
                                text={t('termsAndConditions')} />.</p>

                        <Btn label={t('enrollmentBtn')}
                            disabled={isRequesting}
                            onClick={this._onEnrollmentClick.bind(this)}
                            className={['review-enrollment-page--submit', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION]} />
                    </Section>
                </LoadingOverlay>
            </section>
        );
    }

    render() {
        return this._renderEnrollmentSummary();
    }
}

function mapStateToProps(state) {
    return {
        redemptionCode: state.redemptionCode,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ReviewRedemptionCodeEnrollment)));

