import React from 'react';
import './contactInfoPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import ContactInfoForm from '../../components/contactInfoForm/contactInfoForm';
import {
    setRecipientFirstName,
    setRecipientLastName,
    setRecipientMiddleInitial,
    setRecipientEmail,
    setRecipientEmailConfirm,
    setGiverName,
    setGiftMessage,
    setDeliveryDate,
    setRecipientTelephone,
    setSelectedPlan,
    setClub,
    setRecipientLocation,
    setStateName,
    setCityName,
    setLocationSuggestions,
    setPendingRecipientLocation,
    setLocationKey,
    setAssociates,
    addAssociate,
    deleteAssociate,
    setAssociateProp,
    resetAssociates
} from '../../redux/membership/actions';
import { addToCart, replaceCartItem } from '../../redux/cart/actions';
import utils from '../../utils/utils';
import config from '../../config';

const { routes } = config;

class ContactInfoPage extends React.Component {

    componentDidMount() {
        const { t, dispatch, membership } = this.props;
        if (!membership.giftMessage) {
            dispatch(setGiftMessage(t('giftMessageDefault')));
        }

        if (this.props.location.state && this.props.location.state === 'lastData') {
            this.setData();
        }
        document.getElementById('cartAAA').focus();
        utils.scrollTop();

    }

    setData() {
        const { dispatch, cart } = this.props;
        if (cart.items.length > 0) {
            const item = cart.items[cart.items.length - 1];
            dispatch(setCityName(item.cityName));
            dispatch(setDeliveryDate(item.deliveryDate));
            dispatch(setGiftMessage(item.giftMessage));
            dispatch(setGiverName(item.giverName));
            dispatch(setLocationSuggestions(item.locationSuggestions));
            dispatch(setPendingRecipientLocation(item.pendingRecipientLocation));
            dispatch(setRecipientEmail(item.recipientEmail));
            dispatch(setRecipientEmailConfirm(item.recipientEmailConfirm));
            dispatch(setRecipientFirstName(item.recipientFirstName));
            dispatch(setRecipientLastName(item.recipientLastName));
            dispatch(setRecipientLocation(item.recipientLocation));
            dispatch(setRecipientMiddleInitial(item.recipientMiddleInitial));
            dispatch(setRecipientTelephone(item.recipientTelephone));
            dispatch(setClub(item.selectedClub));
            dispatch(setSelectedPlan(item.selectedPlan));
            dispatch(setStateName(item.stateName));
            dispatch(setAssociates(item.associates));
        }
    }

    _onSubmit() {
        const { dispatch, history, membership, cart } = this.props;
        if (cart.items.length !== membership.key) {
            dispatch(addToCart(membership));
            dispatch(setLocationKey(cart.items.length + 1))
        } else {
            dispatch(replaceCartItem(cart.items.length - 1, membership))
        }
        utils.scrollTop();
        history.push(routes.cartPage);
    }

    _onValueChange(prop, value) {
        const { dispatch } = this.props;
        switch (prop) {
            case 'recipientFirstName':
                dispatch(setRecipientFirstName(value));
                break;
            case 'recipientLastName':
                dispatch(setRecipientLastName(value));
                break;
            case 'recipientMiddleInitial':
                dispatch(setRecipientMiddleInitial(value));
                break;
            case 'recipientEmail':
                dispatch(setRecipientEmail(value));
                break;
            case 'recipientEmailConfirm':
                dispatch(setRecipientEmailConfirm(value));
                break;
            case 'recipientTelephone':
                dispatch(setRecipientTelephone(value));
                break;
            case 'giverName':
                dispatch(setGiverName(value));
                break;
            case 'giftMessage':
                dispatch(setGiftMessage(value));
                break;
            case 'deliveryDate':
                dispatch(setDeliveryDate(value));
                break;
            default:
                break;
        }
    }
    _onAssociateAdd() {
        const { dispatch } = this.props;
        dispatch(addAssociate());
    }
    _onAssociateDelete(value) {
        const { dispatch } = this.props;
        dispatch(deleteAssociate(value));
    }
    _onChangeAssociate(value) {
        const { dispatch } = this.props;
        dispatch(setAssociateProp(value));
    }
    _onAssociateReset(value) {
        const { dispatch } = this.props;
        dispatch(resetAssociates(value));
    }
    _onSetAssociate(value) {
        const { dispatch } = this.props;
        dispatch(setAssociates(value));
    }

    render() {
        const { t, membership } = this.props;
        return (
            <section className="page contact-info-page">
                <Hero title={t('contactInfoPageTitle')} />

                <Section padding={PADDING_ALL} className="contact-info-page--blurb">
                    <p>{t('contactInfoPageSubtitle')}</p>
                </Section>

                <Section padding={PADDING_ALL} className="contact-info-page--form">
                    <ContactInfoForm data={membership}
                        onValueChange={this._onValueChange.bind(this)}
                        onAssociateAdd={this._onAssociateAdd.bind(this)}
                        onAssociateDelete={this._onAssociateDelete.bind(this)}
                        onChangeAssociate={this._onChangeAssociate.bind(this)}
                        onAssociateReset={this._onAssociateReset.bind(this)}
                        onSetAssociate={this._onSetAssociate.bind(this)}
                        onSubmit={this._onSubmit.bind(this)} />
                </Section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ContactInfoPage)));
