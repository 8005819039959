import React from 'react';
import './questionBox.css';
import Section from "../../components/section/section";
import Ak from "../../components/ak/ak";
import { PADDING_ALL } from '../../utils/styleValues';
import Utils from '../../utils/utils';

class QuestionBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			windowWidth: window.innerWidth
		};
		this.innerRef = null;
		this._setRefBound = this._setRef.bind(this);
		this._changeDimBound = this._changeDim.bind(this);
	}

	componentDidMount() {
		this.name = this._getName();
		window.addEventListener('resize', this._changeDimBound);
		if (this.name === window.location.hash.replace('#', '')) {
			this.setState({
				isOpen: true
			}, () => {
				setTimeout(() => {
					if (this.innerRef) {
						this.innerRef.scrollIntoView();
					}
				}, 100)
			});

		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._changeDimBound);
	}

	_changeDim() {
		this.setState({
			windowWidth: window.innerWidth
		});
	}

	_setRef(ref) {
		this.innerRef = ref;
	}

	_toggleOpen(e) {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen
		});
	}

	_getInnerDetailsHeight() {
		if (this.innerRef) {
			return this.innerRef.offsetHeight;
		}
		return 0;
	}

	_getName() {
		const { question } = this.props;
		return Utils.slugify(question.question);
	}

	render() {
		const { question } = this.props;
		const { isOpen, windowWidth } = this.state;
		const openClass = isOpen ? 'active' : '';
		const name = this._getName();
		const heightStyle = {};
		heightStyle.height = isOpen ? this._getInnerDetailsHeight() : 0; // stylelint bug...
		console.log('windowWidth', windowWidth);
		return (
			<Section padding={PADDING_ALL} className={`question-box ${openClass}`} name={name}>
				<div className="question-box--question"
					onClick={this._toggleOpen.bind(this)}>
					<Ak className="question-box--indicator"
						onClick={this._toggleOpen.bind(this)}>

						<img className="question-box--indicator-image"
							src="/images/icons/accordion-indicator.svg" alt="" />
					</Ak>
					<h2 className="question-box--question-text">{question.question}</h2>
				</div>
				<div className="question-box--answer" style={heightStyle}>
					<div className="question-box--answer-inner" ref={this._setRefBound}>
						<p className="question-box--answer-text" dangerouslySetInnerHTML={{ __html: question.answer }}></p>
					</div>
				</div>
			</Section >
		);
	}
};

export default QuestionBox;
