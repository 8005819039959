export const ADD_ORDER = 'orders.ADD_ORDER';
export const RESET_ORDERS = 'orders.RESET_ORDERS';
export const SET_CONFIRMATION = 'orders.SET_CONFIRMATION';

export function addOrder(order) {
	return {
		type: ADD_ORDER,
		value: order
	};
}

export function setConfirmationCode(code) {
	return {
		type: SET_CONFIRMATION,
		value: code
	};
}

export function resetOrders() {
	return {
		type: RESET_ORDERS
	};
}
