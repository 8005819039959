import React from 'react';
import './btn.css';

import AK from '../../components/ak/ak';
import utils from '../../utils/utils';

export const SIZE_LARGE = 'btn-large';
export const SIZE_MEDIUM = 'btn-med'; // default
export const SIZE_SMALL = 'btn-small';
export const DISPLAY_BLOCK = 'btn-block';
export const DISPLAY_INLINE = 'btn-inline'; // default
export const COLOR_ALT = 'btn-alt';
export const COLOR_ACTION = 'btn-action';
export const COLOR_BLANK = 'btn-blank';
export const COLOR_ALT_SIMPLE = 'btn-alt-simple';
export const COLOR_ALT_APPLY = 'btn-alt-apply';
export const COLOR_ALT_FULL = 'btn-alt-full';
export const COLOR_ACTION_SIMPLE = 'btn-action-simple';
export const GIFT_ANOTHER = 'btn-gift-another';
export const DISPLAY_BLOCK_LEFT = 'btn-block-left';
export const BUTTON_PAD = 'btn-pad';

class Btn extends React.Component {

	constructor(props) {
		super(props);
		this._elRef = null;
		this._onRevBound = this._onRef.bind(this);
	}

	_onRef(elRef) {
		const { onRef } = this.props;
		this._elRef = elRef;
		if (utils.isFunction(onRef)) {
			onRef(elRef);
		}
	}

	render() {
		const { label, onClick, className, disabled, type, value } = this.props;
		const cssClass = utils.extractClassName(className);
		if (type === 'a') {
			return (
				<AK disabled={disabled === true}
					onClick={onClick}
					ref={this._onRevBound}
					value={value}
					type={(type || 'button')}
					className={`btn ${cssClass}`}>{label}</AK>
			);
		} else {
			return (
				<button disabled={disabled === true}
					onClick={onClick}
					ref={this._onRevBound}
					value={value}
					type={(type || 'button')}
					className={`btn ${cssClass}`}>{label}</button>
			);
		}
	}
}

export default Btn;

