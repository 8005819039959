import React from 'react';
import './cartPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import Total from '../../components/total/total';
import Promo from '../../components/promo/promo';
import { PADDING_ALL, PADDING_NO_BOTTOM } from '../../utils/styleValues';
import ContactInfoForm from '../../components/contactInfoForm/contactInfoForm';
import MembershipSummary from '../../components/membershipSummary/membershipSummary';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION, GIFT_ANOTHER } from '../../components/btn/btn';
import {
    resetMembership,
    addAssociate,
    deleteAssociate,
    setAssociateProp,
    resetAssociates
} from '../../redux/membership/actions';
import utils from '../../utils/utils';
import config from '../../config';
import { replaceCartItem } from '../../redux/cart/actions';

const { routes } = config;

class CartPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editIndex: -1
        };
        this.skipScroll = false
    }

    componentDidMount() {
        if (window.location.hash === '#edit') {
            this._setEditMode();
        }
        setTimeout(() => {
            window.addEventListener("scrollUp", this.updateScroll.bind(this));
            this.updateScroll();
        }, 0);
    }

    componentDidUpdate() {
        if (this.skipScroll) {
            this.skipScroll = false
        } else {
            this.updateScroll();
        }
    }

    doSkipScroll = () => {
        this.skipScroll = true
    }

    updateScroll() {
        document.getElementById('cartAAA').scrollIntoView();
        utils.scrollTop();
    }

    componentWillUnmount() {
        window.addEventListener("scrollUp", this.updateScroll.bind(this));
    }

    _renderEmptyCart() {
        const { t } = this.props;
        return (
            <div>
                <h3 className="cart-page--empty-title">{t('emptyCartTitle')}</h3>
                <p className="cart-page--empty-text">{t('emptyCartText')}</p>
            </div>
        );
    }

    _renderButtons() {
        const { t, cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        if (cartCount > 0) {
            return (
                <div>
                    <Btn label={t('addAnotherMembershipBtn')}
                        onClick={this._onAddAnotherClick.bind(this)}
                        className={[
                            GIFT_ANOTHER,
                            'cart-page--add-another']} />
                    <Btn label={t('checkoutBtn')}
                        type="submit"
                        onClick={this._onCheckoutClick.bind(this)}
                        className={[
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK,
                            COLOR_ACTION,
                            'cart-page--submit']} />
                </div>
            );
        } else {
            return (
                <Btn label={t('emptyCartButtonText')}
                    onClick={this._onAddAnotherClick.bind(this)}
                    className={[
                        SIZE_MEDIUM,
                        DISPLAY_BLOCK,
                        COLOR_ACTION,
                        'cart-page--empty-button'
                    ]} />
            );
        }
    }

    _renderSummary() {
        const { cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        if (cartCount > 0) {
            return (<MembershipSummary items={cart.items} onEdit={this._onEditMembership.bind(this)} />);
        } else {
            return this._renderEmptyCart();
        }
    }

    _renderTotal() {
        const { cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        if (cartCount > 0) {
            return (<Total items={cart} hideFees={true} />);
        } else {
            return null;
        }
    }

    _renderPromo() {
        const { cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        if (cartCount > 0) {
            return (<Promo items={cart} doSkipScroll={this.doSkipScroll} />);
        } else {
            return null;
        }
    }

    _onEditMembership(index) {
        const { cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        if (index < cartCount && index > -1) {
            this._setEditMode(index);
        } else {
            this._clearEditMode();
        }
    }

    _onCheckoutClick(e) {
        const { history } = this.props;
        e.preventDefault();
        utils.scrollTop();
        history.push(routes.checkoutPage);
    }

    _onAddAnotherClick(e) {
        const { dispatch, history } = this.props;
        e.preventDefault();
        dispatch(resetMembership());
        utils.scrollTop();
        history.push(routes.locationSelectPage);
    }

    _renderListing() {
        return (
            <form action=""
                className="cart-page--form"
                name="cart_form"
                encType="multipart/form-data"
                method="post"
                autoComplete="off">
                {this._renderTotal()}
                {this._renderPromo()}
                {this._renderSummary()}
                {this._renderButtons()}
            </form>
        );
    }

    _onAssociateAdd() {
        const { dispatch } = this.props;
        dispatch(addAssociate());
    }
    _onAssociateDelete(value) {
        const { dispatch } = this.props;
        dispatch(deleteAssociate(value));
    }
    _onChangeAssociate(value) {
        const { dispatch } = this.props;
        dispatch(setAssociateProp(value));
    }
    _onAssociateReset(value) {
        const { dispatch } = this.props;
        dispatch(resetAssociates(value));
    }

    _renderEditForm() {
        const { t, cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        const { editIndex } = this.state;
        if (editIndex < cartCount && editIndex > -1) {
            return (
                <ContactInfoForm data={cart.items[editIndex]}
                    hideConfirmationLabel={true}
                    saveButtonLabel={t('save')}
                    onAssociateAdd={this._onAssociateAdd.bind(this)}
                    onAssociateDelete={this._onAssociateDelete.bind(this)}
                    onChangeAssociate={this._onChangeAssociate.bind(this)}
                    onAssociateReset={this._onAssociateReset.bind(this)}
                    onCancel={this._onEditCancel.bind(this)}
                    onSubmit={this._onEditSubmit.bind(this)} />
            );
        }
        return null;
    }

    _renderCart() {
        const { cart } = this.props;
        const cartCount = utils.countCartItems(cart);
        const { editIndex } = this.state;
        if (editIndex < cartCount && editIndex > -1) {
            return this._renderEditForm();
        } else {
            return this._renderListing();
        }
    }

    _onEditSubmit(data) {
        const { dispatch } = this.props;
        const { editIndex } = this.state;
        if (data) {
            dispatch(replaceCartItem(editIndex, data));
        }
        setTimeout(() => {
            this._clearEditMode();
        }, 100)
    }

    _onEditCancel() {
        this._clearEditMode();
    }

    _setEditMode(index) {
        this.setState({
            editIndex: index
        });
        document.body.classList.add('edit-mode');
        window.location.hash = 'edit';
    }

    _clearEditMode() {
        this.setState({
            editIndex: -1
        });
        document.body.classList.remove('edit-mode');
        window.location.hash = '';
    }

    _isEditMode() {
        const { editIndex } = this.state;
        return editIndex !== -1;
    }

    render() {
        const { t, cart } = this.props;
        const isEditMode = this._isEditMode();
        const cartCount = utils.countCartItems(cart);
        return (
            <section className="page cart-page">
                <Hero title={t('cartPageTitle')} />

                {cartCount > 0 && !isEditMode ? (<Section padding={PADDING_NO_BOTTOM}>
                    <h3 className={'section--title'}>{t('giftMemberships')}</h3>
                </Section>) : null}
                <Section padding={PADDING_ALL} className="cart-page--memberships">
                    {this._renderCart()}
                </Section>
            </section >
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart,
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(CartPage)));

