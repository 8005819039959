import React from 'react'
import './membershipAdditionalBenefits.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
// import utils from '../../utils/utils';
import IconTextBox from '../../components/iconTextBox/iconTextBox'
import Card from '../../components/card/card'
import { PADDING_ALL } from '../../utils/styleValues'
import { get } from 'lodash'

class MembershipAdditionalBenefits extends React.Component {
    render() {
        const { plan, t, product } = this.props

        return (
            <Card padding={PADDING_ALL} className="membership-additional-benefits">
                <div className="membership-benefits--details">
                    <div className="membership-benefits--row-info">
                        <IconTextBox
                            icon="/images/icons/battery-icon.svg"
                            title={t('membershipIconBox1Title')}
                            text={
                                get(product, 'battery') ? get(product, 'battery') : get(plan, 'battery') || ''
                            }
                        />
                    </div>
                    <div className="membership-benefits--row-info">
                        <IconTextBox
                            icon="/images/icons/flat-tire-icon.svg"
                            title={t('membershipIconBox2Title')}
                            text={get(product, 'flat') ? get(product, 'flat') : get(plan, 'flat') || ''}
                        />
                    </div>
                    <div className="membership-benefits--row-info">
                        <IconTextBox
                            icon="/images/icons/keys-icon.svg"
                            title={t('membershipIconBox3Title')}
                            text={
                                get(product, 'lockout') ? get(product, 'lockout') : get(plan, 'lockout')
                            }
                        />
                    </div>
                    <div className="membership-benefits--row-info">
                        <IconTextBox
                            icon="/images/icons/gas-icon.svg"
                            title={t('membershipIconBox4Title')}
                            text={get(product, 'fuel') ? get(product, 'fuel') : get(plan, 'fuel') || ''}
                        />
                    </div>
                </div>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        product: state.redemptionCode.product,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MembershipAdditionalBenefits)))
