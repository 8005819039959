import React from 'react';
import './enrollmentPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Section from '../../components/section/section';
import Hero from '../../components/hero/hero';
import { PADDING_ALL } from '../../utils/styleValues';
import EnrollmentForm from '../../components/enrollmentForm/enrollmentForm';
import utils from '../../utils/utils';
import config from '../../config';
import {
    setFirstName,
    setMiddleInitial,
    setLastName,
    setAddress1,
    setAddress2,
    setState,
    setStateName,
    setZipCode,
    setBirthYear,
    setPhoneNumber,
    setEmail,
    setMembershipNumber,
    setAddress,
    setCity
} from '../../redux/claim/actions';

const { routes } = config;

class EnrollmentPage extends React.Component {

    componentDidMount() {
        const { claim, history } = this.props;
        if (!claim.claimToken) {
            history.replace(routes.claimPage);
        }

        this.updateScroll();
    }

    _onValueChange(prop, value) {
        const { dispatch } = this.props;
        switch (prop) {
            case 'firstName':
                dispatch(setFirstName(value));
                break;
            case 'middleInitial':
                dispatch(setMiddleInitial(value));
                break;
            case 'lastName':
                dispatch(setLastName(value));
                break;
            case 'address1':
                dispatch(setAddress1(value));
                break;
            case 'address2':
                dispatch(setAddress2(value));
                break;
            case 'address':
                dispatch(setAddress(value));
                break;
            case 'state':
                dispatch(setState(value));
                break;
            case 'stateName':
                dispatch(setStateName(value));
                break;
            case 'city':
                dispatch(setCity(value));
                break;
            case 'zipCode':
                dispatch(setZipCode(value));
                break;
            case 'birthYear':
                dispatch(setBirthYear(value));
                break;
            case 'phoneNumber':
                dispatch(setPhoneNumber(value));
                break;
            case 'email':
                dispatch(setEmail(value));
                break;
            case 'membershipNumber':
                dispatch(setMembershipNumber(value));
                break;
            default:
                break;
        }
    }

    updateScroll() {
        document.getElementById('logoAAA').scrollIntoView();
        utils.scrollTop();
    }

    _onSubmit(data) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.reviewEnrollmentPage);
    }

    render() {
        const { t, claim } = this.props;
        const { token } = claim;
        return (
            <section className="page enrollment-page">
                <Hero title={t('enrollmentPageTitle')} />

                <Section padding={PADDING_ALL}>
                    {token ? (
                        <p className="enrollment-page--gift-id-label">{t('giftReferenceNumber')}
                            <span className="enrollment-page--gift-id-value">{token}</span>
                        </p>
                    ) : null}
                    <EnrollmentForm data={claim}
                        onValueChange={this._onValueChange.bind(this)}
                        onSubmit={this._onSubmit.bind(this)} />
                </Section>

            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        claim: state.claim
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(EnrollmentPage)));

