const en = {
    global: {
        // dates
        monthJanuary: 'January',
        monthFebruary: 'February',
        monthMarch: 'March',
        monthApril: 'April',
        monthMay: 'May',
        monthJune: 'June',
        monthJuly: 'July',
        monthAugust: 'August',
        monthSeptember: 'September',
        monthOctober: 'October',
        monthNovember: 'November',
        monthDecember: 'December',
        // ui
        and: 'and',
        select: 'Select',
        learnMore: 'Learn More',
        continue: 'Continue',
        viewDetails: 'View details',
        hideDetails: 'Hide details',
        termsAndConditions: 'Terms of Use and Privacy',
        privacyLinkText: ' Privacy Notice',
        helpLinkText: ' Help',
        termsLinkText: ' Terms of Use and Privacy',
        contactUSText: 'Contact',
        questionAndComments: 'QUESTIONS AND COMMENTS',
        faqLinkText: ' FAQ',
        emptyCartTitle: 'Your cart is empty.',
        emptyCartText: '',
        emptyClubPlans:
            '<p>Gift memberships for this city cannot be purchased directly from this site today. They may be offered here in the future, however.</p><p>Please visit the AAA club that serves this city.</p>',
        emptyClubAfter:
            'please go to <a href="https://www.aaa.com/stop/">https://www.aaa.com/stop</a> then enter the ZIP code of the gift recipient’s residence. This will take you to the website of the AAA club that serves their area.',
        emptyClubPreface: 'To purchase a gift for a recipient residing in',
        year: 'year',
        memberships: 'Memberships',
        subtotal: 'Subtotal',
        fees: 'Fees',
        enrollmentFees: 'Enrollment Fees',
        total: 'Total',
        cancel: 'Cancel',
        edit: 'Edit',
        save: 'Save',
        remove: 'Remove',
        empty: 'Empty',
        faqSectionTitle: 'Questions?',
        faqSectionText:
            'Visit our Help page for more information about gift memberships.',
        faqSectionBtnText: 'HELP',
        mastercard: 'Mastercard',
        visa: 'Visa',
        americanExpress: 'American Express',
        discover: 'Discover',
        visitAAA: 'Visit AAA.com',
        requiredField: 'Required',
        call: 'Call',
        email: 'Email',
        emailAddress: 'Email Address',
        somethingWentWrong: 'Something went wrong',
        genericError: 'There was an error, please try again',
        payeezyError:
            'There was a problem processing your payment. Check your credit card information and try again.',
        paymentError: 'There was an error',
        claimError: 'Gift doesn’t exist or has already been claimed',
        tryAgain: 'Try again',
        mostPopular: 'Most Popular',
        annualPrice: 'Annual Price',
        unknownError: 'Unknown Error',
        // homePage
        homePageTitle: 'Give the Gift of AAA Membership',
        homePageBlurb:
            'Gifting AAA is fast, easy and valuable!\nYou’ll enjoy peace of mind knowing they’re covered; they’ll enjoy a year of trusted emergency road service, awesome discounts, travel perks and more.',
        homeMeta:
            'Give the gift of a AAA Membership and enjoy peace of mind knowing your love ones will be covered with a year of trusted emergency road service, awesome discounts, travel perks and more!',
        homeCononicalUrl: 'https://gift.aaa.com/aaa-gift-membership',
        homeTitle: 'AAA Gift Membership | Buy AAA Gift Membership',
        homePreviewTitle: 'Product Preview',
        howItWorks: 'How it works',
        homeIconBox1Text:
            'Enter your recipient’s city to view local pricing and benefits.',
        homeIconBox2Text: 'Select the best membership option for their needs.',
        homeIconBox3Text:
            'Write a personalized message and choose when the gift will be digitally delivered.',
        getStarted: 'Get Started',
        // maintenancePage
        maintenancePageTitle: 'Give the Gift of AAA Membership',
        maintenancePageBlurb:
            'Thank you for your interest in purchasing the gift of AAA. The site is currently being enhanced and will be available shortly. We apologize for any inconvenience and appreciate your patience.',
        maintenancePageBlurb2:
            'Until then you can purchase a gift membership by clicking ',
        maintenancePageBlurb3:
            ' then entering a ZIP code of your recipient; you will then be taken to the local AAA club’s website to make your purchase.',
        maintenancePageAAALinkText: 'here',
        maintenancePageAAALink: 'https://www.aaa.com/stop/',
        // locationSelectPage
        locationSelectPageTitle: 'Where Do \nThey Live?',
        locationBlurb:
            'We’ll look up pricing and benefits in your recipient’s area.',
        locationInputLabel: 'City',
        contactLocationInputLabel: "Gift Recipient's City",
        locationInputPlaceholder: 'Search by city name',
        fullNameInputLabel: 'Your Name',
        emailInputLabel: 'Your Email',
        commentInputLabel: 'Comment/Question',
        stateInputLabel: 'State',
        contactStateInputLabel: "Gift Recipient's State",
        stateInputPlaceholder: 'Search by state name',
        goToMemberships: 'View Membership Options',
        noResultsMessage:
            "Though we are working on offering nationwide gift-purchasing capabilities, certain regions are not yet part of the AAA Gift Center program. We are sorry for the inconvenience. \r\nIf you'd like to purchase a gift membership for a recipient in one of these regions, please contact their local AAA club directly. Follow the link below and enter a ZIP code where the recipient lives.",
        noResultsLink: 'See our FAQ',
        // chooseMembershipPage
        chooseMembershipPageTitle: 'Choose Their\n Membership',
        availableOptions: 'Available options in ',
        pricesFeeWarning:
            'Click Learn More to find out which fees may apply to the memberships listed below.',
        inactiveRegionTitle: 'Sorry, this location is unavailable',
        inactiveRegionText: 'Please try another location',
        tryAnotherLocation: 'Try another location',
        // membershipDetailPage
        genericMembershipDetailTitle: 'Choose a membership',
        membershipStartDate:
            'The gift recipient is responsible for enrolling with AAA. A Gift Membership is not active until the recipient enrolls and receives a membership confirmation from their local AAA club.',
        additionalBenefits: 'Additional Benefits',
        selectThisMembership: 'Select',
        backToMembershipOptions: 'Back to membership options',
        membershipIconBox1Title: 'Battery Service',
        membershipIconBox1Text:
            'A trained technician will test their battery, perfom a jump, or even replace it on the spot if needed.',
        membershipIconBox2Title: 'Flat Tire Change',
        membershipIconBox2Text:
            'Come rain, sleet, or snow, we’ll change their flat and have them back on the road in no time.',
        membershipIconBox3Title: 'Lockout Service',
        membershipIconBox3Text:
            'This membership includes $locksmithAmount toward locksmith services.',
        membershipIconBox4Title: 'Fuel Delivery',
        membershipIconBox4Text:
            'If they run out of gas, we’ll deliver enough fuel to get them to the nearest gas station (at no additional cost).',
        towingLabel: 'Towing',
        towingStat: 'Miles',
        serviceCallsLabel: 'Service Calls',
        serviceCallsStat: 'Per Year',
        coverageLabel: 'Coverage',
        coverageStat: '365 Days',
        addMemberMessage:
            'AAA Associate membership extend AAA Services to any household member residing at the same address or to any children away at school.',
        addMemberButton: 'Add Associate Member',
        //contactInfoPage
        contactInfoPageTitle: 'Gift Recipient Information',
        contactInfoPageSubtitle:
            'Tell us who you want to give the gift AAA Membership to and include a personalized gift message to the recipient.',
        recipientFirstNameLabel: 'First Name',
        recipientLastNameLabel: 'Last Name',
        recipientMiddleInitialLabel: 'MI',
        recipientFirstNamePlaceholder: 'e.g. Jane',
        recipientLastNamePlaceholder: 'e.g. Doe',
        recipientEmailLabel: 'Email address',
        recipientEmailPlaceholder: 'e.g. email@address.com',
        recipientEmailConfirmLabel: 'Confirm Email address',
        recipientEmailConfirmPlaceholder: 'e.g. email@address.com',
        recipientEmailConfirmDescription:
            'We want to make sure this gets to the right person.',
        recipientTelephoneLabel: 'Telephone number',
        recipientTelephoneError: '',
        giverNameLabel: 'Your Name',
        giverNameLabelSingluar: 'Gift for :',
        giverNamePlaceholder: 'e.g. Grandma and Grandpa Doe',
        giverNameDescription:
            'We’ll let your recipient know who the membership is from.',
        gifEmailLabel: 'Email',
        giftMessageLabel: 'Gift Message',
        giftMessageDefault: 'Enjoy your AAA membership.',
        charactersLeft: 'characters left',
        sendDateLabel: 'Delivery Date',
        errorDateLabel:
            'Delivery date may neither be in the past nor more than 90 days in the future',
        receiptConfirmaion: 'You will be notified when your recipient is notified.',
        fixMessage: 'Please fix the following',
        successMessage: 'Thank you!',
        isRequired: 'is required',
        areRequired: 'are required',
        mustMatch: 'don’t match',
        isInvalid: 'is invalid',
        digitsLeft: 'must include 10 digits',
        reason: 'Reason for Contacting Us',
        lessThan: 'Max characters allowed',

        // cartPage
        cartPageTitle: 'Cart',
        giftMemberships: 'GIFT MEMBERSHIPS',
        checkoutBtn: 'Checkout',
        addAnotherMembershipBtn: '+ Gift another membership',
        membershipLabel: 'Membership',
        recipientLocationLabel: 'City',
        deliveryDateLabel: 'Delivery Date',
        productLabel: 'Product',
        feesLabel: 'Required Fees',
        confirmationNote:
            'We’ll send you an email to let you know it was delivered.',
        additionalNote:
            'Additional one-time Enrollment Fee of $fee will be added to total',
        associates: 'ASSOCIATES',
        gifAssociatesLabel: 'Associates',
        gifTotalLabel: 'Gift Total',
        gifMembershipLabel: 'Membership',
        salexTaxLabel: 'Sales Tax',
        // faqPage
        faqPageTitle: 'AAA Gift \nMembership Help',
        faqPageDescription: '',
        faqMeta:
            'Find answers to the most common questions we receive about our AAA Gift Memberships. ',
        faqCononicalUrl: 'https://gift.aaa.com/faq',
        faqTitle: 'AAA Gift Center | Help.',
        question1: 'Question lorem ipsum dolor sit amet bibodem?',
        answer1:
            'Answer lorem ipsum dolor sit amet consecuter ipsum lorem bibodem dolor amet.',

        // checkoutPage
        checkoutPageTitle: 'Checkout',
        checkoutPageBlurb: 'Almost done! We just need a few more details from you.',
        contactInformationTitle: 'Contact Information',
        acceptedPaymentMethodLabel: 'Accepted Payment Methods',
        securePaymentMethod: 'Secure Payment Method',
        securePayment: 'Secure payment',
        reviewOrder: ' Review Your Order',

        customerFirstNameLabel: 'First Name',
        customerMiddleInitialLabel: 'MI',
        customerLastNameLabel: 'Last Name',
        customerMiddleInitalLabel: 'MI',
        yourEmailLabel: 'Email address',
        buyersEmailLabel: 'Gift Buyer\'s Email address',
        yourEmailPlaceholder: 'e.g. email@address.com',
        yourEmailDescription: 'We’ll send you a receipt for your order.',
        yourEmailConfirmLabel: 'Confirm Email address',
        yourEmailConfirmPlaceholder: 'e.g. email@address.com',
        customerTelephoneLabel: 'Telephone number',
        buyersTelephoneLabel: 'Gift Buyer\'s Telephone number',

        paymentMethodLabel: 'Payment Method',
        cardNameLabel: 'Name on the Card',
        cardNamePlaceholder: 'e.g. Jane Doe',
        cardNumberLabel: 'Card Number',
        cardNumberPlaceholder: 'e.g. 1234567890',
        cardExpirationDateLabel: 'Expiration Date',
        cardExpirationMonthLabel: 'Expiration Month',
        cardExpirationYearLabel: 'Expiration Year',
        cardExpirationMonthPlaceholder: 'MM',
        cardExpirationYearPlaceholder: 'YYYY',
        cardExpirationMonthError: '2 digit month required',
        cardExpirationYearError: '4 digit year required',
        cardExpirationDateError: '2 digit month and year are required',
        securityCodeLabel: 'Security Code',
        securityCodePlaceholder: 'CCV',
        zipCodeLabel: 'Billing ZIP Code',
        zipCodeFormat: 'ZIP Code should be in XXXXX or XXXXX-XXXX format',
        zipCodePlaceholder: 'e.g. 12345',
        emptyCartButtonText: 'Add a Gift Membership',
        fourDigitYear: '4 digit year',
        twoDigitMonth: '2 digit month',
        fullNameRequired: 'Please enter first and last name',

        // reviewOrderPage
        contactUsPageTitle: 'AAA GIFT CENTER: CONTACT US',
        editOrderPageTitle: 'Edit Payment Details',
        reviewOrderPageTitle: 'Complete your purchase',
        reviewOrderPageBlurb:
            'By purchasing this gift membership you are agreeing to our ',
        reviewOrderTermCondition: 'Terms & Conditions',
        submitOrder: 'Submit Order',
        backToCart: 'Back to Cart',
        paymentDetails: 'Payment information',
        acceptTerms: 'By purchasing this gift membership you are agreeing to our ',
        emailAddressLabel: 'Email Address',
        emailAddressPlaceholder: 'e.g. email@address.com',
        membershipNumberAddressLabel: 'Membership Number',
        membershipNumberAddressDescription:
            'If you are an existing AAA member, enter your membership number.',
        membershipNumberAddressError:
            'Membership Number may contain numeric values only.',

        // giverThankYouPage
        giverThankYouPageTitle: 'Thank you!',
        giverThankYouPageBlurb:
            'Your order has been submitted. We’ll send you a confirmation email soon with more details.',
        orderId: 'Order ID',
        whatsNext: "What's Next?",
        whatsNextText:
            'We will send an email to your gift recipient(s) on the delivery date(s) that you specified.  We will also send another email to you at the same time to confirm delivery.  The recipient(s) will be able to follow a link in their email to claim the gift and enroll for their AAA membership after providing a few more personal details, including their complete address.',
        // pinPage
        pinDisclosureForPlusAndPremier: '<b>NOTE: </b>For Plus and Premier, most AAA Clubs limit roadside service benefits to <u>Basic/Classic</u> level during the first week (5-7 days) of the enrolled membership.',
        pinPageTitle: 'Claim Your Gift Membership',
        pinPageBlurb:
            'Welcome to AAA! We look forward to serving you, but first we’ll need you to enroll with your local AAA club.',
        pinGetStartedBlurb:
            'Get started by entering your PIN, found in your AAA gift email.',
        pinLabel: 'PIN',
        invalidPinError: 'Invalid PIN',
        claimIconBox1Text:
            'Complete the enrollment process (it only takes a couple of minutes)',
        claimIconBox2Text: 'Your local AAA club will process your membership',
        claimIconBox3Text:
            'You will receive an email when your membership is active; you can then use your benefits',
        claimIconBox4Text:
            'Soon after, you’ll receive your membership card and other materials',
        // enrollmentPage
        enrollmentPageTitle: 'Membership enrollment information',
        enrollmentPageSubtitle: 'Welcome! Let’s get started.',
        firstNameLabel: 'First Name',
        firstNamePlaceholder: 'e.g. Jane',
        middleInitialLabel: 'MI',
        middleInitialPlaceholder: 'e.g. Q',
        lastNameLabel: 'Last Name',
        lastNamePlaceholder: 'e.g. Doe',
        address1Label: 'Email Address',
        address1Placeholder: 'e.g. 123 Street Name Rd.',
        address2Label: 'Confirm Email Address',
        address2Placeholder: 'e.g. Apt 123',
        addressLabel: 'Address',
        cityLabel: 'City',
        cityPlaceholder: 'e.g. Seattle',
        stateLabel: 'State',
        statePlaceholder: 'e.g. WA',
        birthYearLabel: 'Birth Year',
        birthYearPlaceholder: 'YYYY',
        phoneNumberLabel: 'Phone Number',
        telephoneNumberLabel: 'Telephone',
        phoneNumberPlaceholder: 'e.g. 123-555-6789',
        reviewDetailsBtn: 'Review Details',
        giftReferenceNumber: 'Gift Reference #:',
        // reviewEnrollmentPage
        editEnrollmentTitle: 'Edit your details',
        reviewEnrollmentTitle: 'Review your details',
        reviewEnrollmentSubtitle: 'Let’s make sure we got everything right.',
        enrollmentNameLabel: 'Your Name',
        enrollmentAddress1Label: 'Address Line 1',
        enrollmentAddress2Label: 'Address Line 2',
        enrollmentLocationLabel: 'Location',
        enrollmentZipCodeLabel: 'ZIP Code',
        enrollmentBirthYearLabel: 'Birth Year',
        enrollmentPhoneNumberLabel: 'Telephone Number',
        enrollmentEmailAddressLabel: 'Email Address',
        enrollmentBtn: 'Enroll my Membership',
        enrollmentTerms:
            'By enrolling in this gift membership you are agreeing to our ',
        // recipientThankYouPage
        recipientThankYouTitle: 'Thank You for confirming your AAA Membership,',
        recipientThankYouSubtitle:
            'You’ll receive an email soon to let you know your membership has been enrolled but be aware that your new membership kit may take 3-4 weeks to arrive. Welcome to',
        nowWhat: 'Now what?',
        nextSteps: 'We recommend going over all the great benefits you’ll receive.',
        visitAAABenefits: 'View My AAA Benefits',
        // privacyPage
        privacyPageTitle: 'Privacy Notice',
        // termsPage
        termsPageTitle: 'Terms of Use and Privacy',
        // error modal
        errorClose: 'close',
        errorTitle: 'An error has occurred.',
        errorMessageDefault: 'An unknown error occurred, please try again.',
        errorCancel: 'Cancel',
        errorRetry: 'Retry',
        errorOK: 'OK',
        btnSendLabel: 'Send',
        // membershipInformationPage
        membershipInformationTitle: 'Membership benefits',
        membershipInformationAdditional: 'Addittional Benefits',
        membershipInformationTowing: 'TOWING',
        membershipInformationMiles: 'MILES',
        membershipInformationServiceCall: 'SERVICE CALLS',
        membershipInformationYear: 'PER YEAR',
        membershipInformationCoverage: 'COVERAGE',
        membershipInformationDays: '365 DAYS',
        // promoCode
        promoCode: 'Promo Code',
        promoCodeApply: 'Apply code',
        promoCodeCongratulations: 'Congratulations',
        promoCodeSuccess: 'Promo code applied.',
        promoCodeError: 'Promo Code not applied',
        promoCodeNotApplied: 'The promo code could not be applied.',
        promoCodeRedeemed: 'Promo Code Redeemed',
        promoCodeRedeemedMsg:
            'This promo code has already been used. Please try another.',
        promoCodeExpired: 'Promo Code Expired',
        promoCodeExpiredMsg: 'Please try entering a different promo code.',
        promoCodeInvalid: 'Invalid Code Entered',
        promoCodeInvalidMsg: 'Please try entering your promo code again.',
        promoCodeNote: 'Promo codes may not be valid in all regions.',
        promoCodeWithOutAssociates: 'No Associates',
        promoCodeWithOutAssociatesMsg:
            'This promo code is only valid for gifts with associates – there are no associates on the gifts listed below.',
        // redemptionCode
        redemptionCodeTitle: 'Redeem Your Membership',
        redemptionCodeDescription: 'Get started by entering your Redemption Code.',
        redemptionCodeInputLabel: 'Redemption code',
        searchRedemptionCode: 'Search',
        'redemptionCodeData Code does not exist or has been redeemed':
            'Code does not exist or has been redeemed',
    },
    tnc: {
        lastModified: 'Last Modified: December 6, 2018',
        termsContent:
            '<h3>Acceptance of the Terms of Use</h3>' +
            '<p>These terms of use are entered into by and between You and AAA Washington("<b>Company</b>", "<b>we</b>", or "<b>us</b>").The following terms and conditions, together with any documents they expressly incorporate by reference(collectively, these "<b>Terms of Use</b>"), govern your access to and use of <a href="https://gift.aaa.com">https://gift.aaa.com</a>, including any content, functionality, and services offered on or through https://gift.aaa.com (the "<b>Website</b>"), whether as a guest or a registered user.</p>' +
            '<p>Please read the Terms of Use carefully before you start to use the Website. <b>By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at https://wa.aaa.com/about/privacy-notice, incorporated herein by reference.</b> If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</p>' +
            '<p>This Website is offered and available to users who are 18 years of age or older, and reside in the United States or any of its territories or possessions. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.</p>' +
            '<h3>Changes to the Terms of Use</h3>' +
            '<p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website.</p>' +
            '<p>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>' +
            '<h3>Accessing the Website and Account Security</h3>' +
            '<p>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>' +
            '<p>You are responsible for:' +
            '	<ul>' +
            '		<li>Making all arrangements necessary for you to have access to the Website.</li>' +
            '		<li>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including but not limited to through the use of any interactive features on the Website, is governed by our Privacy Policy https://wa.aaa.com/about/privacy-notice, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>' +
            '<h3>Intellectual Property Rights</h3>' +
            '<p>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:' +
            '				<ul>' +
            '		<li>Your computer may temporarily store copies of such materials incidental to your accessing and viewing those materials.</li>' +
            '		<li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>' +
            '		<li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>' +
            '		<li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>You must not:' +
            '				<ul>' +
            '		<li>Modify copies of any materials from this site.</li>' +
            '		<li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>' +
            '		<li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>' +
            '<p>Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</p>' +
            '<h3>Trademarks</h3>' +
            '<p>The Company name, the terms and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</p>' +
            '<h3>Prohibited Uses</h3>' +
            '<p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:' +
            '				<ul>' +
            '		<li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>' +
            '		<li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>' +
            '		<li>To send, knowingly receive, upload, download, use, or re-use any material which does not comply with the standards set out in these Terms of Use or in any Privacy Policy.</li>' +
            '		<li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter", "spam", or any other similar solicitation.</li>' +
            '		<li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>' +
            "		<li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.</li> " +
            '	</ul>' +
            '</p>' +
            '<p>Additionally, you agree not to:' +
            '				<ul>' +
            "		<li>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website.</li> " +
            '		<li>Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>' +
            '		<li>Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.</li>' +
            '		<li>Use any device, software, or routine that interferes with the proper working of the Website.</li>' +
            '		<li>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>' +
            '		<li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</li>' +
            '		<li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>' +
            '		<li>Otherwise attempt to interfere with the proper working of the Website.</li>' +
            '	</ul>' +
            '</p>' +
            '<h3>User Contributions</h3>' +
            '<p>The Website contains e-commerce selection, purchase, payment, and enrollment features for AAA gift memberships (collectively, "<b>Interactive Services</b>") that allow users to compare, select, and purchase one or more AAA gift memberships for recipients the purchaser identifies, and enables gift recipients to enroll as a new member with their local AAA club.  Gift buyers and gift recipients will be required to submit required information ("<b>User Contributions</b>") to initiate, execute, and complete a gift purchase transaction.</p>' +
            '<p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>' +
            '<p>Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose/according to your account settings.</p>' +
            '<p>You represent and warrant that:' +
            '	<ul>' +
            '		<li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.</li>' +
            '		<li>All of your User Contributions do and will comply with these Terms of Use.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</p>' +
            '<p>We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Website.</p>' +
            '<h3>Monitoring and Enforcement; Termination</h3>' +
            '<p>We have the right to:' +
            '	<ul>' +
            '		<li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>' +
            '		<li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public, or could create liability for the Company.</li>' +
            '		<li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>' +
            '		<li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website.</li>' +
            '		<li>erminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>' +
            '<p>However, we do not undertake to review all material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>' +
            '<h3>Content Standards</h3>' +
            '<p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:' +
            '	<ul>' +
            '		<li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>' +
            '		<li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>' +
            '		<li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>' +
            '		<li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy https://wa.aaa.com/about/privacy-notice.</li>' +
            '		<li>Be likely to deceive any person.</li>' +
            '		<li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>' +
            '		<li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>' +
            '		<li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>' +
            '		<li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</p>' +
            '<h3>Reliance on Information Posted</h3>' +
            '<p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>' +
            '<p>This Website includes content provided by third parties, specifically informational and pricing content provided by participating AAA clubs. The accuracy of content provided by third parties are solely the responsibility of the entity providing those materials. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>' +
            '<h3>Changes to the Website</h3>' +
            '<p>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>' +
            '<h3>Information About You and Your Visits to the Website</h3>' +
            '<p>All information we collect on this Website is subject to our Privacy Policy https://wa.aaa.com/about/privacy-notice. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>' +
            '<h3>Other Terms and Conditions</h3>' +
            '<p>Additional terms and conditions may also apply to specific portions, services, or features of the Website, including the terms of any purchase of goods or services through the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.</p>' +
            '<h3>Linking to the Website and Social Media Features</h3>' +
            '<p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.</p>' +
            '<p>This Website may provide certain social media features that enable you to:' +
            '				<ul>' +
            '		<li>Link from your own or certain third-party websites to certain content on this Website.</li>' +
            '		<li>Send emails or other communications with certain content, or links to certain content, on this Website.</li>' +
            '		<li>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>You may use these features solely as they are provided by us, solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:' +
            '				<ul>' +
            '		<li>Establish a link from any website that is not owned by you.</li>' +
            '		<li>Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>' +
            '		<li>Link to any part of the Website other than the homepage.</li>' +
            '		<li>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</li>' +
            '	</ul>' +
            '</p>' +
            '<p>The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.</p>' +
            '<p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.</p>' +
            '<p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>' +
            '<h3>Links from the Website</h3>' +
            '<p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so \nentirely at your own risk and subject to the terms and conditions of use for such websites.</p>' +
            '<h3>Geographic Restrictions</h3>' +
            '<p>The owner of the Website is based in the state of Washington in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>' +
            '<h3>Disclaimer of Warranties</h3>' +
            '<p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>' +
            '<p>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>' +
            '<p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>' +
            '<p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>' +
            '<h3>Limitation on Liability</h3>' +
            '<p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES , INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>' +
            '<p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>' +
            '<h3>Indemnification</h3>' +
            "<p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services, and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.</p>" +
            '<h3>Governing Law and Jurisdiction</h3>' +
            '<p>All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Washington without giving effect to any choice or conflict of law provision or rule (whether of the State of Washington or any other jurisdiction).</p>' +
            '<p>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of Washington in each case located in the City of Seattle and County of King although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>' +
            '<h3>Arbitration</h3>' +
            "<p>At Company's sole discretion, it may require You to submit any disputes arising from the use of these Terms of Use or the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Washington law.</p>" +
            '<h3>Limitation on Time to File Claims</h3>' +
            '<p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>' +
            '<h3>Waiver and Severability</h3>' +
            '<p>No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>' +
            '<p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>' +
            '<h3>Entire Agreement</h3>' +
            '<p>The Terms of Use and our Privacy Policy and any other terms Company provides you, including without limitation, terms of sale constitute the sole and entire agreement between you and AAA Washington regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</p>' +
            '<h3>Your Comments and Concerns</h3>' +
            '<p>This website is operated by AAA Washington 3605 132nd AVE SE, Bellevue, WA 98006.</p>' +
            '<p>All notices of copyright infringement claims should be sent to the above listed address.</p>' +
            '<p>All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: AAAGiftCenter@aaawa.com.</p>',
    },
}

export default en
