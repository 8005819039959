import React from 'react';
import './membershipDetails.css';
import { withTranslation } from "react-i18next";
import Section from '../../components/section/section';
import Card from '../card/card';
import CardMembershipStat from '../cardMembershipStat/cardMembershipStat';
import { PADDING_ALL } from '../../utils/styleValues';
import Btn, { SIZE_MEDIUM, DISPLAY_INLINE, COLOR_ACTION } from '../btn/btn';
import utils from '../../utils/utils';
import CostAdjustments from '../costAdjustments/costAdjustments';
import { connect } from 'react-redux';

class MembershipDetails extends React.Component {

    _onMembershipSelect(e) {
        if (this.isProductPreviewModeEnabled) {
            return
        }
        const { onSelect, membership } = this.props;
        if (utils.isFunction(onSelect)) {
            onSelect(membership);
        }
    }

    render() {
        const { t, membership } = this.props;
        return (
            <div className="membership-details">
                <Card padding={PADDING_ALL}
                    className="membership-details--cost">
                    <div style={{ textAlign: 'left' }}>
                        <div style={{ fontSize: "1.25rem", lineHeight: "1.25rem", color: '#4c4c4c' }}>
                            <strong itemProp="price">${membership.cost.toFixed(2)}</strong>&nbsp;/&nbsp;year
                        </div>
                        <div className={membership.enrollmentFee > 0 ? "" : "none"} style={{ fontSize: "1rem", lineHeight: "1.25rem", color: '#4c4c4c' }}>
                            {t('Enrollment fee')}&nbsp;/&nbsp;${membership.enrollmentFee.toFixed(2)}
                        </div>
                        <div className={membership.costPerAssociate > 0 ? "" : "none"} style={{ fontSize: "1rem", lineHeight: "1.25rem", color: '#4c4c4c' }}>
                            {t('Associate Members')}&nbsp;/&nbsp;${membership.costPerAssociate.toFixed(2)}
                        </div>
                    </div>
                    <div className="membership-details--cost-select">
                        <Btn label={t('select')}
                            onClick={this._onMembershipSelect.bind(this)}
                            className={[
                                'membership-details--cost-select-btn',
                                SIZE_MEDIUM,
                                DISPLAY_INLINE,
                                COLOR_ACTION
                            ]} />
                    </div>
                    <CostAdjustments plan={membership} hideTotal={true} />
                </Card>
                <Section padding={PADDING_ALL} noInner={true}
                    className="membership-details--cost">
                    <div itemProp="description" style={{ borderStyle: "none" }} className="ql-container ql-snow">
                        <span style={{ padding: 0 }} className="ql-editor" dangerouslySetInnerHTML={{ __html: membership.longDescription }}>
                        </span>
                    </div>
                </Section>
                <CardMembershipStat className="membership-details--towing"
                    label={t('towingLabel')}
                    stat={t('towingStat')}
                    value={membership.towing}
                    exception={membership.mileageException} />
                <CardMembershipStat className="membership-details--service"
                    label={t('serviceCallsLabel')}
                    stat={t('serviceCallsStat')}
                    value={membership.entitlements} />
                <CardMembershipStat className="membership-details--coverage"
                    label={t('coverageLabel')}
                    stat={t('coverageStat')}
                    value={"24/7"} />
                {membership.mileageException ? (
                    <p className="membership-details--exception"><span className="warning">*</span> {membership.mileageException}</p>
                ) : null}
            </div>
        );
    }

    get isProductPreviewModeEnabled() {
        return this.props.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        productPreviewModeEnabled: state.config.productPreviewModeEnabled,
    };
}

export default withTranslation('global')(connect(mapStateToProps)(MembershipDetails));

