import React from 'react'
import './membershipBenefits.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import 'react-quill/dist/quill.snow.css'
// import utils from '../../utils/utils';
import Card from '../../components/card/card'
// import CardMembershipStat from '../cardMembershipStat/cardMembershipStat';
import { PADDING_ALL } from '../../utils/styleValues'
import { get } from 'lodash'

class MembershipBenefits extends React.Component {
    render() {
        const { t, plan, product } = this.props

        return (
            <Card padding={PADDING_ALL} className="membership-benefits">
                <div className="membership-benefits--glance">
                    <p className="membership-benefits--name">
                        {get(product, 'name') ? get(product, 'name') : get(plan, 'name') || ''}
                    </p>
                </div>
                <div className="membership-benefits--details">
                    <hr className={'hr'} />
                    <div
                        style={{ borderStyle: 'none' }}
                        className="membership-benefits--content ql-container ql-snow"
                    >
                        <span
                            style={{ padding: 0 }}
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: get(product, 'longDescription') ? get(product, 'longDescription')
                                    : get(plan, 'longDescription') || '<p></p>',
                            }}
                        ></span>
                    </div>
                    <div className="membership-benefits--group-info">
                        {/* <CardMembershipStat className="membership-benefits--first-column"
							label={t('towingLabel')}
							stat={t('towingStat')}
							value={plan.towing}
							exception={!!plan.mileageException} />
						<CardMembershipStat className="membership-benefits--second-column"
							label={t('serviceCallsLabel')}
							stat={t('serviceCallsStat')}
							value={plan.entitlements} />
						<CardMembershipStat className="membership-benefits--third-column"
							label={t('coverageLabel')}
							stat={t('coverageStat')}
							value={"24/7"} /> */}
                        <div className="notactive membership-benefits--first-column">
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationTowing')}
                            </p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-value">
                                {get(product, 'towing') ? get(product, 'towing') : get(plan, 'towing')}
                                {(
                                    get(product, 'mileageException')
                                        ? get(product, 'mileageException')
                                        : get(plan, 'mileageException') || ''
                                ) ? (
                                    <span className="warning">*</span>
                                ) : null}
                            </p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationMiles')}
                            </p>
                        </div>
                        <div className="notactive membership-benefits--second-column">
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationServiceCall')}
                            </p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-value">
                                {get(product, 'entitlements')
                                    ? get(product, 'entitlements')
                                    : get(plan, 'entitlements') || ''}
                            </p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationYear')}
                            </p>
                        </div>
                        <div className="notactive membership-benefits--third-column">
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationCoverage')}
                            </p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-value">24/7</p>
                            {/* <hr className="membership-benefits--hr-column" /> */}
                            <p className="membership-benefits--column-label">
                                {t('membershipInformationDays')}
                            </p>
                        </div>
                    </div>
                    {(
                        get(product, 'mileageException')
                            ? get(product, 'mileageException')
                            : get(plan, 'mileageException')
                    ) ? (
                        <p className="membership-benefits--content">
                            <span className="warning">*</span>{' '}
                            {get(product, 'mileageException')
                                ? get(product, 'mileageException')
                                : get(plan, 'mileageException') || ''}
                        </p>
                    ) : null}
                    {/* <Section padding={PADDING_ALL} className="membership-detail-page--description"> */}
                    <p className="membership-benefits--content">
                        {t('membershipStartDate')}
                    </p>
                    {/* </Section> */}
                </div>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        product: state.redemptionCode.product,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MembershipBenefits)))
