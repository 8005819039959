import TagManager from 'react-gtm-module'

export const initGTM = (trackingID) => {

    const tagManagerArgs = {
        gtmId: trackingID
    }

    TagManager.initialize(tagManagerArgs);
}

export const PageView = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: window.location.pathname +
                window.location.search
        },
    });
}

export const Event = (category, action, label, value) => {
    window.dataLayer.push({
        event: 'event',
        eventProps: {
            category: category,
            action: action,
            label: label,
            value: value
        }
    });
};

export const PageViewUpdate = (path) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: path
        }
    });
}