export const CUSTOMER_REGION = 'membership.CUSTOMER_REGION';
export const PENDING_CUSTOMER_REGION = 'membership.PENDING_CUSTOMER_REGION';
export const REGION_SUGGESTIONS = 'membership.REGION_SUGGESTIONS';
export const SET_CLUB = 'membership.SET_CLUB';
export const SELECTED_PLAN = 'membership.SELECTED_PLAN';
export const RECIPIENT_FIRST_NAME = 'membership.RECIPIENT_FIRST_NAME';
export const RECIPIENT_LAST_NAME = 'membership.RECIPIENT_LAST_NAME';
export const RECIPIENT_EMAIL = 'membership.RECIPIENT_EMAIL';
export const RECIPIENT_EMAIL_CONFIRM = 'membership.RECIPIENT_EMAIL_CONFIRM';
export const GIVER_NAME = 'membership.GIVER_NAME';
export const GIFT_MESSAGE = 'membership.GIFT_MESSAGE';
export const DELIVERY_DATE = 'membership.DELIVERY_DATE';
export const RESET_MEMBERSHIP = 'membership.RESET_MEMBERSHIP';
export const PREP_EDIT = 'membership.PREP_EDIT';
export const STATE_NAME = 'membership.STATE_NAME';
export const CITY_NAME = 'membership.CITY_NAME';
export const RECIPIENT_MIDDLE_INITIAL = 'membership.RECIPIENT_MIDDLE_INITIAL';
export const RECIPIENT_TELEPHONE = 'membership.RECIPIENT_TELEPHONE';
export const LOCATION_KEY = 'membership.LOCATION.KEY';
export const RECIPIENT_SET_ASSOCIATES = 'membership.RECIPIENT_SET_ASSOCIATES';
export const RECIPIENT_ADD_ASSOCIATES = 'membership.RECIPIENT_ADD_ASSOCIATES';
export const RECIPIENT_DELETE_ASSOCIATES = 'membership.RECIPIENT_DELETE_ASSOCIATES';
export const RECIPIENT_SET_ASSOCIATE_PROP = 'membership.RECIPIENT_SET_ASSOCIATE_PROP';
export const RECIPIENT_RESET_ASSOCIATE = 'membership.RECIPIENT_RESET_ASSOCIATE';

export function prepareEditCustomer(customer) {
	return {
		type: PREP_EDIT,
		value: customer
	};
}

export function setRecipientLocation(val) {
	return {
		type: CUSTOMER_REGION,
		value: val
	};
}

export function setPendingRecipientLocation(val) {
	return {
		type: PENDING_CUSTOMER_REGION,
		value: val
	};
}

export function setLocationSuggestions(suggestions) {
	return {
		type: REGION_SUGGESTIONS,
		value: suggestions
	};
}

export function setSelectedPlan(membership) {
	return {
		type: SELECTED_PLAN,
		value: membership
	};
}

export function setRecipientFirstName(name) {
	return {
		type: RECIPIENT_FIRST_NAME,
		value: name
	};
}

export function setRecipientLastName(name) {
	return {
		type: RECIPIENT_LAST_NAME,
		value: name
	};
}

export function setRecipientMiddleInitial(name) {
	return {
		type: RECIPIENT_MIDDLE_INITIAL,
		value: name
	};
}

export function setRecipientEmail(email) {
	return {
		type: RECIPIENT_EMAIL,
		value: email
	};
}

export function setRecipientEmailConfirm(email) {
	return {
		type: RECIPIENT_EMAIL_CONFIRM,
		value: email
	};
}

export function setRecipientTelephone(telephone) {
	return {
		type: RECIPIENT_TELEPHONE,
		value: telephone
	};
}

export function setGiverName(name) {
	return {
		type: GIVER_NAME,
		value: name
	};
}

export function setGiftMessage(msg) {
	return {
		type: GIFT_MESSAGE,
		value: msg
	};
}

export function setDeliveryDate(date) {
	return {
		type: DELIVERY_DATE,
		value: date
	};
}

export function setClub(club) {
	return {
		type: SET_CLUB,
		value: club
	};
}

export function setAssociates(ass) {
	return {
		type: RECIPIENT_SET_ASSOCIATES,
		value: ass
	}
}

export function addAssociate() {
	return {
		type: RECIPIENT_ADD_ASSOCIATES
	}
}

export function deleteAssociate(id) {
	return {
		type: RECIPIENT_DELETE_ASSOCIATES,
		value: id
	}
}

export function setAssociateProp(ass) {
	return {
		type: RECIPIENT_SET_ASSOCIATE_PROP,
		value: ass
	}
}

export function resetAssociates() {
	return {
		type: RECIPIENT_RESET_ASSOCIATE,
	}
}

export function setStateName(stateName) {
	return {
		type: STATE_NAME,
		value: stateName
	};
}

export function setCityName(cityName) {
	return {
		type: CITY_NAME,
		value: cityName
	};
}

export function setLocationKey(key) {
	return {
		type: LOCATION_KEY,
		value: key
	};
}

export function resetMembership() {
	return {
		type: RESET_MEMBERSHIP
	};
}
