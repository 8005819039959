import React from 'react';
import './contactInfoForm.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import TextInput from '../../components/textInput/textInput';
import ErrorList from '../../components/errorList/errorList';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION, COLOR_ALT_SIMPLE, DISPLAY_BLOCK_LEFT, BUTTON_PAD } from '../../components/btn/btn';
import validators, { runValidators } from '../../utils/validators';
import utils from '../../utils/utils';
import config from '../../config';

const { routes } = config;

class ContactInfoForm extends React.Component {
    constructor(props) {
        super(props);
        const { data, t } = props;
        console.log("props", props);
        console.log("t", t);
        this.email = React.createRef();
        this.emailConfirm = React.createRef();
        this.validators = {
            firstName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('recipientFirstNameLabel')} ${t('isRequired')}`
            }],
            lastName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('recipientLastNameLabel')} ${t('isRequired')}`
            }],
            email: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('recipientEmailLabel')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('recipientEmailLabel')} ${t('isInvalid')}`
            },
            {
                validator: this._compareEmails.bind(this),
                message: `${t('recipientEmailLabel')} ${t('and')} ${t('recipientEmailConfirmLabel')} ${t('mustMatch')}`
            }],
            emailConfirm: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('recipientEmailConfirmLabel')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('recipientEmailConfirmLabel')} ${t('isInvalid')}`
            }],
            giftMessage: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('giftMessageLabel')} ${t('isRequired')}`
            }],
            recipientTelephone: [{
                validator: validators.validTelephoneOptional,
                message: `${t('recipientTelephoneLabel')} ${t('digitsLeft')}`
            }],
            deliveryDate: [{
                validator: validators.isDateStringV2,
                message: `${t('errorDateLabel')}`
            }, {
                validator: validators.isDateBetween(-1, config.maxFutureDaysSendGift + 1),
                message: `${t('errorDateLabel')}`
            }],
            assFirstName: [{
                validator: validators.isNotNullOrEmpty,
                message: `Associate First Name ${t('isRequired')}`
            }]
        };
        this.state = {
            hasSubmittedForm: false,
            recipientEmailsEqualError: false,
            recipientFirstName: data.recipientFirstName,
            recipientLastName: data.recipientLastName,
            recipientMiddleInitial: data.recipientMiddleInitial,
            recipientEmail: data.recipientEmail,
            recipientEmailConfirm: data.recipientEmailConfirm,
            recipientTelephone: data.recipientTelephone,
            giftMessage: data.giftMessage,
            deliveryDate: data.deliveryDate,
            costPerAssociate: data.selectedPlan.costPerAssociate,
            associates: data.associates,
            recipientFirstNameError: runValidators(data.recipientFirstName, this.validators.firstName),
            recipientLastNameError: runValidators(data.recipientLastName, this.validators.lastName),
            recipientEmailMatchError: runValidators(data.recipientEmailConfirm, this.validators.email),
            recipientEmailConfirmMatchError: runValidators(data.recipientEmail, this.validators.emailConfirm),
            recipientTelephoneError: runValidators(data.recipientTelephone, this.validators.recipientTelephone),
            giftMessageError: runValidators(data.giftMessage, this.validators.giftMessage),
            deliveryDateError: runValidators(data.deliveryDate, this.validators.deliveryDate),
            associatesLocal: data.associates.length > 0 ? data.associates : []
        };
        // submit btn
        this._submitRef = null;
        this._submitRefCallback = this._onSubmitRef.bind(this);

        this._addAssociate = this._addAssociate.bind(this);
    }

    componentDidMount() {
        const { t, history, data } = this.props;
        const { selectedPlan, recipientLocation } = data;
        const { giftMessage, deliveryDate } = this.state;
        const isLocalhost = window.location.hostname === 'localhost';
        if ((selectedPlan && recipientLocation) || isLocalhost) {
            if (!giftMessage) {
                this.setState({
                    giftMessage: t('giftMessageDefault'),
                    giftMessageError: null
                });
            }
            if (!deliveryDate || utils.reclaimDate(deliveryDate) < new Date()) {
                this.setState({
                    deliveryDate: utils.formatDate(new Date()),
                    deliveryDateError: null
                });
            }
        } else if (recipientLocation && !isLocalhost) {
            const region = encodeURIComponent(utils.formatRegion(recipientLocation));
            utils.scrollTop();
            history.push(`${routes.chooseMembershipPage}/${region}`);
        } else if (!isLocalhost) {
            utils.scrollTop();
            history.push(routes.locationSelectPage);
        }
    }


    _validTelephone() {
        if (!this.state) {
            return true;
        }
        const recipientTelephone = this.state ? this.state.recipientTelephone : null;
        return recipientTelephone.match("^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$")
    }

    _compareEmails() {
        if (!this.state) {
            return true;
        }
        const recipientEmail = this.state ? this.state.recipientEmail : null;
        const recipientEmailConfirm = this.state ? this.state.recipientEmailConfirm : null;
        return (recipientEmail || recipientEmailConfirm) && ((recipientEmail + '').toLowerCase() === (recipientEmailConfirm + '').toLowerCase());
    }

    _change(prop) {
        const { onValueChange, t } = this.props;
        return (val) => {
            if (prop === 'recipientTelephone') {
                const input = document.getElementById('recipient_telephone');
                const start = input.selectionStart;
                this.setState({
                    [prop]: val
                }, () => {
                    const startModified = input.selectionStart;
                    if (start === 5 || (start === 4 && startModified >= 8)) {
                        input.setSelectionRange(start + 2, start + 2)
                    } else if (start === 9 || start === 10 || start === 6 || (start === 1 && startModified >= 7)) {
                        input.setSelectionRange(start + 1, start + 1)
                    } else if (start === 4 && startModified === 7) {
                        input.setSelectionRange(start + 3, start + 3)
                    } else {
                        input.setSelectionRange(start, start)
                    }
                });
            } else {
                if (prop === 'recipientEmail' || prop === 'recipientEmailConfirm') {
                    val = val.trim();
                }
                this.setState({
                    [prop]: val
                }, () => {
                    if (prop === 'recipientEmailConfirm') {
                        this.email.current._validate(this.email.current._inputRef.value);
                    }
                    if (this.state.recipientEmailMatchError === `${t('recipientEmailLabel')} ${t('and')} ${t('recipientEmailConfirmLabel')} ${t('mustMatch')}`) {
                        this.setState({
                            recipientEmailsEqualError: true
                        })
                    } else {
                        this.setState({
                            recipientEmailsEqualError: false
                        })
                    }
                });
            }
            if (utils.isFunction(onValueChange) && !/(Form|Error)$/.test(prop)) {
                onValueChange(prop, val);
            }
        };
    }

    _addAssociate() {
        let joined = null;

        if (config.maxNumberOfAssociates > this.state.associatesLocal.length) {
            const { onAssociateAdd } = this.props;
            const arr = this.state.associatesLocal.map((d) => {
                return d.id
            });
            const lastId = arr.length > 0 ? Math.min(...arr) - 1 : -1;
            joined = this.state.associatesLocal.concat({ id: lastId, firstName: '', middleName: '', lastName: '' });
            this.setState({ associatesLocal: joined });
            onAssociateAdd();
        }
    }

    _deleteAssociate(id) {
        const { onSetAssociate } = this.props;
        const associatesLocal = [...this.state.associatesLocal]
        let index = 0;

        for (let i = 0; i < associatesLocal.length; i++) {
            if (associatesLocal[i].id === id) {
                index = i;
            }
        }

        associatesLocal.splice(index, 1);

        this.setState({ associatesLocal });
        onSetAssociate(associatesLocal);
    }

    _setFirstName = (e, id) => {
        const { onChangeAssociate } = this.props;
        const associatesLocal = [...this.state.associatesLocal];

        let item = null;
        associatesLocal.map((x) => {
            if (x.id === id) {
                item = { ...x }
            }
        });

        item.firstName = e;

        for (let i = 0; i < associatesLocal.length; i++) {
            if (associatesLocal[i].id === id) {
                associatesLocal[i] = item;
            }
        }

        this.setState({ associatesLocal });

        onChangeAssociate({ id: item.id, field: { firstName: item.firstName } });
    }

    _setMiddleName = (e, id) => {
        const { onChangeAssociate } = this.props;
        //onChangeAssociate({ field: { middleName: e } });
        const items = [...this.state.associatesLocal];
        let item = null;
        items.map((x) => {
            if (x.id === id) {
                item = x
            }
        });
        item.middleName = e;
        items.map((x) => {
            if (x.id === id) {
                x = item
            }
        });
        this.setState({ associatesLocal: items });
        onChangeAssociate({ id: item.id, field: { middleName: item.middleName } });
    }

    _setLastName = (e, id) => {
        const { onChangeAssociate } = this.props;
        //const { onChangeAssociate } = this.props;
        //onChangeAssociate({ field: { lastName: e } });
        const items = [...this.state.associatesLocal];
        let item = null;
        items.map((x) => {
            if (x.id === id) {
                item = x
            }
        });
        item.lastName = e;
        items.map((x) => {
            if (x.id === id) {
                x = item
            }
        });
        this.setState({ associatesLocal: items });
        onChangeAssociate({ id: item.id, field: { lastName: item.lastName } });
    }

    _onSubmitForm(e) {
        const { onAssociateAdd, onChangeAssociate, onSubmit, onAssociateReset } = this.props;

        if (this.state.associatesLocal) {
            onAssociateReset();

            this.state.associatesLocal.map((x) => {
                onAssociateAdd();
                onChangeAssociate({ id: x.id, field: { firstName: x.firstName } });
                onChangeAssociate({ id: x.id, field: { middleName: x.middleName } });
                onChangeAssociate({ id: x.id, field: { lastName: x.lastName } });
            })
        }

        const {
            recipientFirstName,
            recipientLastName,
            recipientMiddleInitial,
            recipientEmail,
            recipientEmailConfirm,
            recipientTelephone,
            giftMessage,
            deliveryDate,
            associatesLocal
        } = this.state;

        const associates = associatesLocal;

        e.preventDefault();
        this.setState({
            hasSubmittedForm: true
        });
        if (!this._hasError() && utils.isFunction(onSubmit)) {
            onSubmit({
                recipientFirstName,
                recipientLastName,
                recipientMiddleInitial,
                recipientEmail,
                recipientEmailConfirm,
                recipientTelephone,
                giftMessage,
                deliveryDate,
                associates
            });
        }
    }

    _onCancelForm() {
        const { onCancel } = this.props;
        if (utils.isFunction(onCancel)) {
            onCancel();
        }
    }

    _hasError() {
        const {
            recipientFirstNameError,
            recipientLastNameError,
            recipientEmailMatchError,
            recipientEmailConfirmMatchError,
            giftMessageError,
            deliveryDateError,
            recipientTelephoneError,
        } = this.state;
        const associateErrors = this.getAssociateErrors()
        return recipientFirstNameError ||
            recipientLastNameError ||
            recipientEmailMatchError ||
            recipientEmailConfirmMatchError ||
            giftMessageError ||
            recipientTelephoneError ||
            deliveryDateError ||
            associateErrors;
    }

    getAssociateErrors() {
        const { associatesLocal } = this.state;
        let result = false;

        associatesLocal.map((x) => {
            if (!x.firstName || x.firstName.trim().length === 0) {
                result = true;
            }
            if (!x.lastName || x.lastName.trim().length === 0) {
                result = true;
            }
        })

        return result;
    }

    _listErrors() {
        const { t } = this.props;
        const {
            hasSubmittedForm,
            recipientFirstNameError,
            recipientLastNameError,
            recipientEmailMatchError,
            recipientEmailConfirmMatchError,
            recipientTelephoneError,
            giftMessageError,
            deliveryDateError
        } = this.state;
        if (hasSubmittedForm && this._hasError()) {
            const list = [];
            if (recipientFirstNameError) {
                list.push({
                    key: 'recipientFirstNameError',
                    msg: recipientFirstNameError
                });
            }
            if (recipientLastNameError) {
                list.push({
                    key: 'recipientLastNameError',
                    msg: recipientLastNameError
                });
            }
            if (recipientEmailMatchError) {
                list.push({
                    key: 'recipientEmailMatchError',
                    msg: recipientEmailMatchError
                });
            }
            if (recipientEmailConfirmMatchError) {
                list.push({
                    key: 'recipientEmailConfirmMatchError',
                    msg: recipientEmailConfirmMatchError
                })
            }
            if (recipientTelephoneError) {
                list.push({
                    key: 'recipientTelephoneError',
                    msg: recipientTelephoneError
                });
            }
            if (giftMessageError) {
                list.push({
                    key: 'giftMessageError',
                    msg: giftMessageError
                });
            }
            if (deliveryDateError) {
                list.push({
                    key: 'deliveryDateError',
                    msg: deliveryDateError
                });
            }
            return (
                <ErrorList heading={t('fixMessage')} list={list} />
            );
        } else {
            return null;
        }
    }

    _onSubmitRef(ref) {
        this._submitRef = ref;
    }

    renderAssociates(asso) {
        const { t } = this.props;
        const { hasSubmittedForm } = this.state;

        return (
            asso.map((ass, index) => {
                return (
                    <div id={index} className="contact-info-form--group contact-info-form--group-recipient">
                        <div className="contact-info-form--form-group">
                            <TextInput className="contact-info-form--recipient-first-name"
                                value={ass.firstName}
                                maxLength={256}
                                onChange={(e) => {
                                    this._setFirstName(e, ass.id)
                                }}
                                label={t('recipientFirstNameLabel')}
                                name="recipient_add_member_first_name"
                                id="recipient_add_member_first_name"
                                error={hasSubmittedForm && (!ass.firstName || ass.firstName.trim().length === 0)}
                            />
                            <TextInput className="contact-info-form--recipient-middle-inital"
                                value={ass.middleName}
                                onChange={(e) => {
                                    this._setMiddleName(e, ass.id)
                                }}
                                label={t('recipientMiddleInitialLabel')}
                                name="recipient_add_member_middle_inital"
                                maxLength={1}
                                id="recipient_add_member_middle_initial" />
                        </div>
                        <div className="contact-info-form--form-group">
                            <TextInput className="contact-info-form--recipient-last-name"
                                value={ass.lastName}
                                required={true}
                                maxLength={30}
                                onChange={(e) => {
                                    this._setLastName(e, ass.id)
                                }}
                                label={t('recipientLastNameLabel')}
                                name="recipient_add_member_last_name"
                                id="recipient_add_member_last_name"
                                error={hasSubmittedForm && (!ass.lastName || ass.lastName.trim().length === 0)} />

                            <img className="icon-text-box--icon"
                                style={{ marginBottom: "1.1rem", cursor: 'pointer' }}
                                src="/images/icons/trash-can-icon.png"
                                alt="Delete"
                                onClick={() => {
                                    this._deleteAssociate(ass.id)
                                }} />
                        </div>
                    </div>
                )
            })
        );
    }

    render() {
        const {
            t,
            saveButtonLabel,
            cancelButtonLabel,
            onCancel,
            hideConfirmationLabel
        } = this.props;
        const hasCancelButton = utils.isFunction(onCancel);
        const btnsMarginClass = hideConfirmationLabel === true ? 'contact-info-form--btns-pad' : '';
        const btnsCssClass = hasCancelButton ? 'contact-info-form--btns-multi' : 'contact-info-form--btns-single';
        const {
            recipientFirstName,
            recipientLastName,
            recipientMiddleInitial,
            recipientEmail,
            recipientEmailConfirm,
            recipientTelephone,
            giftMessage,
            deliveryDate,
            hasSubmittedForm,
            recipientFirstNameError,
            recipientLastNameError,
            recipientEmailMatchError,
            recipientEmailConfirmMatchError,
            recipientTelephoneError,
            recipientEmailsEqualError,
            giftMessageError,
            deliveryDateError,
            costPerAssociate
        } = this.state;
        const msgRemainingChars = config.maxGiftMessageLength - (giftMessage || '').length;
        return (
            <form action=""
                className="contact-info-form"
                name="recipient_contact_form"
                encType="multipart/form-data"
                method="post"
                autoComplete="off">
                {/* maybe autocomplete, make sure plays well with suggestions list */}
                <p className="required-label">* {t('requiredField')}</p>
                {this._listErrors()}
                {/* recipient name */}
                <div className="contact-info-form--group contact-info-form--group-recipient">
                    <div className="contact-info-form--form-group">
                        <TextInput className="contact-info-form--recipient-first-name"
                            value={recipientFirstName}
                            error={hasSubmittedForm && recipientFirstNameError}
                            required={true}
                            maxLength={30}
                            onChange={this._change('recipientFirstName').bind(this)}
                            label={t('recipientFirstNameLabel')}
                            name="recipient_first_name"
                            id="recipient_first_name"
                            validators={this.validators.firstName}
                            onValidationChange={this._change('recipientFirstNameError').bind(this)} />
                        <TextInput className="contact-info-form--recipient-middle-inital"
                            value={recipientMiddleInitial}
                            onChange={this._change('recipientMiddleInitial').bind(this)}
                            label={t('recipientMiddleInitialLabel')}
                            name="recipient_middle_inital"
                            maxLength={1}
                            id="recipient_middle_initial" />
                    </div>
                    <TextInput className="contact-info-form--recipient-last-name"
                        value={recipientLastName}
                        error={hasSubmittedForm && recipientLastNameError}
                        required={true}
                        maxLength={30}
                        onChange={this._change('recipientLastName').bind(this)}
                        label={t('recipientLastNameLabel')}
                        name="recipient_last_name"
                        id="recipient_last_name"
                        validators={this.validators.lastName}
                        onValidationChange={this._change('recipientLastNameError').bind(this)} />
                </div>
                <div className="contact-info-form--group contact-info-form--group-email">
                    {/* email */}
                    <TextInput className="contact-info-form--recipient-email"
                        error={hasSubmittedForm && recipientEmailMatchError}
                        value={recipientEmail}
                        required={true}
                        maxLength={256}
                        onChange={this._change('recipientEmail').bind(this)}
                        label={t('recipientEmailLabel')}
                        name="recipient_email"
                        id="recipient_email"
                        ref={this.email}
                        validators={this.validators.email}
                        onValidationChange={this._change('recipientEmailMatchError').bind(this)} />
                    {/* email confirm */}
                    <TextInput className="contact-info-form--recipient-email-confirm"
                        error={hasSubmittedForm && (recipientEmailConfirmMatchError || recipientEmailsEqualError)}
                        value={recipientEmailConfirm}
                        required={true}
                        maxLength={256}
                        onChange={this._change('recipientEmailConfirm').bind(this)}
                        label={t('recipientEmailConfirmLabel')}
                        description={t('recipientEmailConfirmDescription')}
                        name="recipient_email_confirm"
                        id="recipient_email_confirm"
                        ref={this.emailConfirm}
                        validators={this.validators.emailConfirm}
                        onValidationChange={this._change('recipientEmailConfirmMatchError').bind(this)} />
                </div>
                <TextInput className="contact-info-form--telephone"
                    maxLength={20}
                    value={recipientTelephone}
                    error={hasSubmittedForm && recipientTelephoneError}
                    onChange={this._change('recipientTelephone').bind(this)}
                    label={t('recipientTelephoneLabel')}
                    name="recipient_telephone"
                    id="recipient_telephone"
                    validators={this.validators.recipientTelephone}
                    onValidationChange={this._change('recipientTelephoneError').bind(this)}
                />
                {costPerAssociate !== 0 &&
                    <div className={`${btnsMarginClass}`}>
                        <div>
                            <strong>Add Associate Member</strong>
                        </div>
                        <div style={{ marginBottom: '0.5rem' }}>
                            {t('addMemberMessage')}
                        </div>
                        {this.state.associatesLocal && this.state.associatesLocal.length > 0 &&
                            <table>
                                <tbody>
                                    {this.renderAssociates(this.state.associatesLocal)}
                                </tbody>
                            </table>
                        }
                        <Btn label={t('addMemberButton')}
                            type="button"
                            disabled={config.maxNumberOfAssociates <= this.state.associatesLocal.length}
                            onClick={this._addAssociate}
                            className={[COLOR_ALT_SIMPLE, SIZE_MEDIUM, DISPLAY_BLOCK_LEFT, BUTTON_PAD]} />
                    </div>
                }
                {/* gift message */}
                <TextInput className="contact-info-form--gift-message"
                    error={hasSubmittedForm && giftMessageError}
                    type="textarea"
                    value={giftMessage}
                    required={true}
                    onChange={this._change('giftMessage').bind(this)}
                    label={t('giftMessageLabel')}
                    maxLength={config.maxGiftMessageLength}
                    description={`${msgRemainingChars} ${t('charactersLeft')}`}
                    name="gift_message"
                    id="gift_message"
                    validators={this.validators.giftMessage}
                    onValidationChange={this._change('giftMessageError').bind(this)} />
                {/* send date */}
                <TextInput className="contact-info-form--send-date"
                    type="date"
                    error={hasSubmittedForm && deliveryDateError}
                    value={deliveryDate}
                    required={true}
                    onChange={this._change('deliveryDate').bind(this)}
                    label={t('sendDateLabel')}
                    maxLength={10}
                    description={hideConfirmationLabel !== true && t('receiptConfirmaion')}
                    name="send_date"
                    id="send_date"
                    nextTabItem={this._submitRef}
                    validators={this.validators.deliveryDate}
                    onValidationChange={this._change('deliveryDateError').bind(this)} />
                {/* submit */}
                <div className={`contact-info-form--btns ${btnsCssClass} ${btnsMarginClass}`}>
                    <Btn label={saveButtonLabel || t('continue')}
                        type="submit"
                        onRef={this._submitRefCallback}
                        onClick={this._onSubmitForm.bind(this)}
                        className={['contact-info-form--submit', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION]} />
                    {hasCancelButton ? (
                        <Btn label={cancelButtonLabel || t('cancel')}
                            type="button"
                            onClick={this._onCancelForm.bind(this)}
                            className={['contact-info-form--cacncel', SIZE_MEDIUM, COLOR_ALT_SIMPLE]} />
                    ) : null}
                </div>
            </form>
        );
    }
}
export default withTranslation('global')(withRouter(ContactInfoForm));
