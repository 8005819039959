import React from 'react';
import './hero.css';

export const HAS_IMAGE = 'hero-image';
export const NO_IMAGE = 'hero-no-image';

class Hero extends React.Component {

	_renderTitle(title) {
		const { isProductName } = this.props;
		if (isProductName === true) {
			return (<h1 className="hero--title" itemProp="name">{title}</h1>);
		} else {
			return (<h1 className="hero--title">{title}</h1>);
		}
	}

	render() {
		const { image, title, subtitle } = this.props;
		const imgClass = image ? HAS_IMAGE : NO_IMAGE;
		const imgStyle = { backgroundImage: image ? `url(${image})` : 'none' };
		return (
			<div className={`hero ${imgClass}`}
				style={imgStyle}>
				<div className="hero--inner app--centered">
					<header className="hero--header">
						{this._renderTitle(title)}
						{subtitle && <h2 className="hero--subtitle">{subtitle}</h2>}
					</header>
				</div>
			</div>
		);
	}
}

export default Hero;

