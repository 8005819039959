import React from 'react'
import './giverThankYouPage.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Section from '../../components/section/section'
import { PADDING_ALL } from '../../utils/styleValues'
import utils from '../../utils/utils'
import config from '../../config'
import { resetCart } from '../../redux/cart/actions'
import { resetOrders } from '../../redux/orders/actions'
import { resetMembership } from '../../redux/membership/actions'
import './googleTag'

const { routes } = config

class GiverThankYouPage extends React.Component {
    constructor(props) {
        super(props)
        this._boundUnload = this._unload.bind(this)
    }

    _unload(e) {
        const { dispatch } = this.props
        dispatch(resetCart())
        dispatch(resetOrders())
        dispatch(resetMembership())
    }

    componentDidMount() {
        const env = this.props.location.state.conf.environment
        window.addEventListener('unload', this._boundUnload)
        if (env === 'prod') {
            //const pixelscript = document.createElement("script");
            //pixelscript.async = true;
            //pixelscript.type = 'text/javascript';
            //pixelscript.src = '/js/fbPixelScript.js';
            //const pixelnoscript = document.createElement("noscript");
            //pixelnoscript.src = '/js/fbPixelNoscript.js';
            //document.head.appendChild(pixelscript);
            //document.head.appendChild(pixelnoscript);
            const flnoscript = document.createElement('noscript')
            const flscript = document.createElement('script')
            flscript.type = 'text/javascript'
            flscript.src = '/js/flightScript.js'
            flnoscript.src = '/js/flightNoscript.js'
            document.body.appendChild(flscript)
            document.body.appendChild(flnoscript)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('unload', this._boundUnload)
    }

    _onVisitAAAClick() {
        const win = window.open(config.AAAURL, '_blank')
        win.focus()
    }

    _onClickGoToFaq(e) {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.faqPage)
    }

    render() {
        const { t, orders } = this.props
        const orderCount = Array.isArray(orders.items) ? orders.items.length : 0
        if (orderCount > 0 || window.location.href.includes('localhost')) {
            return (
                <section className="page thank-you-page">
                    <br></br>
                    <br></br>
                    <div className="text-center">
                        <div className="circle-loader load-complete">
                            <div className="checkmark draw"></div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <h3 className="text-center">
                        {' '}
                        <strong>
                            Payment Successfully Processed
                        </strong>{' '}
                    </h3>
                    <p className="text-center">
                        Thank you for purchasing and gifting a AAA Membership.
                        <br></br>
                        We’ll send you the order details and receipt via email shortly.
                        <br></br>
                        <br></br>
                        We’ll send your gifted membership and message to the gift
                        <br></br>
                        recipient(s) on the delivery date you specified.
                        <br></br>
                        <br></br>
                        We recommend that you contact the gift recipient(s) to
                        <br></br>
                        let them know you gave them a gift membership.
                        <br></br>
                        Tell them to double check for their gift in the inbox’s junk folder.
                        <br></br>
                        <br></br>
                        All gifts expire 60 days after delivery if the recipient does
                        <br></br>
                        not claim it and submit the enrollment information.
                    </p>
                </section>
            )
        } else {
            return (
                <section className="page thank-you-page">
                    <Section padding={PADDING_ALL}>
                        <h3 className="cart-page--empty-title">{t('emptyCartTitle')}</h3>
                        <p className="cart-page--empty-text">{t('emptyCartText')}</p>
                    </Section>
                </section>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        orders: state.orders,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(GiverThankYouPage)))
