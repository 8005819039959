import React from 'react';
import './maintenancePage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ALT_SIMPLE } from '../../components/btn/btn';
import utils from '../../utils/utils';
import config from '../../config';

const { routes } = config;

class MaintenancePage extends React.Component {

    constructor(props) {
        super(props);
        const dispatch = props.dispatch;
        dispatch({ type: 'DROP_SESSION_DATA' });
    }

    _onClickGoToFaq(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.faqPage);
    }

    render() {
        const { t } = this.props;
        return (
            <section className="page maintenance-page">
                {<Hero image="/images/heros/home-hero.jpg"
                    title={t('maintenancePageTitle')} />}

                <Section padding={PADDING_ALL}>
                    <p className="maintenance-page--blurb app--inset">{t('maintenancePageBlurb')}</p>
                    <p className="maintenance-page--blurb app--inset">{t('maintenancePageBlurb2')}
                        <a href={t('maintenancePageAAALink')} target="_blank">{t('maintenancePageAAALinkText')}</a>{t('maintenancePageBlurb3')}
                    </p>
                </Section>

                <Section padding={PADDING_ALL} className="alt-section">
                    <h2 className="section-heading text-center">{t('faqSectionTitle')}</h2>
                    <p className="text-center">{t('faqSectionText')}</p>
                    <Btn
                        label={t('faqSectionBtnText')}
                        onClick={this._onClickGoToFaq.bind(this)}
                        className={[SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ALT_SIMPLE]} />
                </Section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MaintenancePage)));
