import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { createSession } from 'redux-session';
import redemptionCode from './redemptionCode/reducer';
import membership from './membership/reducer';
import cart from './cart/reducer';
import orders from './orders/reducer';
import claim from './claim/reducer';
import errors from './errors/reducer';
import config from './config/reducer';

const store = createStore(combineReducers({
	redemptionCode,
	membership,
	cart,
	orders,
	claim,
	errors,
	config
}), applyMiddleware(createSession({
	ns: 'aaa.gift'
}), createLogger({
	collapsed: true
})));

export default store;

// store.subscribe(() => {
// 	console.log('subscribe', store.getState())
// });