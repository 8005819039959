export const customLoadingOverlay = {
    overlay: (base) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.5)', 
    }),
    spinner: (base) => ({
      ...base,
      width: '50px',
      height: '50px',
      '& svg circle': {
        stroke: 'rgb(2, 83, 155)',
      },
    }),
    content: (base) => ({
      ...base,
      color: 'white',
      fontSize: '12px', 
      display: 'flex'
    }),
};