import React from 'react';
import './chooseMembershipPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { setRecipientLocation, setClub, setStateName, setCityName } from '../../redux/membership/actions';
import Loading from '../../components/loading/loading';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL } from '../../utils/styleValues';
import CardMembershipType from '../../components/cardMembershipType/cardMembershipType';
import utils from '../../utils/utils';
import API from '../../utils/api';
import config from '../../config';
import { addError } from '../../redux/errors/actions';
import { COLOR_ACTION } from '../../components/btn/btn';
import { Event } from '../../components/tracking/tracking';

const { routes } = config;

class ChooseMembershipPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
        this.api = null;
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
        if (this.isProductPreviewModeEnabled) {
            this._setPreviewProductDataBrigde()
        } else {
            this._fetchData();
        }
    }

    componentWillUnmount() {
        if (this.isProductPreviewModeEnabled) {
            window.removeEventListener("message", this._messageHandler)
        }
    }

    _messageHandler = (m) => {
        if (!this.props || this.props.history.length > 1 || !m.data || m.data.legalDisclosure === undefined) {
            return;
        }
        const { dispatch } = this.props;
        const tempData = {
            products: [{
                name: m.data.name,
                displayName: m.data.displayName,
                cost: +m.data.cost,
                enrollmentFee: +m.data.enrollmentFee,
                shortDescription: m.data.shortDescription,
                longDescription: m.data.longDescription,
                legalDisclosure: m.data.legalDisclosure,
                entitlements: m.data.entitlements,
                towing: m.data.towing,
                mileageException: m.data.mileageException,
                battery: m.data.battery,
                flat: m.data.flat,
                lockout: m.data.lockout,
                fuel: m.data.fuel,
                costPerAssociate: m.data.costPerAssociate
            }]
        }
        const tempLocation = {
            branchId: +m.data.branchId
        }
        dispatch(setRecipientLocation(tempLocation))
        dispatch(setClub(tempData))
        dispatch(setStateName('STATE'))
        dispatch(setCityName('CITY'))
    }

    _setPreviewProductDataBrigde() {
        window.opener.postMessage('PreviewProduct', '*')
        window.addEventListener("message", this._messageHandler)
    }

    _fetchData() {
        const { t, dispatch, membership } = this.props;
        if (membership.recipientLocation) {
            this._fetchMemberships(membership.recipientLocation);
        } else {
            const location = utils.reclaimRegion(decodeURIComponent(window.location.pathname.split('/').pop()));
            if (location && location.city && location.state) {
                this.api.location(utils.formatRegion(location)).then((suggestions) => {
                    return utils.matchRegionToSuggestion(location, suggestions);
                }).then((region) => {
                    dispatch(setRecipientLocation(region));
                    return region;
                }).then((region) => {
                    this._fetchMemberships(region);
                }).catch((err) => {
                    console.warn(err);
                    this.setState({
                        error: t('genericError')
                    });
                    dispatch(addError({
                        message: t('errorMessageDefault'),
                        buttons: [{
                            label: t('errorOK'),
                            onClick: 'dismiss',
                            color: COLOR_ACTION
                        }]
                    }));
                });
            }
        }
    }

    _fetchMemberships(region) {
        const { t, dispatch } = this.props;
        if (region) {
            this.api.branch(region.branchId).then((club) => {
                this.setState({
                    error: null
                });
                dispatch(setClub(club.data));
            }).catch((err) => {
                this.setState({
                    error: t('genericError')
                });
            });
        }
    }

    _showMembershipOptions(clubPlans) {
        const { t, membership } = this.props;
        if (Array.isArray(clubPlans) && clubPlans.length) {
            return clubPlans.map((v, k) => {
                const defaultOpen = (!!config.defaultOpenPlan &&
                    v.name.toLowerCase() === config.defaultOpenPlan.toLowerCase());
                return (<CardMembershipType membershipType={v} key={k} defaultOpen={defaultOpen} />);
            });
        } else {
            console.log("Register GA Event" + ' ' + this.props.membership.selectedClub.name + ' ' + this.props.membership.cityName + ' ' + this.props.membership.stateName)
            Event("No product ", `${this.props.membership.selectedClub.name}`, this.props.membership.cityName + ', ' + this.props.membership.stateName);
            return (<p dangerouslySetInnerHTML={{ __html: `${t('emptyClubPlans')}${t('emptyClubPreface')} ${membership.cityName}, ${membership.stateName}, ${t('emptyClubAfter')}` }}></p>
            );
        }
    }

    _onTryAnotherRegion() {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.locationSelectPage);
    }

    _onTryAgain() {
        this.setState({
            error: null
        });
        this._fetchData();
    }

    _renderBody() {
        const { t, membership } = this.props;
        const { selectedClub } = membership;
        return (
            <section className="page choose-membership-page">
                <Hero image="/images/heros/membership-select-hero.jpg"
                    title={t('chooseMembershipPageTitle')} />


                <Section padding={PADDING_ALL} className="app--centered"
                    itemScope={null}
                    itemType="http://schema.org/ItemList">
                    <link itemProp="url" href={window.location.href} />
                    <p className="choose-membership-page--location">{t('availableOptions')}<strong>{membership.cityName}, {membership.stateName}</strong></p>
                    {this._showMembershipOptions(selectedClub.plans)}
                    <div className="card disclosure-legend" style={{ visibility: selectedClub.plans.filter(x => x.productLevelId > 1).length > 0 ? 'visible' : 'hidden' }}><strong>NOTE:</strong>&nbsp; For Plus and Premier, most AAA Clubs limit roadside service benefits to &nbsp; <u>Basic/Classic</u> level during the first week (5-7 days) of the enrolled membership. </div>
                </Section>
            </section>
        );
    }

    _renderInactiveRegion() {
        const { t } = this.props;
        return utils.renderGenericPage('/images/heros/membership-select-hero.jpg',
            t('chooseMembershipPageTitle'),
            t('inactiveRegionTitle'),
            t('inactiveRegionText'),
            t('tryAnotherLocation'),
            this._onTryAnotherRegion.bind(this));
    }

    _renderLoading() {
        return (<section className="page"><Loading /></section>)
    }

    _renderError() {
        const { t } = this.props;
        return utils.renderGenericPage('/images/heros/membership-select-hero.jpg',
            t('chooseMembershipPageTitle'),
            t('somethingWentWrong'),
            t('genericError'),
            t('tryAgain'),
            this._onTryAgain.bind(this));
    }

    render() {
        const { membership } = this.props;
        const { recipientLocation, selectedClub } = membership;
        const { error } = this.state;
        if (error) {
            return this._renderError();
        } else if (recipientLocation) {
            if (selectedClub && selectedClub.plans) {
                return this._renderBody();
            } else {
                return this._renderLoading();
            }
        } else {
            return this._renderLoading();
        }
    }

    get isProductPreviewModeEnabled() {
        return this.props.conf.productPreviewModeEnabled === true
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ChooseMembershipPage)));

