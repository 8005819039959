import React from 'react'
import './homePage.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Hero from '../../components/hero/hero'
import Section from '../../components/section/section'
import { PADDING_ALL } from '../../utils/styleValues'
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ALT_SIMPLE
} from '../../components/btn/btn'
import IconTextBox from '../../components/iconTextBox/iconTextBox'
import utils from '../../utils/utils'
import config from '../../config'

const { routes } = config

class HomePage extends React.Component {
    constructor(props) {
        super(props)
        const dispatch = props.dispatch
        dispatch({ type: 'DROP_SESSION_DATA' })
    }

    _onClickGetStarted(e) {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.locationSelectPage)
    }

    _onClickGoToFaq(e) {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.faqPage)
    }

    render() {
        const { t } = this.props
        return (
            <section className="page home-page">
                <Hero image="/images/heros/home-hero.jpg" title={t('homePageTitle')} />

                <Section padding={PADDING_ALL}>
                    <p className="home-page--blurb app--inset">{t('homePageBlurb')}</p>
                    <Btn
                        label={t('getStarted')}
                        onClick={this._onClickGetStarted.bind(this)}
                        className={[
                            'btn-get-started btn-action',
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK
                        ]}
                    />
                </Section>

                <Section padding={PADDING_ALL} className="home-page--info">
                    <h2 className="section-heading text-center">{t('howItWorks')}</h2>
                    <IconTextBox
                        icon="/images/icons/location-icon.svg"
                        text={t('homeIconBox1Text')}
                    />
                    <IconTextBox
                        icon="/images/icons/gift-icon.svg"
                        text={t('homeIconBox2Text')}
                    />
                    <IconTextBox
                        icon="/images/icons/edit-icon.svg"
                        text={t('homeIconBox3Text')}
                    />
                </Section>

                <Section padding={PADDING_ALL} className="alt-section">
                    <h2 className="section-heading text-center">
                        {t('faqSectionTitle')}
                    </h2>
                    <p className="text-center">{t('faqSectionText')}</p>
                    <Btn
                        label={t('faqSectionBtnText')}
                        onClick={this._onClickGoToFaq.bind(this)}
                        className={[SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ALT_SIMPLE]}
                    />
                </Section>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(HomePage)))
