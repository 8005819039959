import React from 'react';
import './enrollmentRedemptionCodePage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Section from '../../components/section/section';
import Hero from '../../components/hero/hero';
import { PADDING_ALL } from '../../utils/styleValues';
import utils from '../../utils/utils';
import config from '../../config';
import EnrollmentRedemptionCodeForm from '../../components/enrollmentRedemptionCodeForm/enrollmentRedemptionCodeForm';
import { setEnrollmentRedemptionCode } from '../../redux/redemptionCode/actions';

const { routes } = config;

class EnrollmentRedemptionCodePage extends React.Component {

    componentDidMount() {
        const { redemptionCode, history } = this.props;
        if (!redemptionCode.redemptionCode) {
            history.replace(routes.redemptionCode);
        }

        this.updateScroll();
    }

    _onValueChange(prop, value) {
        const { dispatch } = this.props;
        dispatch(setEnrollmentRedemptionCode(prop, value));
    }

    updateScroll() {
        document.getElementById('logoAAA').scrollIntoView();
        utils.scrollTop();
    }

    _onSubmit() {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.reviewRedemptionCodeEnrollment);
    }

    render() {
        const { t, redemptionCode } = this.props;
        return (
            <section className="page enrollment-page">
                <Hero title={t('enrollmentPageTitle')} />
                <Section padding={PADDING_ALL}>
                    <EnrollmentRedemptionCodeForm data={redemptionCode}
                        onValueChange={this._onValueChange.bind(this)}
                        onSubmit={this._onSubmit.bind(this)} />
                </Section>

            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        redemptionCode: state.redemptionCode
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(EnrollmentRedemptionCodePage)));

