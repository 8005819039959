import React, { Component } from 'react'
import Select from 'react-select'
import './dropdown.css'

export class Dropdown extends Component {
  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  componentDidUpdate() {
    const { value } = this.props

    if (value === -1) {
      this.clearValue()
    }
  }


  render() {
    const { placeholder, required, error, onChange, options, value } = this.props

    const requiredClass = required ? 'text-input--required' : ''
    const customStyles = {
      control: (provided, state) => {
        const border = () => {
          if (error) {
            return '1px solid #d52b1e'
          }
          if (state.isFocused) {
            return '0.5px solid #3d89cc'
          }
          return '1px solid #bcbcbc'
        }

        return {
          ...provided,
          border: border(),
          borderRadius: '0px',
          marginTop: '-2px',
          marginBottom: '12px',
          backgroundColor: '#fafafa',
          fontWeight: '400',
          fontFamily: 'Arial !important',
        }
      },
      option: (provided, state) => {
        return {
          ...provided,
          backgroundColor: state.isSelected ? '#3d89cc' : 'transparent',
          fontFamily: 'Arial !important',
        }
      },
      singleValue: (provided) => {
        return {
          ...provided,
          color: '#333',
          fontSize: '1rem',
          lineHeight: '1.2',
          fontFamily: 'Arial !important',
        }
      },
    }

    return (
      <div>
        <label className={`text-input--label ${requiredClass} ${error ? 'text-input--error' : ''}`}>
          {placeholder}
        </label>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          defaultValue={value}
          styles={customStyles}
          options={options}
          placeholder=""
          required={required}
          isSearchable={false}
          onChange={onChange}
        />
      </div>
    )
  }
}

export default Dropdown
