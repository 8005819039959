export const SET_CONFIG_ACTION = 'config.SET_CONFIG_ACTION';
export const SET_CONFIG_STRIPE_ACTION = 'config.SET_CONFIG_STRIPE_ACTION';
export const SET_ENVIROMENT = 'config.SET_ENVIROMENT';

export function setConfig(conf) {
	return {
		type: SET_CONFIG_ACTION,
		value: conf
	};
}

export function setStripeConf(stripeConf) {
	return {
		type: SET_CONFIG_STRIPE_ACTION,
		value: stripeConf
	};
}

export function setEnviroment(env) {
	return {
		type: SET_ENVIROMENT,
		value: env
	}
}