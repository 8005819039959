import React from 'react';
import './textInput.css';
import InfiniteCalendar from '@appannie/react-infinite-calendar';
import '@appannie/react-infinite-calendar/styles.css'; // only needs to be imported once
import { runValidators } from '../../utils/validators';
import utils from '../../utils/utils';
import keys from '../../utils/keys';
import config from '../../config';

class TextInput extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showDatePicker: false,
			hilight: null
		};
		this._inputRef = null;
		this._calRef = null;
		this._keyHandler = this._onKeyDown.bind(this);
		this._calKeyPress = this._onCalKeyPress.bind(this);
		this._minDate = new Date();
		this._maxDate = new Date(this._minDate.getFullYear(),
			this._minDate.getMonth(),
			this._minDate.getDate() + config.maxFutureDaysSendGift);
		this._onInputRefBound = this._onInputRef.bind(this);
		this._onSuggestionsRefBound = this._onSuggestionsRef.bind(this);
		this._onCalRefBound = this._onCalRef.bind(this);
	}

	componentDidMount() {
		const { type } = this.props;
		if (this._inputRef && type !== 'date') {
			this._inputRef.addEventListener('keydown', this._keyHandler);
		}
	}

	componentWillUnmount() {
		const { type } = this.props;
		if (this._inputRef && type !== 'date') {
			this._inputRef.removeEventListener('keydown', this._keyHandler);
		}
	}

	_getHilightIndex(hilight, suggestions, map) {
		let index = -1;
		for (let i = 0, ii = suggestions.length; i < ii; i++) {
			if (map(suggestions[i]).name === hilight.name) {
				index = i;
				break;
			}
		}
		return index;
	}

	_onKeyDown(e) {
		// TODO: tab to suggestions...
		const { suggestions, suggestionsMap, onSuggestionSelect, onHideSuggestions } = this.props;
		const { hilight } = this.state;
		const operatorKeyPressed = e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
		if (!operatorKeyPressed &&
			Array.isArray(suggestions) &&
			suggestions.length &&
			utils.isFunction(onSuggestionSelect) &&
			utils.isFunction(suggestionsMap)) {

			switch (e.keyCode) {
				case keys.KEY_TAB:
					// TODO tab through list items
					break;
				case keys.KEY_ESCAPE:
					if (utils.isFunction(onHideSuggestions)) {
						e.preventDefault();
						onHideSuggestions();
					}
					break;
				case keys.KEY_UP:
					e.preventDefault();
					if (!hilight) {
						this.setState({
							hilight: suggestionsMap(suggestions[suggestions.length - 1])
						});
					} else {
						const indexUp = this._getHilightIndex(hilight, suggestions, suggestionsMap);
						// if (indexUp !== -1) {
						if (indexUp - 1 > -1) {
							this.setState({
								hilight: suggestionsMap(suggestions[indexUp - 1])
							});
						} else {
							this.setState({
								hilight: suggestionsMap(suggestions[suggestions.length - 1])
							});
						}
						// }
					}
					break;
				case keys.KEY_DOWN:
					e.preventDefault();
					if (!hilight) {
						this.setState({
							hilight: suggestionsMap(suggestions[0])
						});
					} else {
						// let indexDown = -1;
						const indexDown = this._getHilightIndex(hilight, suggestions, suggestionsMap);
						// if (indexDown !== -1) {
						if (indexDown + 1 < suggestions.length) {
							this.setState({
								hilight: suggestionsMap(suggestions[indexDown + 1])
							});
						} else {
							this.setState({
								hilight: suggestionsMap(suggestions[0])
							});
						}
						// }
					}
					break;
				case keys.KEY_ENTER:
					e.preventDefault();
					if (hilight) {
						const indexEnter = this._getHilightIndex(hilight, suggestions, suggestionsMap);
						if (indexEnter !== -1) {
							onSuggestionSelect(suggestions[indexEnter]);
						}
					}
					break;
				default:
					break;
			}
		}
	}

	_formatPhone(e, val) {
		const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		val = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		return val;
	}

	_onChange(e) {
		const { onChange } = this.props;
		let val = e.target.value;
		if (e.target.id.includes('phone')) {
			val = this._formatPhone(e,val);
		}
		const maxLength = e.target.maxLength;
		val = maxLength && maxLength !== -1 && val.length > maxLength ? val.slice(0, maxLength) : val;
		if (utils.isFunction(onChange)) {
			onChange(val);
		}
		setTimeout(() => {
			this._validate(val);
		}, 10);
	}

	_onBlur(e) {
		const { onBlur } = this.props
		const val = e.target.value;
		if (utils.isFunction(onBlur)) {
			onBlur(val);
			setTimeout(() => {
				this._validate(val);
			}, 10);
		}
	}

	_validate(val) {
		const { validators, onValidationChange } = this.props;
		if (validators && utils.isFunction(onValidationChange)) {
			onValidationChange(runValidators(val, validators));
		}
	}

	_onSelectItem(region) {
		const { onSuggestionSelect } = this.props;
		if (region && utils.isFunction(onSuggestionSelect)) {
			onSuggestionSelect(region);
			this.setState({
				hilight: null
			});
		}
	}

	_listSuggestions(suggestions) {
		const { value, suggestionsMap } = this.props;
		const { hilight } = this.state;
		if (Array.isArray(suggestions)) {
			return suggestions.map((v, k) => {
				const mapped = utils.isFunction(suggestionsMap) ? suggestionsMap(v) : v;
				const isSelected = mapped.name.toLowerCase() === value.toLowerCase();
				const selected = isSelected ? 'selected' : '';
				const toHighlight = hilight ? hilight.name : null;
				const highlighted = toHighlight && mapped.name === toHighlight ? 'hilight' : '';
				if (mapped) {
					return (
						<li key={mapped.id}
							role="option"
							aria-selected={isSelected}
							className={`text-input--suggestion-item ${selected} ${highlighted}`}
							onClick={(e) => {
								this._onSelectItem(v);
							}}>{mapped.name}</li>
					);
				} else {
					return null;
				}
			});
		}
		return null;
	}

	_showSuggestions(suggestions) {
		const { id } = this.props;
		if (Array.isArray(suggestions) && suggestions.length > 0) {
			return (
				<ul className="text-input--suggestions"
					aria-labelledby={`${id}_label`}
					role="listbox"
					id={`${id}_suggestions`}
					ref={this._onSuggestionsRefBound}>
					{this._listSuggestions(suggestions)}
				</ul>
			);
		}
		return null;
	}

	_onSuggestionsRef(suggestionsRef) {
		if (suggestionsRef) {
			// TODO: make fit on screen & scroll if too long
			if (this._inputRef) {
				this._inputRef.scrollIntoView();
			}
			// const rect = suggestionsRef.getBoundingClientRect();
			// console.log(rect);
		}
	}

	_onInputRef(ref) {
		const { onInputRef } = this.props;
		if (ref) {
			this._inputRef = ref;
		}
		if (utils.isFunction(onInputRef)) {
			onInputRef(ref);
		}
	}

	_onCalRef(ref) {
		this._calRef = ref;
		if (ref) {
			window.addEventListener('keypress', this._calKeyPress);
		} else {
			window.removeEventListener('keypress', this._calKeyPress);
		}
	}

	_modifyDateValue(date, delta) {
		let newDate = new Date(date.getFullYear(),
			date.getMonth(),
			date.getDate() + delta);
		if (newDate < this._minDate) {
			newDate = this._minDate;
		} else if (newDate > this._maxDate) {
			newDate = this._maxDate;
		}
		return newDate;
	}

	_onCalKeyPress(e) {
		const { value, nextTabItem } = this.props;
		const currentDate = utils.reclaimDate(value);
		let newDate = null;
		if (this._calRef && e) {
			switch (e.keyCode) {
				case keys.KEY_LEFT:
					newDate = this._modifyDateValue(currentDate, -1)
					this._onDateSelect(newDate);
					e.preventDefault();
					break;
				case keys.KEY_UP:
					newDate = this._modifyDateValue(currentDate, -7)
					this._onDateSelect(newDate);
					e.preventDefault();
					break;
				case keys.KEY_RIGHT:
					newDate = this._modifyDateValue(currentDate, 1)
					this._onDateSelect(newDate);
					e.preventDefault();
					break;
				case keys.KEY_DOWN:
					newDate = this._modifyDateValue(currentDate, 7)
					this._onDateSelect(newDate);
					e.preventDefault();
					break;
				case keys.KEY_ENTER:
					newDate = currentDate
					this._onDateSelect(newDate);
					this.setState({
						showDatePicker: false
					});
					e.preventDefault();
					break;
				case keys.KEY_ESCAPE:
					this.setState({
						showDatePicker: false
					});
					e.preventDefault();
					break;
				case keys.KEY_TAB:
					this.setState({
						showDatePicker: false
					});
					e.preventDefault();
					if (nextTabItem && nextTabItem.focus) {
						nextTabItem.focus();
					}
					break;
				default:
					break;
			}
			if (newDate) {
				const querySelector = `.Cal__Day__root[data-date="${utils.formatDate(newDate)}"]`;
				const dayElement = document.querySelector(querySelector);
				if (dayElement) {
					dayElement.scrollIntoView();
				}
			}
		}
	}

	_toggleDatePicker(e) {
		// if not want to close on select...
		// const { showDatePicker } = this.state;
		// if (e && e.target) {
		// 	if (e.target.classList.contains('text-input--date-wrapper')) {
		// 		this.setState({
		// 			showDatePicker: false
		// 		});
		// 	}
		// } else {
		// 	this.setState({
		// 		showDatePicker: !showDatePicker
		// 	});
		// }
		const { showDatePicker } = this.state;
		this.setState({
			showDatePicker: !showDatePicker
		});
	}

	_onDateIconClick() {
		this._toggleDatePicker();
	}

	_getCalendarDimensions() {
		const winWidth = Math.min(window.innerWidth, 400);
		const winHeight = Math.min(window.innerHeight, 400);
		const margin = 32;
		if (winWidth > winHeight) {
			return [winHeight - margin, winHeight - margin];
		} else {
			return [winWidth - margin, winWidth - margin];
		}
	}

	_onDateSelect(date) {
		const { onChange } = this.props;
		if (utils.isFunction(onChange)) {
			onChange(utils.formatDate(date));

			setTimeout(() => {
				this._validate(utils.formatDate(date));
			}, 10);
		}
	}

	_getTextareaField() {
		const {
			value,
			placeholder,
			name,
			id,
			maxLength,
			required
		} = this.props;
		return (
			<textarea className="text-input--input text-input--textarea"
				ref={this._onInputRefBound}
				required={required === true}
				name={name}
				id={id}
				maxLength={maxLength}
				placeholder={placeholder || ''}
				onChange={this._onChange.bind(this)}
				value={value || ''}></textarea>
		);
	}

	_getDefaultInputField() {
		const {
			value,
			placeholder,
			name,
			id,
			maxLength,
			minLength,
			min,
			max,
			required,
			type,
			suggestions,
			pattern,
			disabled
		} = this.props;
		// const inputPattern = type === 'number' ? pattern || '^[0-9]+$' : pattern;
		const hasSugs = suggestions && suggestions.length;
		// const inputType = type === 'number' ? 'tel' : (type || 'text');
		return (
			<input type={type}
				ref={this._onInputRefBound}
				className="text-input--input"
				disabled={disabled}
				required={required === true}
				name={name}
				id={id}
				min={min}
				max={max}
				aria-autocomplete={hasSugs ? 'list' : 'none'}
				pattern={pattern}
				minLength={minLength}
				maxLength={maxLength}
				placeholder={placeholder || ''}
				onChange={this._onChange.bind(this)}
				onBlur={this._onBlur.bind(this)}
				value={value || ''} />
		);
	}

	_getDateInputField() {
		const {
			value,
			placeholder,
			name,
			id,
			required
		} = this.props;
		const { showDatePicker } = this.state;
		const dateInput = (
			<input type="text"
				ref={this._onInputRefBound}
				className="text-input--input text-input--date"
				required={required === true}
				name={name}
				id={id}
				pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
				minLength={10}
				maxLength={10}
				//THIS ON FOCUS PREVENTS FROM TYPING DATE MANUALLY
				// onFocus={(e) => {
				// 	if (e) {
				// 		e.preventDefault();
				// 	}
				// 	this._onDateIconClick();
				// }}
				placeholder={placeholder || ''}
				onChange={this._onChange.bind(this)}
				value={value || ''} />
		);
		const dateFromValue = value ? utils.reclaimDate(value) : new Date();
		const [width, height] = this._getCalendarDimensions();
		return (
			<div className={`text-input--input-wrapper`}>
				{dateInput}
				<img className="text-input--icon"
					src="/images/icons/calendar-icon.svg"
					alt="date picker"
					onClick={this._onDateIconClick.bind(this)} />
				{showDatePicker ? (
					<div className="text-input--date-wrapper"
						onClick={this._toggleDatePicker.bind(this)}>

						<InfiniteCalendar
							ref={this._onCalRefBound}
							width={width}
							height={height}
							selected={dateFromValue}
							min={this._minDate}
							max={this._maxDate}
							minDate={this._minDate}
							maxDate={this._maxDate}
							tabIndex={0}
							autoFocus={true}
							displayOptions={{
								showHeader: false,
								showOverlay: false,
								overscanMonthCount: 3
							}}
							onSelect={this._onDateSelect.bind(this)} />
					</div>
				) : null}
			</div>
		);
	}

	_getInputField() {
		const { type } = this.props;
		switch (type) {
			case 'textarea':
				return this._getTextareaField();
			case 'date':
				return this._getDateInputField();
			default:
				return this._getDefaultInputField();
		}
	}

	render() {
		const {
			id,
			className,
			label,
			labelOverflow,
			hideLabel,
			suggestions,
			description,
			type,
			required,
			error
		} = this.props;
		const cssClass = utils.extractClassName(className);
		const typeClass = `text-input-${(type || '')}`;
		const errorClass = error ? 'error' : '';
		const input = this._getInputField();
		const requiredClass = required ? 'text-input--required' : '';
		const hasSugs = suggestions && suggestions.length;
		const sugsId = `${id}_suggestions`;
		const labelText = labelOverflow || hideLabel ? (<span>{label}</span>) : label;
		const labelStyle = hideLabel ? { visibility: 'hidden' } : {};
		const labelClass = labelOverflow ? 'text-input--label-overflow' : ''
		return (
			<div className={`text-input ${typeClass} ${cssClass} ${errorClass}`}
				role={hasSugs ? 'combobox' : ''}
				aria-owns={sugsId}
				aria-controls={sugsId}
				aria-expanded={hasSugs}
				aria-haspopup={hasSugs ? 'listbox' : ''} >
				<label htmlFor={id}
					style={labelStyle}
					id={`${id}_label`}
					className={`text-input--label ${requiredClass} ${labelClass}`}>{labelText}</label>
				{input}
				{description ? <p className="text-input--description">{description}</p> : null}
				{this._showSuggestions(suggestions)}
			</div >
		);
	}
}

export default TextInput;

