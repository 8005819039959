import React from 'react';
import './membershipInformationPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import {
    COLOR_ACTION
} from '../../components/btn/btn';
import Hero from '../../components/hero/hero';
import Section from '../../components/section/section';
import { PADDING_ALL, PADDING_NO_BOTTOM, PADDING_NO_TOP } from '../../utils/styleValues';
import MembershipBenefits from '../../components/membershipBenefits/membershipBenefits';
import MembershipAdditionalBenefits from '../../components/membershipAdditionalBenefits/membershipAdditionalBenefits';
import utils from '../../utils/utils';
import config from '../../config';
import { addError } from '../../redux/errors/actions';

const { routes } = config;

class MembershipInformationPage extends React.Component {
    _onClickGoToFaq(e) {
        const { history } = this.props;
        utils.scrollTop();
        history.push(routes.faqPage);
    }

    _onFetchError(err) {
        const { t, dispatch } = this.props;
        console.warn(err);
        this.setState({
            error: t('genericError')
        });
        dispatch(addError({
            message: t('errorMessageDefault'),
            buttons: [{
                label: t('errorRetry'),
                onClick: 'dismiss',
                color: COLOR_ACTION
            }]
        }));
    }

    render() {
        const { t, claim } = this.props;
        // console.log('plan', plan);
        // const feesTotal = utils.calculateFeesTotalCost(plan);
        return (
            <section className="page membership-information-page">
                <Hero title={t('membershipInformationTitle')} />
                <Section padding={PADDING_ALL} className="membership-information--benefits">
                    <MembershipBenefits plan={claim.planResponse} />
                </Section>
                <Section padding={PADDING_NO_BOTTOM}>
                    <h3 className={'section--title'}>{t('membershipInformationAdditional')}</h3>
                </Section>
                <Section padding={PADDING_NO_TOP} className="membership-additional-benefits">
                    <MembershipAdditionalBenefits plan={claim.planResponse} />
                </Section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        claim: state.claim
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MembershipInformationPage)));
