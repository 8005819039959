import React from 'react';
import { connect } from 'react-redux';
import './enrollmentForm.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import TextInput from '../textInput/textInput';
import ErrorList from '../errorList/errorList';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION, COLOR_ALT_SIMPLE } from '../btn/btn';
import validators, { runValidators } from '../../utils/validators';
import utils, { ZIP_REGEX } from '../../utils/utils';
import API from '../../utils/api';
import Hero from '../hero/hero';
import config from '../../config';
import {
    setLocationSuggestions,
    resetMembership
} from '../../redux/membership/actions';
import {
    setState,
    setAssociateProp
} from '../../redux/claim/actions';
import { addError } from '../../redux/errors/actions';

const { productLevel } = config;

class EnrollmentForm extends React.Component {
    constructor(props) {
        super(props);
        const { data, t } = props;
        this.email = React.createRef();
        this.emailConfirm = React.createRef();
        this.validators = {
            firstName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('firstNameLabel')} ${t('isRequired')}`
            }],
            lastName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('lastNameLabel')} ${t('isRequired')}`
            }],
            email: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('address1Label')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('address1Label')} ${t('isInvalid')}`
            },
            {
                validator: this._compareEmails.bind(this),
                message: `${t('address1Label')} ${t('and')} ${t('address2Label')} ${t('mustMatch')}`
            }],
            emailConfirm: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('address2Label')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('address2Label')} ${t('isInvalid')}`
            }],
            city: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('cityLabel')} ${t('isRequired')}`
            }],
            state: [{
                validator: validators.isStateNullOrEmpty,
                message: `${t('stateLabel')} ${t('isRequired')}`
            }],
            zipCode: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('enrollmentZipCodeLabel')} ${t('isRequired')}`
            }, {
                validator: validators.matchesPattern(ZIP_REGEX),
                message: `${t('zipCodeFormat')}`
            }],
            birthYear: [{
                validator: validators.isValidYearInPast,
                message: `${t('birthYearLabel')} ${t('isRequired')}`
            }, {
                validator: validators.isLength(4),
                message: `${t('birthYearLabel')} ${t('isInvalid')}`
            }],
            phoneNumber: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('phoneNumberLabel')} ${t('isRequired')}`
            }, {
                validator: validators.validTelephone,
                message: `${t('phoneNumberLabel')} ${t('digitsLeft')}`
            }],
            address: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('addressLabel')} ${t('isRequired')}`
            }]
        };
        const { planResponse } = data;
        const productLevelName = planResponse.productLevelName;

        this.state = {
            hasSubmittedForm: false,
            emailsEqualError: false,
            productLevelName: productLevelName,
            firstName: data.firstName,
            middleInitial: data.middleInitial,
            lastName: data.lastName,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            birthYear: data.birthYear,
            phoneNumber: data.phoneNumber,
            address: data.address,
            membershipNumber: data.membershipNumber,
            associates: data.associates,
            stateProvinces: null,
            pendingEnrollmentState: "",
            stateSuggestions: "",
            error: null,
            hasSearched: false,
            firstNameError: runValidators(data.firstName, this.validators.firstName),
            lastNameError: runValidators(data.lastName, this.validators.lastName),
            emailMatchError: runValidators(data.address1, this.validators.email),
            emailConfirmMatchError: runValidators(data.address2, this.validators.emailConfirm),
            cityError: runValidators(data.city, this.validators.city),
            stateError: runValidators(data.state, this.validators.state),
            zipCodeError: runValidators(data.zipCode, this.validators.zipCode),
            birthYearError: runValidators(data.birthYear, this.validators.birthYear),
            phoneNumberError: runValidators(data.phoneNumber, this.validators.phoneNumber),
            addressError: runValidators(data.address, this.validators.address),
            listErrors: []
        }
        this.api = null;
        this.previousValue = '';
        this.isClearing = false;

        const { associates } = this.state;

        associates.map((x, index) => {
            x.recipientAssociateId = index + 1;
        })

    }

    componentDidMount() {
        const { dispatch, conf } = this.props;
        this.api = new API(conf);
        dispatch(resetMembership());
        dispatch(setLocationSuggestions(null));
    }

    _hasError() {
        const associateErrors = this.getAssociateErrors()
        const {
            firstNameError,
            lastNameError,
            emailMatchError,
            emailConfirmMatchError,
            cityError,
            stateError,
            zipCodeError,
            birthYearError,
            phoneNumberError,
            addressError
        } = this.state;
        return firstNameError ||
            lastNameError ||
            emailMatchError ||
            emailConfirmMatchError ||
            cityError ||
            stateError ||
            zipCodeError ||
            birthYearError ||
            phoneNumberError ||
            addressError ||
            associateErrors;
    }

    getAssociateErrors() {
        const { associates } = this.state;
        let result = false;

        associates.map((x) => {
            if (!x.firstName || x.firstName.trim().length === 0) {
                result = true;
            }
            if (!x.lastName || x.lastName.trim().length === 0) {
                result = true;
            }
            if (!x.birthYear || x.birthYear.trim().length !== 4) {
                result = true;
            }
        })

        return result;
    }

    _listErrors() {
        const {
            firstNameError,
            lastNameError,
            emailMatchError,
            emailConfirmMatchError,
            cityError,
            stateError,
            zipCodeError,
            birthYearError,
            phoneNumberError,
            addressError
        } = this.state;
        if (this._hasError()) {
            const list = [];
            if (firstNameError) {
                list.push({
                    key: 'firstNameError',
                    msg: firstNameError
                });
            }
            if (lastNameError) {
                list.push({
                    key: 'lastNameError',
                    msg: lastNameError
                });
            }
            if (emailMatchError) {
                list.push({
                    key: 'emailMatchError',
                    msg: emailMatchError
                });
            }
            if (emailConfirmMatchError) {
                list.push({
                    key: 'emailConfirmMatchError',
                    msg: emailConfirmMatchError
                });
            }
            if (cityError) {
                list.push({
                    key: 'cityError',
                    msg: cityError
                });
            }
            if (stateError) {
                list.push({
                    key: 'stateError',
                    msg: stateError
                });
            }
            if (zipCodeError) {
                list.push({
                    key: 'zipCodeError',
                    msg: zipCodeError
                });
            }
            if (birthYearError) {
                list.push({
                    key: 'birthYearError',
                    msg: birthYearError
                });
            }
            if (phoneNumberError) {
                list.push({
                    key: 'phoneNumberError',
                    msg: phoneNumberError
                });
            }
            if (addressError) {
                list.push({
                    key: 'addressError',
                    msg: addressError
                });
            }
            return list;
        }
        return [];
    }

    _onCancelForm() {
        const { onCancel } = this.props;
        if (utils.isFunction(onCancel)) {
            onCancel();
        }
    }

    _onSubmitForm(e) {
        const { onSubmit } = this.props;
        const {
            firstName,
            middleInitial,
            lastName,
            address1,
            address2,
            city,
            state,
            stateName,
            zipCode,
            birthYear,
            phoneNumber,
            membershipNumber,
            address
        } = this.state;
        const messages = this._listErrors();
        e.preventDefault();
        this.setState({
            listErrors: messages,
            hasSubmittedForm: true
        });
        if (!this._hasError() && utils.isFunction(onSubmit)) {
            onSubmit({
                firstName,
                middleInitial,
                lastName,
                address1,
                address2,
                city,
                state,
                stateName,
                zipCode,
                birthYear,
                phoneNumber,
                membershipNumber,
                address
            });
        }
    }

    renderStateProvinces() {
        return this.state.stateProvinces.map((stateProvince, index) => {
            if (this.state.state === stateProvince.state) {
                return (<option selected="selected" id={stateProvince.state} key={index}>{stateProvince.fullName}</option>);
            } else {
                return (<option id={stateProvince.state} key={index}>{stateProvince.fullName}</option>);
            }
        });
    }

    _compareEmails() {
        if (!this.state) {
            return true;
        }
        const address1 = this.state ? this.state.address1 : null;
        const address2 = this.state ? this.state.address2 : null;
        return (address1 || address2) && ((address1 + '').toLowerCase() === (address2 + '').toLowerCase());
    }

    _change(prop) {
        const { onValueChange, t } = this.props;
        return (val) => {
            if (prop === 'phoneNumber') {
                const input = document.getElementById('enrollment_phone_number');
                const start = input.selectionStart;
                this.setState({
                    [prop]: val
                }, () => {
                    const startModified = input.selectionStart;
                    if (start === 5 || (start === 4 && startModified >= 8)) {
                        input.setSelectionRange(start + 2, start + 2)
                    } else if (start === 9 || start === 10 || start === 6 || (start === 1 && startModified >= 7)) {
                        input.setSelectionRange(start + 1, start + 1)
                    } else if (start === 4 && startModified === 7) {
                        input.setSelectionRange(start + 3, start + 3)
                    } else {
                        input.setSelectionRange(start, start)
                    }
                });
            } else {
                if (prop === 'address1' || prop === 'address2') {
                    val = val.trim();
                }
                this.setState({
                    [prop]: val
                }, () => {
                    if (prop === 'address2') {
                        this.email.current._validate(this.email.current._inputRef.value);
                    }
                    if (this.state.emailMatchError === `${t('address1Label')} ${t('and')} ${t('address2Label')} ${t('mustMatch')}`) {
                        this.setState({
                            emailsEqualError: true
                        })
                    } else {
                        this.setState({
                            emailsEqualError: false
                        })
                    }
                });
            }
            if (utils.isFunction(onValueChange) && !/(Form|Error)$/.test(prop)) {
                onValueChange(prop, val);
            }
        };
    }

    _changeAss(e, id, attribute) {
        let item = null;
        const { dispatch } = this.props;
        const associates = [...this.state.associates];

        associates.map((x, i) => {
            if (x.recipientAssociateId === id) {
                item = { ...x }
            }
        })

        if (attribute === 'firstName') {
            item.firstName = e;
        }

        if (attribute === 'middleInitial') {
            item.middleInitial = e;
        }

        if (attribute === 'lastName') {
            item.lastName = e;
        }

        if (attribute === 'birthYear') {
            item.birthYear = e;
        }

        for (let i = 0; i < associates.length; i++) {
            if (associates[i].recipientAssociateId === id) {
                associates[i] = item;
            }
        }

        this.setState({ associates });

        if (attribute === 'firstName') {
            dispatch(setAssociateProp({ id: id, field: { firstName: e } }));
        }

        if (attribute === 'middleInitial') {
            dispatch(setAssociateProp({ id: id, field: { middleInitial: e } }));
        }

        if (attribute === 'lastName') {
            dispatch(setAssociateProp({ id: id, field: { lastName: e } }));
        }

        if (attribute === 'birthYear') {
            dispatch(setAssociateProp({ id: id, field: { birthYear: e } }));
        }
    }

    _onStateChange(val) {
        this.setState({ pendingEnrollmentState: val });
        if (!val.length) {
            this.setState({ stateSuggestions: null });
            this.isClearing = true;
        } else if ((val && val.length >= 1) || (this.previousValue &&
            this.previousValue.length > val.length ** val.length)) {
            this.isClearing = false;
            if (this.state.state) {
                this.setState({ state: null });
            }
            this._requestState(val);
        }
        this.previousValue = val;
    }

    _requestState(val) {
        const { dispatch, t } = this.props;
        this.api.state(val).then((suggestions) => {
            if (Array.isArray(suggestions.data) && !this.isClearing) {
                this.setState({ stateSuggestions: suggestions.data })
            }
            this.setState({
                error: null,
                hasSearched: true
            });
        }).catch((err) => {
            console.warn(err);
            this.setState({
                error: t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _clearSuggestions() {
        const { dispatch } = this.props;
        dispatch(setLocationSuggestions(null));
    }

    _onStateSelect(region) {
        const { dispatch } = this.props;
        if (region) {
            dispatch(setState(region));
            this.setState({ stateSuggestions: null, state: region, pendingEnrollmentState: region.fullName, stateName: region.fullName, stateError: undefined });
        }
    }

    _mapStateSuggestions(suggestion) {
        if (suggestion && suggestion.stateProvinceId) {
            return {
                name: suggestion.fullName,
                stateInitials: suggestion.state,
                id: suggestion.stateProvinceId
            };
        } else {
            return null;
        }
    }

    renderAssociates() {
        const { t } = this.props;
        const { associates, hasSubmittedForm } = this.state;
        return (
            associates.map((ass) => {
                return (
                    <div id={ass.id} className="enrollment-form--content">
                        <div className="enrollment-form--form-group" style={{ marginRight: "0.8rem" }}>
                            <TextInput className="enrollment-form--ass-first-name"
                                value={ass.firstName}
                                onChange={(e) => {
                                    this._changeAss(e, ass.recipientAssociateId, 'firstName');
                                }}
                                required={true}
                                maxLength={30}
                                label={t('firstNameLabel')}
                                name="associate_first_name"
                                id="associate_first_name"
                                error={hasSubmittedForm && (!ass.firstName || ass.firstName.trim().length === 0)} />

                            <TextInput className="enrollment-form--middle-initial"
                                value={ass.middleInitial}
                                onChange={(e) => {
                                    this._changeAss(e, ass.recipientAssociateId, 'middleInitial');
                                }}
                                required={false}
                                label={t('middleInitialLabel')}
                                maxLength={1}
                                name="associate_middle_initial"
                                id="associate_middle_initial" />
                        </div>
                        <div className="enrollment-form--form-group">
                            <TextInput className="enrollment-form--ass-last-name"
                                value={ass.lastName}
                                onChange={(e) => {
                                    this._changeAss(e, ass.recipientAssociateId, 'lastName');
                                }}
                                required={true}
                                maxLength={30}
                                label={t('lastNameLabel')}
                                name="associate_last_name"
                                id="associate_last_name"
                                error={hasSubmittedForm && (!ass.lastName || ass.lastName.trim().length === 0)}
                            />
                            <TextInput className="enrollment-form--ass-birth-year"
                                type="number"
                                value={ass.birthYear}
                                onChange={(e) => {
                                    this._changeAss(e, ass.recipientAssociateId, 'birthYear');
                                }}
                                required={true}
                                label={t('birthYearLabel')}
                                labelOverflow={true}
                                maxLength={4}
                                placeholder={t('birthYearPlaceholder')}
                                name="associate_birth_year"
                                id="associate_birth_year"
                                error={hasSubmittedForm && (!ass.birthYear || ass.birthYear.trim().length === 0)}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    renderDisclosure(t, productLevelName) {
        if (productLevelName == productLevel.plus || productLevelName == productLevel.plusRV ||
            productLevelName == productLevel.premier || productLevelName == productLevel.premierRV) {
            return <p className='enrollment-form-disclosure' dangerouslySetInnerHTML={{ __html: t('pinDisclosureForPlusAndPremier') }}></p>
        }
    }

    render() {
        const {
            t,
            saveButtonLabel,
            cancelButtonLabel,
            onCancel
        } = this.props;
        const hasCancelButton = utils.isFunction(onCancel);
        const btnsCssClass = hasCancelButton ? 'enrollment-form--btns-multi' : 'enrollment-form--btns-single';
        const {
            hasSubmittedForm,
            firstName,
            middleInitial,
            lastName,
            address1,
            address2,
            city,
            state,
            zipCode,
            birthYear,
            phoneNumber,
            address,
            membershipNumber,
            firstNameError,
            lastNameError,
            emailMatchError,
            emailConfirmMatchError,
            emailsEqualError,
            cityError,
            zipCodeError,
            birthYearError,
            phoneNumberError,
            addressError,
            listErrors,
            stateError,
            associates,
            productLevelName
        } = this.state;
        return (
            <form action=""
                className="enrollment-form"
                name="enrollment_form"
                encType="multipart/form-data"
                method="post"
                autoComplete="off">

                <p className="required-label">* {t('requiredField')}</p>
                <ErrorList heading={t('fixMessage')} list={listErrors} />

                <div className="enrollment-form--content">
                    <div className="enrollment-form--form-group">
                        <TextInput className="enrollment-form--first-name"
                            value={firstName}
                            error={hasSubmittedForm && firstNameError}
                            required={true}
                            maxLength={30}
                            onChange={this._change('firstName').bind(this)}
                            label={t('firstNameLabel')}
                            name="enrollment_first_name"
                            id="enrollment_first_name"
                            validators={this.validators.firstName}
                            onValidationChange={this._change('firstNameError').bind(this)} />

                        <TextInput className="enrollment-form--middle-initial"
                            value={middleInitial}
                            required={false}
                            error={hasSubmittedForm && false}
                            onChange={this._change('middleInitial').bind(this)}
                            label={t('middleInitialLabel')}
                            maxLength={1}
                            name="enrollment_middle_initial"
                            id="enrollment_middle_initial" />
                    </div>
                    <TextInput className="enrollment-form--last-name"
                        value={lastName}
                        error={hasSubmittedForm && lastNameError}
                        required={true}
                        maxLength={30}
                        onChange={this._change('lastName').bind(this)}
                        label={t('lastNameLabel')}
                        name="enrollment_last_name"
                        id="enrollment_last_name"
                        validators={this.validators.lastName}
                        onValidationChange={this._change('lastNameError').bind(this)} />
                </div>

                <div className="enrollment-form--content">
                    <TextInput className="enrollment-form--address-1"
                        value={address1}
                        maxLength={256}
                        error={hasSubmittedForm && emailMatchError}
                        required={true}
                        onChange={this._change('address1').bind(this)}
                        label={t('address1Label')}
                        name="enrollment_address1"
                        id="enrollment_address1"
                        ref={this.email}
                        validators={this.validators.email}
                        onValidationChange={this._change('emailMatchError').bind(this)} />

                    <TextInput className="enrollment-form--address-2"
                        value={address2}
                        error={hasSubmittedForm && (emailConfirmMatchError || emailsEqualError)}
                        required={true}
                        onChange={this._change('address2').bind(this)}
                        label={t('address2Label')}
                        name="enrollment_address2"
                        id="enrollment_address2"
                        ref={this.emailConfirm}
                        validators={this.validators.emailConfirm}
                        onValidationChange={this._change('emailConfirmMatchError').bind(this)} />
                </div>
                <div className="enrollment-form--content">
                    <TextInput className="enrollment-form--phone-number"
                        maxLength={20}
                        value={phoneNumber}
                        error={hasSubmittedForm && phoneNumberError}
                        required={true}
                        onChange={this._change('phoneNumber').bind(this)}
                        label={t('enrollmentPhoneNumberLabel')}
                        name="enrollment_phone_number"
                        id="enrollment_phone_number"
                        validators={this.validators.phoneNumber}
                        onValidationChange={this._change('phoneNumberError').bind(this)} />

                    <TextInput className="enrollment-form--birth-year"
                        type="number"
                        value={birthYear}
                        error={hasSubmittedForm && birthYearError}
                        required={true}
                        onChange={this._change('birthYear').bind(this)}
                        label={t('birthYearLabel')}
                        labelOverflow={true}
                        maxLength={4}
                        placeholder={t('birthYearPlaceholder')}
                        name="enrollment_birth_year"
                        id="enrollment_birth_year"
                        validators={this.validators.birthYear}
                        onValidationChange={this._change('birthYearError').bind(this)} />
                </div>

                <TextInput className="enrollment-form--address"
                    value={address}
                    maxLength={150}
                    error={hasSubmittedForm && addressError}
                    required={true}
                    onChange={this._change('address').bind(this)}
                    label={t('addressLabel')}
                    name="enrollment_address"
                    id="enrollment_address"
                    validators={this.validators.address}
                    onValidationChange={this._change('addressError').bind(this)} />

                <div className="enrollment-form--content">
                    <TextInput className="enrollment-form--city"
                        value={city}
                        maxLength={255}
                        error={hasSubmittedForm && cityError}
                        required={true}
                        onChange={this._change('city').bind(this)}
                        label={t('cityLabel')}
                        name="enrollment_city"
                        id="enrollment_city"
                        validators={this.validators.city}
                        onValidationChange={this._change('cityError').bind(this)} />

                    <TextInput className="enrollment-form--state"
                        value={state ? state.fullName : this.state.pendingEnrollmentState}
                        onChange={this._onStateChange.bind(this)}
                        onHideSuggestions={this._clearSuggestions.bind(this)}
                        onSuggestionSelect={this._onStateSelect.bind(this)}
                        label={t('stateLabel')}
                        required={true}
                        name="state"
                        id="state"
                        error={hasSubmittedForm && stateError}
                        validators={this.validators.state}
                        onValidationChange={this._change('stateError').bind(this)}
                        suggestionsMap={this._mapStateSuggestions.bind(this)}
                        suggestions={this.state.stateSuggestions} />

                    <TextInput className="enrollment-form--zip-code"
                        type="text"
                        value={zipCode}
                        error={hasSubmittedForm && zipCodeError}
                        required={true}
                        onChange={this._change('zipCode').bind(this)}
                        label={t('enrollmentZipCodeLabel')}
                        maxLength={10}
                        pattern={ZIP_REGEX}
                        name="enrollment_zip_code"
                        id="enrollment_zip_code"
                        validators={this.validators.zipCode}
                        onValidationChange={this._change('zipCodeError').bind(this)} />
                </div>
                <div className="enrollment-form--content">
                    <TextInput className="enrollment-form--membership-number"
                        value={membershipNumber}
                        type="number"
                        maxLength={30}
                        error={false}
                        required={false}
                        onChange={this._change('membershipNumber').bind(this)}
                        label={t('membershipNumberAddressLabel')}
                        description={t('membershipNumberAddressDescription')}
                        name="enrollment_membership"
                        id="enrollment_membership" />

                    <div className="enrollment-form--membership-number" style={{ width: 'calc(50%)' }}>
                        {this.renderDisclosure(t, productLevelName)}
                    </div>

                </div>

                {/* Associates */}
                {
                    (associates && associates.length > 0) && <Hero title="Associate enrollment information" />
                }
                <table>
                    <tbody>
                        {this.renderAssociates()}
                    </tbody>
                </table>
                {/* submit */}
                <div className={`enrollment-form--btns ${btnsCssClass}`}>
                    <Btn label={saveButtonLabel || t('reviewDetailsBtn')}
                        type="submit"
                        onClick={this._onSubmitForm.bind(this)}
                        className={['enrollment-form--submit', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION]} />
                    {hasCancelButton ? (
                        <Btn label={cancelButtonLabel || t('cancel')}
                            type="button"
                            onClick={this._onCancelForm.bind(this)}
                            className={['enrollment-form--cacncel', SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ALT_SIMPLE]} />
                    ) : null}
                </div>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        conf: state.config
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(EnrollmentForm)));

