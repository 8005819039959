import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store from './redux/store';
import { I18nextProvider } from "react-i18next";
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import i18n from "./i18n";
import App from './App';
import { setConfig, setStripeConf } from './redux/config/actions';

fetch('/api/clientconfiguration').then((result) => {
	return result.json();
}).then((result) => {
	// console.log('__config__', result);
	return store.dispatch(setConfig(Object.assign(result, {
		siteUrl: window.location.origin,
		debug: window.location.href.includes('localhost'),
		productPreviewModeEnabled: window.location.href.includes('preview-membership'),
	})));
}).then(() => {
	fetch('/api/stripe/config').then((result) => {
		return result.json();
	}).then((result) => {
		return store.dispatch(setStripeConf(Object.assign(result)));
	}).then(() => {
		ReactDOM.render((
			<BrowserRouter>
				<Provider store={store}>
					<I18nextProvider i18n={i18n}>
						<App />
					</I18nextProvider>
				</Provider>
			</BrowserRouter>
		), document.getElementById('root'));
	});
});
registerServiceWorker();
