import React from 'react'
import './searchRedemptionCode.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Hero from '../../components/hero/hero'
import Section from '../../components/section/section'
import { PADDING_ALL } from '../../utils/styleValues'
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ACTION
} from '../../components/btn/btn'
import TextInput from '../../components/textInput/textInput'
import utils from '../../utils/utils'
import API from '../../utils/api'
import config from '../../config'

import {
    cleanRedemptionCode,
    setEnrollmentRedemptionCode,
    setRedemptionCode
} from '../../redux/redemptionCode/actions'
import { get } from 'lodash'
import { addError } from '../../redux/errors/actions'

const { routes } = config

// TODO: keyboard shortcuts (here or in TextInput...)

class SearchRedemptionCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            redemptionCode: '',
        }
        this.inputRef = null
        this.previousValue = ''
        this.isClearing = false
        this.api = null
    }

    componentDidMount() {
        const { dispatch, conf } = this.props
        this.api = new API(conf)
        utils.scrollTop()
        dispatch(cleanRedemptionCode())
        utils.scrollTop()
    }

    _handleSubmit(e) {
        const { dispatch, t } = this.props

        e.preventDefault()
        const { history, redemptionCode } = this.props
        if (get(redemptionCode, 'redemptionCode')) {
            this.api
                .searchRedemptionCode(get(redemptionCode, 'redemptionCode'))
                .then((redemptionCodeData) => {
                    if (redemptionCodeData === 'Code does not exist or has been redeemed') {
                        this.setState({
                            error: t('Code does not exist or has been redeemed'),
                        })
                        return null
                    }
                    console.log('redemptionCodeData', redemptionCodeData)
                    dispatch(setEnrollmentRedemptionCode('product', get(redemptionCodeData, 'data.product') || ''));

                    this.setState({
                        error: null,
                    })
                    this._setRedemptionCode(redemptionCode)
                    utils.scrollTop()
                    history.push(`${routes.enrollmentRedemptionCodePage}`)
                }).catch((err) => {
                    console.warn(err)
                    this.setState({
                        error: t('genericError'),
                    })
                    dispatch(addError({
                        message: t('errorMessageDefault'),
                        buttons: [
                            {
                                label: t('errorOK'),
                                onClick: 'dismiss',
                                color: COLOR_ACTION,
                            }
                        ],
                    }))
                    return err
                })

        }
    }



    _setRedemptionCode(redemptionCode) {
        const { dispatch } = this.props
        dispatch(setRedemptionCode(redemptionCode))
    }

    _onInputRef(ref) {
        this.inputRef = ref
    }

    _onInputStateRef(ref) {
        this.inputRef = ref
    }

    _onRedemptionCodeChange(val) {
        this._setRedemptionCode(val)
        this.setState({ redemptionCode: val })
    }

    render() {
        const { t, redemptionCode } = this.props
        const { error } = this.state
        return (
            <section className="page location-select-page">
                <Hero
                    image="/images/heros/claim-hero.jpg"
                    title={t('redemptionCodeTitle')}
                />

                <Section
                    padding={PADDING_ALL}
                    className="location-select-page--info app--inset"
                >
                    <p>{t('pinPageBlurb')}</p>
                    <p>{t('redemptionCodeDescription')}</p>
                    <form
                        action=""
                        className="location-select-page--form"
                        name="user_location_form"
                        encType="multipart/form-data"
                        method="post"
                        autoComplete="off"
                    >
                        {/* maybe autocomplete, make sure plays well with suggestions list */}

                        {error ? <p className="warning">{error}</p> : null}

                        <TextInput
                            className="customer-location-input"
                            value={this.state.redemptionCode}
                            onChange={this._onRedemptionCodeChange.bind(this)}
                            label={t('redemptionCodeInputLabel')}
                            name="redemptionCode"
                            id="redemptionCode"
                        />

                        <Btn
                            label={t('continue')}
                            type="submit"
                            onClick={this._handleSubmit.bind(this)}
                            disabled={!get(redemptionCode, 'redemptionCode')}
                            className={[
                                SIZE_MEDIUM,
                                DISPLAY_BLOCK,
                                COLOR_ACTION,
                                'location-select-page--submit'
                            ]}
                        />
                    </form>
                </Section>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        redemptionCode: state.redemptionCode,
        conf: state.config,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(SearchRedemptionCode)))
