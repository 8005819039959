import React from 'react';
import './cardMembershipStat.css';

import Card from '../card/card';
import { PADDING_ALL } from '../../utils/styleValues';
import utils from '../../utils/utils';

const CardMembershipStat = (props) => {
	const { label, value, stat, className, exception } = props;
	const cssClass = utils.extractClassName(className);
	const exceptionClass = exception ? 'exception' : '';
	return (
		<Card padding={PADDING_ALL}
			className={`card-membership-stat ${cssClass} ${exceptionClass}`}>
			<span className="card-membership-stat--label">{label}</span>
			<span className="card-membership-stat--value"><span>{value}</span></span>
			<span className="card-membership-stat--label">{stat}</span>
		</Card>
	);
};

export default CardMembershipStat;

