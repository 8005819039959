import React from 'react';
import './costAdjustments.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from '../../utils/utils';

class CostAdjustments extends React.Component {
    render() {
        const { plan, t, hideTotal } = this.props;
        if (!plan || !Array.isArray(plan.costAdjustments)) {
            return null;
        }
        const total = utils.calculateMembershipTotalCost(plan).toFixed(2);
        if (!plan.costAdjustments || plan.costAdjustments.length === 0) {
            return null;
        }
        return (
            <ul className="cost-adjustments">
                {plan.costAdjustments.map((adj) => {
                    return (
                        <li key={`${adj.id}-${adj.planId}`}>
                            ${adj.adjustment.toFixed(2)} {adj.adjustmentDescription}
                        </li>
                    );
                })}
                {hideTotal !== true ? (
                    <li className="cost-adjustments--total" key="total">${total} {t('total')}</li>
                ) : null}
            </ul>
        );
    }
}

function mapStateToProps(state) {
    return {
        sample: state.sample
    };
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(CostAdjustments)));

